export type LinksItem = {
  slug: string
  title: string
  icon: string
}

export type Links = LinksItem[]

export const typesList: (t: any) => Links = (t) => {
  const list = [
    {
      slug: 'internal_link',
      icon: 'icon-internal',
    },
    {
      slug: 'affiliate_link',
      icon: 'icon-affiliate',
    },
    {
      slug: 'custom_link',
      icon: 'icon-custom',
    },
  ]

  return list.map((item) => {
    return {
      ...item,
      get title() {
        return t(`links.${item.slug}`)
      },
    }
  })
}
