import { useQueryClient } from 'react-query'
import { useCallback } from 'react'

type ReturnProps = () => () => void

export const useUpdateSiteSettings: ReturnProps = () => {
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await queryClient.invalidateQueries(['sites-global'])
    await queryClient.invalidateQueries('nav-favourite')
  }, [queryClient])
}
