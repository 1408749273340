// Core
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { TextField, Button } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
// Hooks
import { useListContext } from 'core/data'
// Styles
import useStyles from './search.styles'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

const Search: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const {
    listParams: {
      params: { filters },
      methods,
    },
  } = useListContext()

  useEffect(() => {
    if (filters && filters.search) {
      setValue(filters.search)
    } else {
      setValue('')
    }
  }, [filters])

  const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }, [])

  const searchHandler = useCallback(
    (e: any) => {
      e.preventDefault()

      methods.setFilter('search', value)
    },
    [methods, value]
  )

  const clearHandler = useCallback(() => {
    setValue('')
    methods.removeFilter('search')
  }, [methods])

  return (
    <div>
      <form onSubmit={searchHandler}>
        <TextField
          value={value}
          onChange={changeHandler}
          variant="outlined"
          size="small"
          placeholder={t('list.search')}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={searchHandler}
        >
          {t('list.search')}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={clearHandler}
        >
          {t('list.clear')}
        </Button>
      </form>
    </div>
  )
}

export default Search
