// Core
import React, { useCallback, useEffect } from 'react'
import { List } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  expandedSelector,
  foldersSelector,
  foldersTreeSelector,
  getFolders,
  selectedFolderSelector,
  selectFolder,
  toggleFolderExpand,
} from 'modules/media/store'
import { FolderItem } from './components'
import useStyles from './folders-tree.styles'
import { useListContext } from 'core/data'

const findFolderWithParent = (folders: any, selectedFolder: string, dispatch: any) => {
  const folder = folders[selectedFolder]

  if (folder?.parent && folder?.parent !== 'allFiles') {
    const parentId = folder.parent.replace(/^\D+/g, '')

    dispatch(toggleFolderExpand(parentId))
    findFolderWithParent(folders, parentId, dispatch)
  }
}

const FoldersTree = () => {
  const folders = useSelector(foldersSelector)
  const foldersTree = useSelector(foldersTreeSelector)
  const selectedFolder = useSelector(selectedFolderSelector)
  const expanded = useSelector(expandedSelector)
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    listParams: { params, methods },
  } = useListContext()

  useEffect(() => {
    dispatch(getFolders())
  }, [dispatch])

  useEffect(() => {
    if (selectedFolder && folders) {
      findFolderWithParent(folders, selectedFolder, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, folders])

  useEffect(() => {
    if (params.filters?.search) {
      methods.removeFilter('search')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder])

  const selectFolderHandler = useCallback(
    (value: string) => {
      dispatch(selectFolder(value))
      switch (value) {
        case 'root':
          methods.setFilter('folder', 'null')
          break
        case 'allFiles':
          methods.removeFilter('folder')
          break
        default:
          methods.setFilter('folder', value)
          break
      }
    },
    [dispatch, methods]
  )

  return (
    <List component="nav" className={classes.root}>
      {foldersTree.map((item) => (
        <FolderItem
          selectFolder={selectFolderHandler}
          key={item.id}
          data={item}
          folderData={folders}
          selectedFolder={selectedFolder}
          expandedFolders={expanded}
        />
      ))}
    </List>
  )
}

export default FoldersTree
