// Core
import React, { FC, useCallback, useState } from 'react'
// Hooks
import { useRedirectsContext } from '../../hooks'
import { useAppContext, Permissions } from 'core/app'
// Components
import { DataGridHeader } from 'ui'

type Props = {
  children?: never
}

const TableHeader: FC<Props> = () => {
  const {
    actions: { userCan },
  } = useAppContext()
  const { dataController, setModalOpen } = useRedirectsContext()
  const [s, setS] = useState('')

  const searchHandler = useCallback(
    (e) => {
      e.preventDefault()
      dataController.listParams.methods.setFilter('search', s)
    },
    [dataController.listParams.methods, s]
  )

  const clearHandler = useCallback(() => {
    setS('')
    dataController.listParams.methods.setFilter('search', '')
  }, [dataController.listParams.methods])

  return (
    <DataGridHeader
      onSearchSubmit={searchHandler}
      onSearchChange={setS}
      searchValue={s}
      onSearchClear={clearHandler}
      hasCreateButton={userCan('redirects', Permissions.CREATE, 'system')}
      buttonText="Create"
      isLoading={dataController.isFetching}
      buttonProps={{
        onClick: () => setModalOpen(true),
      }}
    />
  )
}

export default TableHeader
