// Core
import React, { FC } from 'react'
import { Box, Button, InputAdornment, LinearProgress, TextField } from '@material-ui/core'
import { AddCircleOutlined, Search } from '@material-ui/icons'

type Props = {
  children?: never
  searchValue: string
  onSearchChange: (value: string) => void
  onSearchSubmit: (e: any) => void
  onSearchClear: () => void
  hasCreateButton: boolean
  buttonProps?: any
  buttonText: string
  isLoading: boolean
}

const InputIcon = (
  <InputAdornment position="start">
    <Search />
  </InputAdornment>
)

const DataGridHeader: FC<Props> = (props) => {
  const {
    searchValue,
    onSearchSubmit,
    onSearchChange,
    onSearchClear,
    hasCreateButton,
    buttonProps = {},
    buttonText,
    isLoading,
  } = props

  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      px={1}
      py={1}
      borderBottom={1}
      style={{ borderColor: '#e0e0e0' }}
      component="form"
      onSubmit={onSearchSubmit}
    >
      <TextField
        InputProps={{ startAdornment: InputIcon }}
        variant="outlined"
        size="small"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => onSearchChange(e.currentTarget.value)}
      />
      <Button variant="outlined" color="primary" type="submit" style={{ margin: '0 10px' }}>
        Search
      </Button>
      <Button variant="outlined" color="primary" onClick={onSearchClear}>
        Clear
      </Button>
      <Box ml="auto" display="flex" alignItems="center">
        {hasCreateButton && (
          <Button
            // component={NavLink}
            // to={createPath}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlined />}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
      </Box>
      {isLoading && (
        <Box width="100%" zIndex={1100} position="absolute" top="100%" left={0} right={0}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  )
}

export default DataGridHeader
