import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
// Components
import { PageTitle } from 'ui'
// Hooks
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
import { useGetSitesLocalizations } from '../../hooks'
import { Permissions, useAppContext } from 'core/app'
// Types
import { ResourceProps } from 'core/data'
// Styles
import useStyles from './list.styles'

export const LocalizationList: FC<ResourceProps> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const sitesLocalizations = useGetSitesLocalizations()

  const {
    actions: { userCan },
  } = useAppContext()

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t('list.localizations') },
  ])

  const handleClick = useCallback(
    (item) => {
      history.push(`translations/${item.code}`, { name: item.name })
    },
    [history]
  )

  return (
    <Grid item xs={12}>
      <PageTitle className={classes.title} title="localizations" />
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={20}>{t('table.show')}</TableCell>
              <TableCell>{t('table.name')}</TableCell>
              <TableCell>{t('table.code')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sitesLocalizations?.map((item) => (
              <TableRow key={item.code}>
                {userCan('translations', Permissions.VIEW, 'system') && (
                  <TableCell>
                    <IconButton aria-label="edit" onClick={() => handleClick(item)}>
                      <RemoveRedEye />
                    </IconButton>
                  </TableCell>
                )}

                <TableCell>
                  <span>{item.name}</span>
                </TableCell>
                <TableCell>
                  <span>{item.code}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Grid>
  )
}
