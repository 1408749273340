import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useNotify } from 'core/hooks'
import { EntityService } from '../utils'

type Params = {
  onSuccess?: (createdId: number) => void
  isWidgetType?: boolean
}

export const useDuplicateEntity = (entityId: number, params: Params = {}) => {
  const { onSuccess, isWidgetType } = params

  const notify = useNotify()

  const duplicateEntityM = useMutation(
    () => {
      return EntityService.duplicateEntity(entityId, isWidgetType)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) onSuccess(data.id)
        notify(`Entity duplicated`, { type: 'success' })
      },
    }
  )

  const duplicateEntity = useCallback(() => {
    duplicateEntityM.mutate()
  }, [duplicateEntityM])

  return { duplicateEntityM, duplicateEntity }
}
