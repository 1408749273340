// Core
import React, { FC, memo } from 'react'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
// Hooks
import { useBreadcrumbsContext } from 'core/breadcrumbs'
import breadcrumbsStyles from './main-breadcrumbs.styles'

type Props = {
  children?: never
}

const MainBreadcrumbs: FC<Props> = () => {
  const { data, loading } = useBreadcrumbsContext()
  const classes = breadcrumbsStyles()
  const hasData = data.length > 0

  if (loading) return <Skeleton variant="rect" width={210} height={24} />
  if (!hasData) return null

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className={classes.root}>
      {data.map((item, index) => {
        const key = `breadcrumbs-item-${index}`

        if (item.to)
          return (
            <Link key={key} color="inherit" component={RouterLink} to={item.to}>
              {item.title}
            </Link>
          )
        return (
          <Typography key={key} color="textPrimary">
            {item.title}
          </Typography>
        )
      })}
    </Breadcrumbs>
  )
}

export default memo(MainBreadcrumbs)
