import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { Edit } from 'core/data'
import { FormContainer } from '../index'
import { useNavigationContext } from 'core/navigation'

const WidgetTypeEdit: FC<ResourceProps> = (props) => {
  const {
    actions: { updateNav },
  } = useNavigationContext()

  return <Edit onSuccess={updateNav} {...props} crudForm={FormContainer} />
}

export default WidgetTypeEdit
