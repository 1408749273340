import React, { cloneElement, ElementType, FC, ReactElement } from 'react'
import { Redirect, Route as ReactRoute, RouteProps } from 'react-router-dom'
import MainLayout from 'modules/layout/containers/main-layout'
import { useAppContext } from 'core/app'

type RouteWrapperProps = {
  layout?: ElementType
  path: string
  componentTo: ReactElement
  guard?: { auth: 'user' | 'guest'; permissions?: string[] }
} & RouteProps

const Route: FC<RouteWrapperProps> = (props) => {
  const { componentTo, layout: Layout = MainLayout, guard, ...rest } = props

  const { user } = useAppContext()

  let canActivate = false
  const redirectPath = guard && guard.auth === 'guest' ? '/' : '/login'

  if (!guard) {
    canActivate = true
  } else if (guard.auth === 'guest' && !user) {
    canActivate = true
  } else if (guard.auth === 'user' && user) {
    canActivate = true
  }

  if (!canActivate) return <Redirect to={redirectPath} />

  return (
    <ReactRoute
      {...rest}
      render={(roteProps) => <Layout>{cloneElement(componentTo, { ...roteProps })}</Layout>}
    />
  )
}

export default Route
