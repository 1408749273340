import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    paper: {
      display: 'flex',
      padding: '5px 10px',
      alignItems: 'center',
    },
    typography: {
      textDecoration: 'underline',
      fontStyle: 'italic',
      fontSize: '1.2rem',
      paddingRight: '10px',
    },
    helperText: {
      position: 'absolute',
      top: '100%',
      left: 0,
    },
    label: {
      marginBottom: '10px',
    },
  })
)

export default styles
