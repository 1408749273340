// Core
import React, { ChangeEvent, FC, useCallback } from 'react'
import { InputLabel, Select } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
// Redux
import { foldersSelector, foldersTreeSelector, selectedFolderSelector } from '../../store'
// Utils
import { getIdFromIri } from 'core/utils/data'
// Types
import { FoldersMap, FolderTreeItem } from '../../types'
import { useTranslation } from 'react-i18next'

type Props = {
  onChange?: (folderId: string) => void
  value?: string
}

type OptionProps = {
  data: FolderTreeItem
  level?: number
  folders: FoldersMap
}

const getSpaces = (count: number) => {
  let value = ''
  for (let i = 0; i <= count - 1; i += 1) value += '&nbsp;&nbsp;'
  return `${value} `
}

const getFolderParent = (parent: string | null) => {
  if (parent === null) return 'root'
  return getIdFromIri(parent)
}

const RecursiveOption: FC<OptionProps> = (props) => {
  const { data, level = 0, folders } = props

  return (
    <>
      <option
        value={data.id}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: getSpaces(level) + folders[data.id].name }}
      />
      {data.children &&
        data.children.map((item) => (
          <RecursiveOption folders={folders} key={item.id} data={item} level={level + 1} />
        ))}
    </>
  )
}

const FoldersSelector: FC<Props> = (props) => {
  const { onChange, value: initialValue } = props
  const { t } = useTranslation()

  const foldersTree = useSelector(foldersTreeSelector)
  const folders = useSelector(foldersSelector)
  const selectedFolder = useSelector(selectedFolderSelector)

  const form = useFormikContext<{ parent: string }>()

  const isEdit = typeof form?.values.parent !== 'undefined'
  const value = isEdit ? getFolderParent(form.values.parent) : initialValue || selectedFolder

  const changeHandler = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      if (onChange) {
        onChange(e.target.value as string)
      } else {
        form.setFieldValue('parent', e.target.value)
      }
    },
    [form, onChange]
  )

  if (!foldersTree[0]) return <div>{t('media.empty')}</div>

  return (
    <div>
      <InputLabel id="folder-select">{t('media.folder')}</InputLabel>
      <Select
        labelId="folder-select"
        native
        fullWidth
        variant="outlined"
        value={value}
        onChange={changeHandler}
      >
        {foldersTree[0].children.map((item) => (
          <RecursiveOption key={item.id} data={item} folders={folders} level={0} />
        ))}
      </Select>
    </div>
  )
}

export default FoldersSelector
