import {
  Attribute,
  ColumnData,
  Entity,
  EntityTypeAttribute,
  RowData,
  Value,
  ValuesMap,
} from './types'

const DataService = {
  prepareColumnsData: (entityTypeAttrs: EntityTypeAttribute[], attrsData: Attribute[]) => {
    const columnsData = entityTypeAttrs.reduce<ColumnData[]>((prev, attr) => {
      if (!attr.showAsColumn || attr.nameable) return prev
      const fullAttrObject = attrsData.find((fullAttr) => fullAttr['@id'] === attr.attribute)
      if (!fullAttrObject) return prev

      const column: ColumnData = {
        title: fullAttrObject.name,
        attrIri: fullAttrObject['@id'],
        order: attr.columnOrder,
        attrType: fullAttrObject.attributeType.type,
        enums: fullAttrObject.attributeEnums,
        attrOptions: DataService._getAttrOptions(fullAttrObject),
      }

      prev.push(column)
      return prev
    }, [])

    columnsData.sort((a, b) => {
      if (a.order !== 0 && b.order === 0) return -1
      if (a.order === 0 && b.order !== 0) return 1
      return a.order - b.order
    })

    return columnsData
  },
  prepareRowsData: (entities: Entity[]) => {
    const rowsData = entities.reduce<RowData[]>((prev, entity) => {
      const rowDataItem: RowData = {
        ...entity,
        valuesMap: DataService._valuesToMap(entity.values),
      }
      prev.push(rowDataItem)
      return prev
    }, [])
    return rowsData
  },
  _valuesToMap: (values: Value[]) => {
    return values.reduce<ValuesMap>((map, value) => {
      map[value.attribute] = value
      return map
    }, {})
  },
  _getAttrOptions: (attribute: Attribute) => {
    if (Array.isArray(attribute.options)) return {}
    return attribute.options
  },
}

export default DataService
