/* eslint-disable no-unsafe-finally */
/* eslint-disable no-empty */
import axios, { AxiosError } from 'axios'
//
import storage from '../../storage'
import httpServiceInstance from '../instance'

export const refreshToken = async (e: AxiosError) => {
  const { config, response } = e

  if (response && response.status === 401 && response.data.message === 'Expired JWT Token') {
    const refreshTokenValue = storage.getRefreshToken()
    if (refreshTokenValue) {
      const refreshRes = await axios.post(`${process.env.REACT_APP_API_URL}/token/refresh`, {
        refresh_token: refreshTokenValue,
      })
      const { data } = refreshRes
      storage.login(data.token, data.refresh_token)

      /**
       * MUST HAVE
       * parse JSON data for next request call
       * after refresh token
       */
      try {
        if (config.data) config.data = JSON.parse(config.data)
      } catch (e) {
      } finally {
        return httpServiceInstance.client(config)
      }
    }
  } else if (response?.data.type === 'cache-revalidation') {
    const {
      data: { message, type },
    } = response
    const error = new Error(message)
    // @ts-ignore
    error.type = type
    return Promise.reject(error)
  } else if (response && response.status === 401) {
    if (httpServiceInstance.appLogout) httpServiceInstance.appLogout()
  }

  return Promise.reject(e)
}
