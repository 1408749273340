// Core
import React, { FC, useState } from 'react'
// Context
import { BreadcrumbsContext } from './breacrumbs-context'
import { BreadcrumbData } from '../types'

type Props = {}

const BreadcrumbsProvider: FC<Props> = (props) => {
  const { children } = props

  const [data, setData] = useState<BreadcrumbData[]>([])
  const [loading, setLoading] = useState(false)

  return (
    <BreadcrumbsContext.Provider
      value={{
        data,
        setBreadcrumbsData: setData,
        loading,
        setLoading,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export default BreadcrumbsProvider
