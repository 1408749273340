// Core
import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { DataView, getQuery, httpService, parseQueryFromLocation, ResourceProps } from 'core/data'
import { FolderColumn, GridItem, NameColumn, Sidebar, SizeColumn } from './components'
import UploadFilesModal from '../upload-files-modal'
import FolderFormModal from '../folder-form-modal'
// Utils
import { DefaultActionColumns, getActions, getTableColumn } from 'core/data/components/data-view'
// Hooks
import { useMediaModalOpen } from 'modules/media/hooks'
import { useAppContext } from 'core/app'
// Types
import { Permissions } from 'core/app'
// Redux
import { selectFolder, toggleFileForm } from '../../store'
import { Icon } from '@material-ui/core'

const MediaList: React.FC<ResourceProps> = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryFromLocation = parseQueryFromLocation(location)
  const modalOpen = useMediaModalOpen()
  const { t } = useTranslation()
  const {
    actions: { userCan },
  } = useAppContext()

  const query = useMemo(
    () =>
      getQuery({
        queryFromLocation,
      }),
    [queryFromLocation]
  )

  useEffect(() => {
    const { edit, folder } = query.filters
    if (folder) {
      dispatch(selectFolder(folder))
    }
    if (edit) {
      httpService.get(`${props.name}/${edit}`).then((response: any) => {
        dispatch(toggleFileForm(true, 'edit', response.data))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      <DataView
        showCheckBox
        resourceProps={props}
        controllerOptions={{
          defaultViewType: 'grid',
          removeFilter: 'folder',
        }}
        sidebar={<Sidebar />}
        columns={[
          getActions(
            [
              userCan('media', Permissions.EDIT, 'system')
                ? { icon: <Icon className="icon-edit-3" />, onClick: modalOpen }
                : -1,
              userCan('media', Permissions.DELETE, 'system') ? DefaultActionColumns.DELETE : -1,
            ],
            t
          ),
          getTableColumn({ title: t('table.name'), width: 'auto' }, 'custom', NameColumn),
          getTableColumn({ title: t('table.size'), width: '100' }, 'custom', SizeColumn),
          getTableColumn({ title: t('table.folder') }, 'custom', FolderColumn),
          getTableColumn({ title: t('table.author'), field: 'createdBy' }, 'reference', null, {
            field: 'email',
            source: 'users',
          }),
          getTableColumn({ title: t('table.created'), field: 'createdAt', width: 124 }, 'date'),
        ]}
        hasGrid={true}
        gridItemComponent={GridItem}
      />
      <UploadFilesModal />
      <FolderFormModal />
    </>
  )
}

export default MediaList
