// Core
import { channel } from 'redux-saga'
import { takeEvery, take, select, put, delay, call } from 'redux-saga/effects'
import axios from 'axios'
// Redux
import { selectedFolderSelector } from '../../folder'
import { uploadFile, uploadFileSuccess, updateUplaodProgress, setUploadFileError } from '../actions'
// Services
import { httpService } from 'core/data'
// Utils
import { hasProp } from 'core/utils/typescript'
// Types
import { ActionTypes, ISetFilesToUploadAction } from '../types'
import { IFileToUpload } from 'modules/media/types'
import { getIdFromIri } from 'core/utils'

const uploadFileChannel = channel()

function* updateProgress(file: IFileToUpload, event: object) {
  if (hasProp('total', event) && hasProp('loaded', event)) {
    const percents = +((Number(event.loaded) / Number(event.total)) * 100).toFixed(3)
    yield put(updateUplaodProgress(file, percents))
  }
}

function* uploadFileHelper(file: IFileToUpload, folder: string) {
  try {
    const formData = new FormData()

    formData.append('file', file.file)

    if (folder !== 'root' && folder !== 'allFiles') {
      formData.append('folder', `/api/media_folders/${folder}`)
    }

    yield put(uploadFile(file))

    const res = yield httpService.post('/media', formData, {
      cancelToken: file.cancelToken.token,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        uploadFileChannel.put({
          file,
          event,
        })
      },
    })

    const mediaRes = yield call(httpService.get, `/media/${getIdFromIri(res.data['@id'])}`)

    yield delay(100)

    yield put(uploadFileSuccess(file, mediaRes.data))
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(setUploadFileError(file, e))
    }
  }
}

function* uploadFilesWorker(action: ISetFilesToUploadAction) {
  try {
    const { files, folder } = action.payload
    const activeFolder = yield select(selectedFolderSelector)

    for (let i = 0; i <= files.length - 1; i += 1) {
      const currentFile = files[i]
      yield call(uploadFileHelper, currentFile, folder || activeFolder)
    }

    yield delay(500)
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* uploadFilesWatcher() {
  yield takeEvery(ActionTypes.SET_FILES_TO_UPLOAD, uploadFilesWorker)

  while (true) {
    const { file, event } = yield take(uploadFileChannel)
    yield call(updateProgress, file, event)
  }
}
