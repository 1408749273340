import { IMediaFolderState } from './types'
import { FolderTreeItem } from '../../types'
import { getIdFromIri } from 'core/utils/data'

const findFolderById = (foldersTree: FolderTreeItem[], id: string): FolderTreeItem | undefined => {
  for (const folder of foldersTree) {
    if (`${folder.id}` === `${id}`) return folder

    if (folder.children) {
      const findFolder = findFolderById(folder.children, id)
      if (findFolder) return findFolder
    }
  }
}

const findFolderAndRemove = (foldersTree: FolderTreeItem[], id: string) => {
  for (const folder of foldersTree) {
    if (folder.id === id) {
      const deleteIndex = foldersTree.indexOf(folder)
      foldersTree.splice(deleteIndex, 1)
      return folder
    }

    if (folder.children.length) {
      findFolderAndRemove(folder.children, id)
    }
  }
}

const createDataTree = (dataset: any): any => {
  const hashTable = Object.create(null)
  dataset.forEach((aData: any) => {
    hashTable[aData['@id']] = { id: aData.id, children: [] }
  })
  const dataTree: any = []
  dataset.forEach((aData: any) => {
    if (aData.parent) hashTable[aData.parent].children.push(hashTable[aData['@id']])
    else dataTree.push(hashTable[aData['@id']])
  })
  return dataTree
}

export const normalizeFolderData = (draft: IMediaFolderState, folders: any) => {
  const normalizedData: any = {}
  // temporary
  const allFilesFolder = {
    '@id': 'allFiles',
    id: 'allFiles',
    parent: null,
    hasChildren: true,
    name: 'All files',
  }

  const rootFolder = {
    '@id': 'root',
    id: 'root',
    parent: 'allFiles',
    hasChildren: true,
    name: 'Root',
  }

  folders.map((item: any) => {
    if (item.parent === null) {
      item.parent = 'root'
    }
    return item
  })

  folders.unshift(allFilesFolder, rootFolder)

  folders.forEach((item: any) => {
    normalizedData[`${item.id}`] = item
  })

  draft.data = { ...draft.data, ...normalizedData }

  draft.folderTree = createDataTree(folders)
}

export const removeFolder = (draft: IMediaFolderState, id: string) => {
  findFolderAndRemove(draft.folderTree, id)
  const removedFolder = draft.data[id]

  if (removedFolder.parent === null) return

  const parentFolder = draft.data[getIdFromIri(removedFolder.parent)]

  const findParent = findFolderById(draft.folderTree, getIdFromIri(removedFolder.parent))
  if (!findParent) return

  if (findParent.children.length === 0) {
    parentFolder.hasChildren = false
  }
}

export const addFolder = (draft: IMediaFolderState, data: any) => {
  draft.data = {
    ...draft.data,
    [data.id]: data,
  }

  if (data.parent === null) {
    const findParent = findFolderById(draft.folderTree, 'root')
    if (!findParent) return

    findParent.children.push({
      id: data.id,
      children: [],
    })
  } else {
    draft.data[getIdFromIri(data.parent)].hasChildren = true

    const findParent = findFolderById(draft.folderTree, getIdFromIri(data.parent))
    if (!findParent) return

    findParent.children.push({
      id: data.id,
      children: [],
    })
  }
}

export const replaceFolder = (draft: IMediaFolderState, data: any) => {
  const toRoot = data.parent === null
  const prevData = draft.data[data.id]
  const folderToReplace = findFolderById(draft.folderTree, data.id)
  if (!folderToReplace) return

  if (toRoot) {
    draft.folderTree.push({ ...folderToReplace })
  } else {
    const newParent = findFolderById(draft.folderTree, getIdFromIri(data.parent))
    if (!newParent) return
    newParent.children.push({ ...folderToReplace })
    draft.data[newParent.id].hasChildren = true
  }

  const prevParent = findFolderById(draft.folderTree, getIdFromIri(prevData.parent))

  if (prevParent) {
    const deleteIndex = prevParent.children.findIndex((item) => item.id === prevData.id)
    prevParent.children.splice(deleteIndex, 1)

    if (prevParent.children.length === 0) {
      draft.data[prevParent.id].hasChildren = false
    }
  } else {
    const deleteIndex = draft.folderTree.findIndex((item) => item.id === prevData.id)
    draft.folderTree.splice(deleteIndex, 1)
  }
}
