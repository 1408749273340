// Core
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
// Services
import { httpService } from 'core/data'
import DataService from './data-service'
// Types
import { Attribute, ControllerProps, Entity, EntityType } from './types'
import { HydraResponse } from 'core/types'

type EntityTypeRes = EntityType
type EntityTypeAttrsRes = HydraResponse<Attribute>
type EntitiesRes = HydraResponse<Entity>

type Params = {
  typeId: number
}

const perPage = 20

const useController = (params: Params): ControllerProps => {
  const { typeId } = params

  const typIri = `/api/entity_types/${typeId}`
  const columnsQKey = `el-columns-${typeId}`
  const dataQKey = `el-data-${typeId}`

  const columnsQ = useQuery(columnsQKey, async () => {
    const entityTypeReq = httpService.get<EntityTypeRes>(`/entity_types/${typeId}`)
    const entityTypeAttrsReq = httpService.get<EntityTypeAttrsRes>(
      `/entity_types/${typeId}/attributes`
    )
    const [entityTypeRes, entityTypeAttrsRes] = await Promise.all([
      entityTypeReq,
      entityTypeAttrsReq,
    ])

    return {
      columns: DataService.prepareColumnsData(
        entityTypeRes.data.entityTypeAttributes,
        entityTypeAttrsRes.data['hydra:member']
      ),
      entityType: entityTypeRes.data,
    }
  })

  const [page, setPage] = useState(1)
  const [search, setSearchState] = useState('')

  const setSearch = useCallback((value: string) => {
    setPage(1)
    setSearchState(value)
  }, [])

  const dataQ = useQuery(
    [dataQKey, page, search],
    async () => {
      const params = {
        entityType: typIri,
        showAsColumn: true,
        hidden: false,
        page,
        itemsPerPage: perPage,
        search,
      }
      const entitiesRes = await httpService
        .get<EntitiesRes>('/entities', { params })
        .then((data) => data.data)
      return entitiesRes
    },
    {
      select: (data) => {
        return {
          totalItems: data['hydra:totalItems'],
          data: DataService.prepareRowsData(data['hydra:member']),
        }
      },
    }
  )

  const isLoading = columnsQ.isLoading || dataQ.isLoading
  const isFetching = columnsQ.isFetching || dataQ.isFetching
  const isDataLoading = dataQ.isLoading
  const isDataFetching = dataQ.isFetching
  const isColumnsLoading = columnsQ.isLoading
  const hasError = columnsQ.isError || dataQ.isError

  return {
    columnsData: columnsQ.data?.columns || [],
    rowsData: dataQ.data?.data || [],
    totalItems: dataQ.data?.totalItems || 0,
    entityType: columnsQ.data?.entityType!,
    isLoading,
    isFetching,
    isDataLoading,
    isDataFetching,
    isColumnsLoading,
    hasError,
    dataQKey,
    typeId,
    page,
    perPage,
    setPage,
    search,
    setSearch,
    refetch: dataQ.refetch,
  }
}

export default useController
