// Core
import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
// Components
import { CheckboxField, TextField } from 'core/form/components'
import { DefaultFields, AffiliateFields, InternalFields, TypeSelect } from './components'
// Styles
import createFieldsStyles from './create-fields.styles'

const CreateFields: FC = () => {
  const classes = createFieldsStyles()
  const {
    values: { type },
  } = useFormikContext<any>()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TypeSelect />
        </Grid>
        {type === 'custom_link' && (
          <Grid item xs={12}>
            <TextField name="url" label={`${t('labels.url')} *`} />
          </Grid>
        )}
        {type === 'affiliate_link' && <AffiliateFields />}
        {type === 'internal_link' && <InternalFields />}
        <DefaultFields />
        {type && type !== 'affiliate_link' && (
          <>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.new-tab')} name={'new_tab'} />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.nofollow')} name={'no_follow'} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}

export default CreateFields
