import React, { FC, ReactElement, RefObject } from 'react'
import { Form, Formik } from 'formik'
import { FormLang, LanguageProvider } from 'core/data'
import { Grid } from '@material-ui/core'
import FormControl from './components/form-control'
import { EavResourceType } from 'modules/new-entity/types'
import { Portal } from '@material-ui/core'
import { FormRef, IEntity, IEntityWidget, MutationType, SortedAttribute } from '../../types'

type TProps = {
  type: MutationType
  asideRef?: RefObject<HTMLElement>
  mainAttributes: SortedAttribute[]
  asideAttributes?: SortedAttribute[]
  initialValues: any
  validationSchema: any
  resourceType: any
  typeOptions: any
  formRef?: FormRef
  asideHolder?: ReactElement
  entity?: IEntity
  disabled?: boolean
  widget?: IEntityWidget
  unsavedChanges?: RefObject<HTMLElement>
}

const AttributesForm: FC<TProps> = (props) => {
  const {
    type,
    asideRef,
    mainAttributes,
    asideAttributes,
    validationSchema,
    initialValues,
    resourceType,
    typeOptions,
    formRef,
    asideHolder,
    entity,
    disabled,
    widget,
  } = props

  const selfType = resourceType === EavResourceType.WIDGET ? 'widget' : 'entity'

  const systemSlug = mainAttributes?.find((item: any) => item.attribute.slug === 'slug')
  const cloakedUrl = mainAttributes?.find((item: any) => item.attribute.slug === 'cloaked_url')

  return (
    <Formik
      onSubmit={() => {}}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
    >
      {() => {
        return (
          <Form>
            <LanguageProvider notSystem>
              <FormLang />
              <Grid container spacing={3}>
                {mainAttributes.map((item: any) => (
                  <FormControl
                    resourceType={resourceType}
                    key={item.id}
                    name={item.attribute['@id']}
                    data={item}
                    options={typeOptions}
                    allData={mainAttributes}
                    pathToHierarchicalOptions={item.attribute['@id']}
                    selfType={selfType}
                    type={type}
                    entity={entity}
                    disabled={disabled}
                    widget={widget}
                    slugs={{
                      system: systemSlug?.attribute['@id'],
                      cloaked: cloakedUrl?.attribute['@id'],
                    }}
                  />
                ))}
              </Grid>
              {asideAttributes && asideRef?.current && (
                <Portal container={asideRef?.current}>
                  <Grid container spacing={2}>
                    {asideHolder}
                    {asideAttributes.map((item: any) => (
                      <FormControl
                        resourceType={resourceType}
                        key={item.id}
                        name={item.attribute['@id']}
                        data={item}
                        options={typeOptions}
                        allData={asideAttributes}
                        pathToHierarchicalOptions={item.attribute['@id']}
                        selfType={selfType}
                        type={type}
                        entity={entity}
                      />
                    ))}
                  </Grid>
                </Portal>
              )}
            </LanguageProvider>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AttributesForm
