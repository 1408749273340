import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { NavLink } from 'react-router-dom'
import { Icon, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core'

type ErrorItem = {
  attribute: string
  entityId: number
  entityTypeId: number
}

type MapErrorItem = {
  attributes: string[]
} & Omit<ErrorItem, 'attribute'>

type RelationDeleteError = {
  'hydra:description': ErrorItem[]
  'hydra:title': string
}

export function isRelationError(error: unknown) {
  if (!isAxiosError(error)) return false
  const errorTitle: string = error.response?.data['hydra:title'] || ''
  if (!errorTitle.includes('Used by other entities')) return false
  return true
}

const RelationsError: FC<{ data: MapErrorItem[] }> = ({ data }) => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        Used by other entities please remove relationship with required fields
      </div>
      <List
        dense
        component={Paper}
        style={{ maxHeight: 300, overflow: 'auto', background: 'transparent', color: '#fff' }}
        variant="outlined"
      >
        {data.map((item) => {
          const link = `/entities/${item.entityTypeId}/edit/${item.entityId}`
          const text = `${window.location.origin}${link}`
          const secondary = `in: ${item.attributes.join(', ')}`
          return (
            <ListItem
              button
              key={item.entityId}
              component={NavLink}
              to={`${link}/?attrs=${item.attributes.join(',')}`}
              target="_blank"
            >
              <ListItemIcon>
                <Icon className="icon-link" />
              </ListItemIcon>
              <ListItemText primary={text} secondary={secondary} />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

function prepareErrorsData(error: unknown): MapErrorItem[] {
  if (!isAxiosError(error)) {
    throw new Error('Wrong error in deleting entity with relations')
  }

  const errorsData: RelationDeleteError['hydra:description'] =
    error.response?.data['hydra:description']
  const errorsMap: MapErrorItem[] = []

  errorsData.forEach((errorItem) => {
    const prevItem = errorsMap.find((item) => item.entityId === errorItem.entityId)
    if (prevItem) {
      prevItem.attributes = [...prevItem.attributes, errorItem.attribute]
      return
    }
    errorsMap.push({ ...errorItem, attributes: [errorItem.attribute] })
  })

  return errorsMap
}

export function useDeleteRelations() {
  const notifyDeleteRelations = (errors: unknown) => {
    const errorsData = prepareErrorsData(errors)
    toast(<RelationsError data={errorsData} />, {
      type: 'error',
      autoClose: false,
      containerId: 'toast-big',
      closeOnClick: false,
    })
  }
  return { notifyDeleteRelations }
}
