// Core
import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
// Styles
import useStyles from './sub-header.styles'
import CreateButtonUi from 'ui/create-button'
import { PageTitle } from 'ui'
import { MainBreadcrumbs } from 'core/breadcrumbs'
import { EavResourceType } from 'modules/new-entity/types'

type TProps = {
  hasButton?: boolean
  buttonName?: string
  buttonClick?(): void
  resource?: string
  resourceType?: EavResourceType
  showBreadcrumbs?: boolean
  canCreate?: boolean
}

const SubHeader: FC<TProps> = (props) => {
  const {
    children,
    hasButton,
    buttonName,
    buttonClick,
    resource,
    canCreate,
    showBreadcrumbs = true,
    resourceType,
  } = props
  const classes = useStyles()

  return (
    <Grid className={classes.root} container>
      {showBreadcrumbs && (
        <Grid item xs={12} className={classes.breadcrumbsWrap}>
          <MainBreadcrumbs />
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.titleWrap}
      >
        <Grid item>
          <PageTitle
            // icon={<SettingsApplicationsRounded />}
            title={resourceType || resource}
          />
          {children}
        </Grid>
        {canCreate && (
          <Grid item>
            {hasButton && <CreateButtonUi name={buttonName} onClick={buttonClick} />}
            {(resourceType === EavResourceType.ENTITY ||
              resourceType === EavResourceType.WIDGET ||
              resourceType === EavResourceType.TEMPLATE) &&
              (resource === 'entities' || resource === 'widgets') && (
                <CreateButtonUi name={resourceType || buttonName} onClick={buttonClick} />
              )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default SubHeader
