// Core
import React, { FC, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
// Components
import { Icon, IconButton, Tooltip, MenuItem as MaterialMenuItem } from '@material-ui/core'
import { useNavigationContext } from 'core/navigation'
// Utils
import clsx from 'clsx'
import { getIdFromIri } from 'core/utils'
// Types
import { NavigationItem } from 'core/navigation/types/navigation-item'
// Styles
import useStyles from './menu-item.styles'

type MenuItemProps = {
  itemData: NavigationItem
  exact?: boolean
}

export const MenuItem: FC<MenuItemProps> = (props) => {
  const classes = useStyles()
  const { itemData, exact = false } = props
  const { isOpen } = useNavigationContext()

  const generateLink = useCallback(() => {
    if (itemData.singleEntity) {
      const entityId = getIdFromIri(itemData.singleEntity)
      return `${itemData.link_to}/edit/${entityId}`
    }
    return `${itemData.link_to}/create`
  }, [itemData])

  return (
    <NavLink
      activeClassName={classes.active}
      className={classes.link}
      to={itemData.single ? generateLink() : itemData.link_to}
      exact={exact}
    >
      <MaterialMenuItem disableGutters className={classes.menuItem}>
        <Tooltip title={itemData.title} arrow placement="right">
          <IconButton>
            <Icon className={clsx(itemData.icon, classes.menuItemIcon)} />
          </IconButton>
        </Tooltip>
        <span
          className={clsx(classes.menuItemText, {
            [classes.menuItemTextOpen]: isOpen,
          })}
        >
          {itemData.title}
        </span>
      </MaterialMenuItem>
    </NavLink>
  )
}
