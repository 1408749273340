// Core
import React, { cloneElement, FC, ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
// Styles
import useStyles from './page-title.styles'
import { useTranslation } from 'react-i18next'

type TProps = {
  icon?: ReactElement
  title?: string
  className?: any
}

const PageTitle: FC<TProps> = (props) => {
  const { children, icon, title, className } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={clsx(classes.root, className)}>
      <div className={classes.titleWrapper}>
        {icon && cloneElement(icon, { className: classes.icon })}
        {title && <Typography className={classes.title}>{t(`list.${title}`)}</Typography>}
      </div>
      {children}
    </Box>
  )
}

export default PageTitle
