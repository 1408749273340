import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const dateTimePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    withEdit: {
      '& .MuiInputBase-root::after': {
        content: '"Edit"',
        fontSize: '0.875rem',
        color: '#3f51b5',
        cursor: 'pointer',
      },
    },
    system: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiFormLabel-root': {
        position: 'relative',
        marginRight: 10,
        transform: 'none',
        fontSize: '0.875rem',
        color: 'rgba(0, 0, 0, 0.87)',
        '&::after': {
          content: '":"',
        },
      },
      '& .MuiInputBase-root': {
        width: 'auto',
      },
      '& fieldset': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        padding: 0,
        fontWeight: 600,
        fontSize: '0.875rem',
        width: 126,
      },
    },
  })
)

export default dateTimePickerStyles
