// Core
import React, { FC } from 'react'
//
import { Button } from '@material-ui/core'
//
import { AddCircleOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

type TProps = {
  name?: string
  onClick?(): void
}

const CreateButtonUi: FC<TProps> = (props) => {
  const { name, onClick } = props
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      startIcon={<AddCircleOutlined />}
      style={{ marginTop: 6, marginBottom: 6 }}
    >
      {t('list.create-new', { name: t(`list.${name}`) })}
    </Button>
  )
}

export default CreateButtonUi
