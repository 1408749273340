import { useMemo } from 'react'
import { useAppContext, Permissions } from 'core/app'
import { EntityType } from 'core/types'

export const useTypePermissions = (entityType: EntityType) => {
  const {
    actions: { userCan },
    user,
  } = useAppContext()

  return useMemo(() => {
    const slug = entityType?.slug
    const type = entityType['@type'] === 'EntityType' ? 'entityType' : 'widgetType'

    const canCreate = userCan(slug, Permissions.CREATE, type)
    const canEdit = userCan(slug, Permissions.EDIT, type)
    const canDelete = userCan(slug, Permissions.DELETE, type)
    const canPublish = userCan(slug, Permissions.PUBLISH, type)

    return {
      canCreate,
      canEdit,
      canDelete,
      canPublish,
      activeRole: user.activeRole.slug,
    }
  }, [entityType, userCan, user])
}
