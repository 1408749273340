import React, { FC, MouseEvent, useCallback, useState } from 'react'
// Material Components
import { Icon, IconButton } from '@material-ui/core'
// Components
import { User } from './components'
// Styles
import menuStyles from './menu.styles'
// Hooks
import { useAppContext } from 'core/app'

const Menu: FC = (props) => {
  const classes = menuStyles()
  const {
    actions: { logout },
  } = useAppContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const logoutHandler = useCallback(() => {
    logout(true)
  }, [logout])

  const handleUserMenuOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl]
  )

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={logoutHandler}>
          <Icon className="icon-log-out" />
        </IconButton>

        <User
          handleUserMenuOpen={handleUserMenuOpen}
          handleMenuClose={handleMenuClose}
          isMenuOpen={isMenuOpen}
          menuId="admin-top-nav"
          anchorEl={anchorEl}
        />
      </div>
    </>
  )
}

export default Menu
