// Core
import React, { FC } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Box, Button, Grid, Icon, Tooltip, Typography } from '@material-ui/core'
// Hooks
import { usePageBuilderContext } from '../../../../context'
// Components
import ButtonAdd from '../button-add'
// Types
import { SectionContainer } from 'core/types/layouts'
import { IEntityWidget } from 'modules/new-entity/types'
// Styles
import useStyles from './container.styles'

type TProps = {
  container: SectionContainer
  showSecondButton: boolean
  setTypesModalState: (value: any) => void
  widgets: IEntityWidget[]
}

const Container: FC<TProps> = (props) => {
  const classes = useStyles()
  const { container, children, setTypesModalState, showSecondButton, widgets, ...rest } = props

  const {
    activeWidgets,
    actions: { toggleContainer },
  } = usePageBuilderContext()
  const isOpened = Boolean(activeWidgets[container.id])

  return (
    <Grid {...rest} item xs={12}>
      <Box className={classes.root}>
        <Typography className={classes.title} component={'p'}>
          {container.name}
        </Typography>

        <Button className={classes.button} onClick={() => toggleContainer(container.id, widgets)}>
          <Tooltip placement={'top'} title={`${!isOpened ? 'expand' : 'collapse'}`}>
            <Icon className={`icon-chevron-${!isOpened ? 'down' : 'up'}`} />
          </Tooltip>
        </Button>

        {showSecondButton && (
          <ButtonAdd
            onClick={() => {
              setTypesModalState({
                container: container.id,
                type: 'unshift',
              })
            }}
          />
        )}

        <div className={classes.holder}>
          <Droppable droppableId={container.id}>
            {(providedDrop, snapshotDrop) => {
              return (
                <Grid innerRef={providedDrop.innerRef} container spacing={1}>
                  {children}
                  {providedDrop.placeholder}
                </Grid>
              )
            }}
          </Droppable>
        </div>

        <ButtonAdd
          onClick={() => {
            setTypesModalState({
              container: container.id,
              type: 'push',
            })
          }}
        />
      </Box>
    </Grid>
  )
}

export default Container
