import React, { FC } from 'react'
import { CrudFormContainer } from 'core/data'
import { DataControllerProps } from 'core/data'
import { Grid } from '@material-ui/core'
import { CheckboxField, TextField } from 'core/form'

const CrudForm: FC<DataControllerProps> = (props) => {
  return (
    <CrudFormContainer {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField localized name="name" label="Name" />
        </Grid>
        <Grid item xs={12}>
          <TextField name={'name'} label={'Internal Name'} />
        </Grid>
        <Grid item xs={12}>
          <TextField placeholder="Slug" name="slug" type="text" label="Slug" />
        </Grid>
        <Grid item xs={12}>
          <TextField placeholder="Width" name="width" type="number" label="Width" />
        </Grid>
        <Grid item xs={12}>
          <TextField placeholder="Height" name="height" type="number" label="Height" />
        </Grid>
        <Grid item xs={12}>
          <TextField placeholder="Quality" name="quality" type="number" label="Quality" />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField label={'Crop image'} name="crop" />
        </Grid>
      </Grid>
    </CrudFormContainer>
  )
}

export default CrudForm
