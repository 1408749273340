/* eslint-disable react-hooks/rules-of-hooks */
// Core
import React, { FC, useState, useEffect, useCallback } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { isJsonString } from 'modules/new-entity/utils'
// Material UI
import {
  TextFieldProps,
  Paper,
  Box,
  IconButton,
  Typography,
  Button,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import LinksModal from 'modules/new-entity/components/links-modal'
// Hooks
import { httpService } from 'core/data'
import { useGetActiveSite, useSitesContext } from 'modules/sites'
// Utils
import { trailingSlash } from 'core/utils'
// Styles
import useStyles from './custom-text-field.styles'

type TProps = {
  name: string
  label: string
} & TextFieldProps

const CustomTextField: FC<TProps> = (props) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [openModal, toggleModalOpen] = useState(false)
  const { name, label } = props
  const [field, meta, helpers] = useField<any>(name)
  const { t } = useTranslation()

  const activeSite = useGetActiveSite()

  const { affiliateSettings } = useSitesContext()
  const {
    prefix: globalAffPrefix,
    alternative: { domain: altDomain },
  } = affiliateSettings

  const siteName = `${trailingSlash(altDomain || activeSite?.url)}${globalAffPrefix}/`

  const linkType = isJsonString(field.value) ? JSON.parse(field.value) : { type: 'custom_link' }

  useEffect(() => {
    if (isJsonString(field.value)) {
      const fieldValues = JSON.parse(field.value)
      switch (fieldValues.type) {
        case 'custom_link': {
          setValue(fieldValues.url)
          break
        }
        case 'affiliate_link': {
          httpService
            .get(`/frontend/entities/${fieldValues.affiliate}`)
            .then((res: any) => setValue(`${siteName}${res.data.values.cloaked_url}`))
          break
        }
        case 'internal_link': {
          httpService.get(`/frontend/entities/${fieldValues.entity}`).then((res: any) => {
            let { anchor } = fieldValues
            anchor = anchor ? `/#${anchor}` : ''
            const slug = res.data.values.slug === '/' ? '' : `/${res.data.values.slug}`
            setValue(`${activeSite?.url}${slug}${anchor}`)
          })
          break
        }
        default: {
          setValue('')
        }
      }
    }
  }, [activeSite, field.value, siteName])

  const submitLinkAttribute = useCallback(
    (values: any) => {
      helpers.setValue(JSON.stringify(values))
      toggleModalOpen(false)
    },
    [helpers]
  )

  const openLinksModal = useCallback(() => {
    toggleModalOpen(true)
    helpers.setTouched(true)
  }, [helpers])

  const deleteLink = useCallback(() => {
    helpers.setValue('')
    setValue('')
  }, [helpers])

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset" error={Boolean(meta.error && meta.touched)}>
        <FormLabel className={classes.label} component="legend">
          {label}
        </FormLabel>
        {value ? (
          <Paper className={classes.paper}>
            <Typography color={'primary'} className={classes.typography}>
              {linkType.type === 'custom_link' ? value : trailingSlash(value)}
            </Typography>
            <IconButton onClick={openLinksModal} color={'primary'}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={deleteLink} color={'primary'}>
              <DeleteIcon />
            </IconButton>
          </Paper>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={openLinksModal}
            startIcon={<Icon className="icon-plus-circle" />}
          >
            {t('links.insert')}
          </Button>
        )}
        {Boolean(meta.touched && meta.error) && (
          <FormHelperText>
            {<span className={classes.helperText}>{meta.touched && meta.error}</span>}
          </FormHelperText>
        )}
      </FormControl>
      {openModal && (
        <LinksModal
          submitLink={submitLinkAttribute}
          toggleModalOpen={toggleModalOpen}
          value={field.value}
          openModal={openModal}
        />
      )}
    </Box>
  )
}

export default CustomTextField
