// Core
import React, { FC, ReactNode, RefObject, useRef } from 'react'
// Styles
import useStyles from './form-holder.styles'
import { Drawer } from '@material-ui/core'

type Props = {
  children: (asideRef: RefObject<HTMLElement>) => ReactNode
}

export const FormHolder: FC<Props> = (props) => {
  const { children } = props
  const classes = useStyles()
  const asidePortalRef = useRef(null)

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.wrapper}>
          <div className={classes.content}>{children(asidePortalRef)}</div>
        </div>

        <Drawer variant="permanent" anchor="right" className={classes.aside}>
          <div ref={asidePortalRef} />
        </Drawer>
      </div>
    </div>
  )
}
