// Core
import React from 'react'
// Types
import { ColumnType, Fieldtypes } from '../types'
import { UrlField, ReferenceField } from '../components'

export const getTableColumn = (
  params: ColumnType,
  type: Fieldtypes = 'string',
  Component?: any,
  refParams?: any,
  props?: {
    [name: string]: unknown
  }
): ColumnType => {
  if (type === 'custom') {
    params.render = (rowData: any) => <Component rowData={rowData} {...props} />
  } else if (type === 'link') {
    params.render = (rowData: any) => <UrlField field={params.field} rowData={rowData} />
  } else if (type === 'reference') {
    params.render = (rowData: any) => (
      <ReferenceField field={params.field} refParams={refParams} rowData={rowData} />
    )
  } else {
    params.type = type
  }

  return params
}
