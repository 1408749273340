// Core
import React, { FC, useCallback, useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { ArrowDropDown, ArrowRight, Folder, FolderOpen, MoreVert } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import DeleteFolderDialog from '../../../delete-folder-dialog'
// Hooks
import { toggleModalForm } from 'modules/media/store'
import { useAppContext } from 'core/app'
// Types
import { IFolder } from 'modules/media/types'
import { Permissions } from 'core/app'
// Styles
import useStyles from './label.styles'

type TProps = {
  treeData: any
  data: IFolder
  onClick: (id: any) => void
  open: boolean
  selectFolder: (value: string) => void
}

const Label: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const { treeData, data, onClick, open, selectFolder } = props
  const {
    actions: { userCan },
  } = useAppContext()
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)
  const dispatch = useDispatch()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const menuHandler = useCallback((e: any) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const closeMenuHandler = useCallback((e: any) => {
    e.stopPropagation()
    setMenuAnchor(null)
  }, [])

  const editHandler = useCallback(
    (e: any) => {
      e.stopPropagation()
      setMenuAnchor(null)
      dispatch(toggleModalForm(true, 'edit', data))
    },
    [dispatch, data]
  )

  const removeHandler = useCallback((e: any) => {
    e.stopPropagation()
    setMenuAnchor(null)
    setDeleteOpen(true)
  }, [])

  return (
    <div className={classes.root}>
      <DeleteFolderDialog
        folderId={treeData.id}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      />
      {(treeData.id === 'root' || data.hasChildren) && (
        <>
          {open ? (
            <ArrowDropDown onClick={() => onClick(treeData.id)} className={classes.arrowIcon} />
          ) : (
            <ArrowRight onClick={() => onClick(treeData.id)} className={classes.arrowIcon} />
          )}
        </>
      )}
      {open ? (
        <FolderOpen className={classes.folderIcon} />
      ) : (
        <Folder className={classes.folderIcon} />
      )}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={classes.label} onClick={() => selectFolder(treeData.id)}>
        {treeData.id === 'root' ? 'Root' : data?.name}
      </div>
      {treeData.id !== 'root' && treeData.id !== 'allFiles' && (
        <>
          <IconButton onClick={menuHandler} size="small" className={classes.actionsButton}>
            <MoreVert />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={closeMenuHandler}
          >
            {userCan('media_folders', Permissions.EDIT, 'system') && (
              <MenuItem onClick={editHandler}>{t('global.edit')}</MenuItem>
            )}
            {userCan('media_folders', Permissions.DELETE, 'system') && (
              <MenuItem onClick={removeHandler}>{t('global.remove')}</MenuItem>
            )}
          </Menu>
        </>
      )}
    </div>
  )
}

export default Label
