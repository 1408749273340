// Core
import React, { FC, useCallback } from 'react'
import { MenuItem, MenuList } from '@material-ui/core'
// Styles
import useStyles from './list.styles'
import { WordBreakWrapper } from 'ui'

type Props = {
  children?: never
  data: any[]
  titleKey: string
  onSelect?: (item: any) => void
  selected: any
  multiple?: boolean
  selectValueField: string
  clearAfterSetValue?: boolean
  clearValue: () => void
}

const List: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    data,
    titleKey,
    onSelect,
    selected,
    selectValueField,
    multiple,
    clearAfterSetValue,
    clearValue,
  } = props

  const clickHandler = useCallback(
    (item: any) => () => {
      if (onSelect) onSelect(item)
      if (clearAfterSetValue) clearValue()
    },
    [clearAfterSetValue, clearValue, onSelect]
  )

  const isActive = useCallback(
    (item) => {
      if (!selected) return false
      if (multiple) {
        return (
          selected &&
          selected.findIndex(
            (_item: any) => _item?.[selectValueField] === item?.[selectValueField]
          ) >= 0
        )
      }
      return item[selectValueField] === selected[selectValueField]
    },
    [selected, multiple, selectValueField]
  )

  return (
    <MenuList className={classes.root}>
      {data.map((item: any) => (
        <MenuItem
          onClick={clickHandler(item)}
          selected={isActive(item)}
          key={item[selectValueField]}
        >
          <WordBreakWrapper>{item[titleKey]}</WordBreakWrapper>
        </MenuItem>
      ))}
    </MenuList>
  )
}

export default List
