// Core
import React, { FC } from 'react'
import { FastField, FieldProps } from 'formik'
import { TextFieldProps, TextField as TextFieldMaterial, Box } from '@material-ui/core'
import { isNullable, isString } from 'core/utils'
// Hooks
import { useInputValuePath } from 'core/hooks'
// Styles
import useStyles from './text-field.styles'

type TProps = {
  name: string
  localized?: boolean
} & TextFieldProps

const defaultProps = (props: any): TProps => ({
  fullWidth: true,
  variant: 'outlined',
  ...props,
})

const showError = (error: undefined | string | boolean): boolean => {
  if (isNullable(error)) return false
  if (isString(error)) return true
  return Boolean(error)
}

const TextField: FC<TProps> = (props) => {
  const classes = useStyles()
  const { name, localized = false, ...rest } = defaultProps(props)
  const valuePath = useInputValuePath(localized, name)

  if (!valuePath) return null

  return (
    <Box className={classes.root}>
      <FastField name={valuePath}>
        {({ field, meta }: FieldProps) => {
          const computedOnBlur = (e: any) => {
            field.onBlur(e)
            rest.onBlur?.(e)
          }
          return (
            <TextFieldMaterial
              {...field}
              {...rest}
              onBlur={computedOnBlur}
              error={meta.touched && showError(meta.error)}
              helperText={
                meta.touched && meta.error ? (
                  <span className={classes.helperText}>{meta.touched && meta.error}</span>
                ) : null
              }
            />
          )
        }}
      </FastField>
    </Box>
  )
}

export default TextField
