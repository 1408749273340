// Core
import React, { FC, useCallback } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import * as Yup from 'yup'
// Components
import { SmartSelectField } from 'core/form'
import { useTranslation } from 'react-i18next'
// Utils
import { transformResponseValidationData } from 'core/utils'

type Props = {
  onCancel: () => void
  onSubmit: (entityType: string) => void
}

type FormProps = { entityType: string }

const validationSchema = Yup.object().shape({
  entityType: Yup.string().required(),
})

const EntityTypeSettings: FC<Props> = (props) => {
  const { onCancel, onSubmit } = props
  const { t } = useTranslation()

  const initialValues: FormProps = { entityType: '' }

  const submitHandler = useCallback(
    async (values: FormProps, formHelpers: FormikHelpers<any>) => {
      try {
        await onSubmit(values.entityType)
      } catch (e) {
        // @ts-ignore
        const errors = transformResponseValidationData(e.response?.data?.violations)
        if (errors) formHelpers.setErrors(errors)
        formHelpers.setSubmitting(false)
      }
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => (
        <Form>
          <DialogContent>
            <SmartSelectField
              name="entityType"
              source={'entity_types'}
              label={t('select.entity-type')}
              reqParams={{ urlable: true }}
              valueField="@id"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button disabled={!formikProps.isValid || formikProps.isSubmitting} type="submit">
              {t('global.add')}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

export default EntityTypeSettings
