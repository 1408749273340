// Core
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
// Context
import { RedirectsProvider } from 'modules/redirects'
// Components
import { CreateRedirect, RedirectsTable, TableHeader } from 'modules/redirects'
import { PageTitle } from 'ui'
// Hooks
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
// Styles
import useStyles from './redirects-page.styles'

type Props = {
  children?: never
}

const RedirectsPage: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  useSetBreadcrumbsData([{ title: t('global.dashboard'), to: '/' }, { title: t('list.redirects') }])

  return (
    <RedirectsProvider>
      <PageTitle className={classes.title} title="redirects" />
      <div className={classes.root}>
        <CreateRedirect />
        <TableHeader />
        <RedirectsTable />
      </div>
    </RedirectsProvider>
  )
}

export default RedirectsPage
