import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    menuItemText: {
      opacity: 0,
      color: colors.blueGrey['600'],
      fontSize: '0.88rem',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: '10px',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuItem: {
      padding: theme.spacing(0.5),
    },
    manuItemFav: {
      background: '#f4f5f7',
    },
    menuItemTextOpen: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuItemIcon: {
      color: colors.blueGrey['600'],
    },
    menuIconArrow: {
      marginLeft: '55px',
    },
    favorites: {
      borderTop: '1px solid #e0e0e0',
      background: '#f4f5f7',
    },
  })
)

export default useStyles
