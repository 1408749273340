import React, { FC, useCallback, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { SmartSelect } from 'ui'
import CloseIcon from '@material-ui/icons/Close'

type TProps = {
  typeId: number
  insertTemplate: (id: string, withValues: boolean) => void
  isOpen: boolean
  onModalClose: () => void
  children?: never
}

const InsertTemplate: FC<TProps> = (props) => {
  const { typeId, insertTemplate, isOpen, onModalClose } = props

  const [selected, setSelected] = useState<any>(null!)
  const [loading, setLoading] = useState(false)

  const insertHandler = useCallback(
    async (withValues = true) => {
      setLoading(true)
      await insertTemplate(selected, withValues)
      setLoading(false)
      setSelected(null)
      onModalClose()
    },
    [insertTemplate, onModalClose, selected]
  )

  return (
    <>
      <Dialog open={isOpen} onClose={onModalClose} keepMounted={false}>
        <DialogTitle>
          Insert Template
          <IconButton
            aria-label="close"
            onClick={onModalClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SmartSelect
            source={`entities`}
            label={'Select template'}
            valueField="@id"
            reqParams={{ template: true, entityType: `/api/entity_types/${typeId}` }}
            onChange={(e) => {
              setSelected(e)
            }}
          />
          <DialogContentText style={{ marginTop: 10 }}>
            Previously content will be lost!!!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            size="small"
            variant="contained"
            onClick={() => insertHandler()}
            disabled={loading || !selected}
          >
            Insert With Content
          </Button>
          <Button
            type="button"
            color="primary"
            size="small"
            variant="contained"
            onClick={() => insertHandler(false)}
            disabled={loading || !selected}
          >
            Insert Without Content
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InsertTemplate
