import { isAxiosError } from 'axios'
import { LockData } from './types'
import { httpService } from 'core/data'

/**
 * Suggestion
  time_to_live: 10m
  refresh_time: 8m
  idle_time: 30m
  idle_wait: 60s
 */

export const API_ROUTE = '/locks'
export const REFRESH_TIME = 9 * 60 * 1000
export const TIME_TO_LIVE = 10 * 60
export const IDLE_WAIT_TIME = 60
export const WAIT_FOR_ACCESS_TIME = 30_000

export const ASK_CONTROL_EVENT = 'ASK_CONTROL'
export const CANCEL_ASK_CONTROL = 'CANCEL_ASK_CONTROL'
export const DENY_CONTROL_EVENT = 'DENY_CONTROL'
export const LOCK_DELETED_EVENT = 'LockDeleted'
export const LOCK_UPDATED_EVENT = 'LockUpdated'

export const userParser = {
  parse: (value: string) => JSON.parse(value),
  stringify: (userData: any) => {
    const dataToStringify = {
      userId: userData.id,
      userName: `${userData.firstName} ${userData.lastName}`,
    }
    return JSON.stringify(dataToStringify)
  },
}

export const parseSocketEvent = (e: any) => {
  const eventData = JSON.parse(e.data)
  const isEventFromBackend = Boolean(eventData.event)

  const eventName = isEventFromBackend ? eventData.event : eventData.type

  let data: any = {}

  if (isEventFromBackend) {
    data = eventData.data
  } else {
    data = eventData?.data ? JSON.parse(eventData.data) : {}
  }

  return {
    event: eventName,
    data,
  }
}

export class LockService {
  static isLockExistError(error: unknown) {
    if (isAxiosError(error)) {
      return error.response?.data['hydra:description']?.includes('already existing')
    }
    return false
  }

  static generateLockData(lockKey: string, user: any): LockData {
    let lockValue: any

    if ('userName' in user) {
      lockValue = user
    } else {
      lockValue = { userId: user.id, userName: `${user.firstName} ${user.lastName}` }
    }

    const lockRecord = { key: lockKey, ttl: TIME_TO_LIVE, value: JSON.stringify(lockValue) }
    return lockRecord
  }

  static createLock(lockData: LockData) {
    return httpService.post(API_ROUTE, lockData)
  }

  static updateLock(lockKey: string, lockData: LockData) {
    return httpService.put(`${API_ROUTE}/${lockKey}`, lockData)
  }

  static deleteLock(lockKey: string) {
    return httpService.deleteKeepAlive(`${API_ROUTE}/${lockKey}`)
  }

  static async getLock(lockKey: string): Promise<LockData> {
    const res = await httpService.get<LockData>(`${API_ROUTE}/${lockKey}`)
    return res.data
  }
}
