// Core
import React, { FC } from 'react'
import { Draggable, DraggableProps } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'
// Components
import WidgetItem from '../widget-item'

type TProps = {
  data: any
  disableMoveUp: boolean
  disableMoveDown: boolean
} & Omit<DraggableProps, 'children'>

const DraggableItem: FC<TProps> = (props) => {
  const { data, disableMoveDown, disableMoveUp, ...rest } = props

  return (
    <Draggable {...rest}>
      {(providedDrag) => {
        return (
          <Grid innerRef={providedDrag.innerRef} {...providedDrag.draggableProps} item xs={12}>
            <WidgetItem
              disableMoveUp={disableMoveUp}
              disableMoveDown={disableMoveDown}
              index={rest.index}
              data={data}
              dnd={providedDrag.dragHandleProps}
            />
          </Grid>
        )
      }}
    </Draggable>
  )
}

export default DraggableItem
