import { BreadcrumbsContextProps } from '../types'
import { useContext } from 'react'
import { BreadcrumbsContext } from '../context'

type UseBreadcrumbsContext = () => BreadcrumbsContextProps

export const useBreadcrumbsContext: UseBreadcrumbsContext = () => {
  const context = useContext<BreadcrumbsContextProps>(BreadcrumbsContext)

  return context
}
