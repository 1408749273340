import React from 'react'
import { Grid } from '@material-ui/core'
import { SmartSelectField, TextField } from 'core/form'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { useTranslation } from 'react-i18next'

const DefaultFields = () => {
  const { t } = useTranslation()
  const {
    data: gaEntityTypes,
    isLoading: loadingGa,
    isError: errorGa,
  } = useQuery('ga-entity-type', () => {
    return httpService
      .get<any>('entity_types', { params: { slug: 'ga-event' } })
      .then((res) => res.data['hydra:member'][0])
  })

  return (
    <>
      <Grid item xs={12}>
        <TextField name="link_text" label={`${t('labels.link-text')}`} />
      </Grid>
      {!loadingGa && !errorGa && gaEntityTypes && (
        <Grid item xs={12}>
          <SmartSelectField
            valueField={'id'}
            source={'entities'}
            name={'ga_event'}
            label={t('select.event')}
            reqParams={{ entityType: gaEntityTypes['@id'] }}
          />
        </Grid>
      )}
    </>
  )
}

export default DefaultFields
