// Core
import { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
// Hooks
import { useRedirectsContext } from './use-redirects-context'
import { useNotify } from 'core/hooks'
// Services
import { httpService } from 'core/data'
// Utils
import { transformResponseValidationData } from 'core/utils'
// Types
import { Redirect } from '../types'

type MutationData = {
  type: 'delete' | 'update'
  data: Redirect
}

export const useRedirectController = (data?: Redirect) => {
  const notify = useNotify()
  const { createMutation, invalidate, setModalOpen } = useRedirectsContext()
  const [isEdit, setIsEdit] = useState(false)

  const mutation = useMutation<unknown, unknown, MutationData>(
    (mutationData) => {
      const { type, data } = mutationData
      if (type === 'delete') return httpService.delete(`/redirects/${data.id}`)
      return httpService.put(`/redirects/${data.id}`, data)
    },
    {
      onSettled: async () => {
        await invalidate()
      },
    }
  )

  const form = useFormik<Redirect>({
    initialValues: data || {
      type: '301',
      fromUrl: '',
      toUrl: '',
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      values = { ...values }
      values.type = +values.type
      values.fromUrl = values.fromUrl.replace(/\/+$|\s/g, '') // replace all '/' and ' '
      values.toUrl = values.toUrl.replace(/\s/g, '') // replace all ' '

      try {
        if (data) {
          await mutation.mutateAsync({
            type: 'update',
            data: values,
          })
          setIsEdit(false)
        } else {
          await createMutation.mutateAsync(values)
          notify('Redirect created successfully', { type: 'success' })
          setModalOpen(false)
        }
        helpers.resetForm()
      } catch (e) {
        // @ts-ignore
        const errors = transformResponseValidationData(e.response?.data?.violations)
        if (errors) helpers.setErrors(errors)
      }
    },
  })

  const deleteHandler = useCallback(async () => {
    if (isEdit) {
      setIsEdit(false)
    } else {
      await mutation.mutateAsync({
        type: 'delete',
        data: data!,
      })
    }
  }, [data, isEdit, mutation])

  const editHandler = useCallback(async () => {
    if (!isEdit) {
      setIsEdit(true)
    } else {
      await form.submitForm()
    }
  }, [form, isEdit])

  return {
    form,
    mutation,
    deleteHandler,
    editHandler,
    isEdit,
  }
}
