// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getIn, useField, useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { SmartSelect, SmartSelectValue } from 'ui'
import SmartSelectField from '../smart-select-field'
import { getIdFromIri } from 'core/utils'
import { httpService } from 'core/data'
import { AttributeData } from 'modules/new-entity/types'
// Styles
import useStyle from './ier-reference-field.style'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  children?: never
  data: AttributeData
  defaultValues?: any
}

const IerReferenceField: FC<Props> = (props) => {
  const {
    name,
    data: { entityTypes },
    data,
    defaultValues,
  } = props
  const classes = useStyle()
  const { t } = useTranslation()
  const { errors, touched } = useFormikContext<any>()
  const [field, , { setValue }] = useField(name)
  const [typeIri, setEntityType] = useState<SmartSelectValue>()

  const {
    checkedId,
    enabled,
    //
  } = useMemo(() => {
    const { value: checkedIri } = defaultValues
    const localValueIri = field.value

    const checkedId = getIdFromIri(localValueIri || checkedIri)
    return { checkedId, enabled: Boolean(checkedId) }
  }, [defaultValues, field])

  const { isLoading } = useQuery(
    ['entities', checkedId],
    async () => {
      const response = await httpService.get<any>(`/entities/${checkedId}`)
      return response.data.entityType
    },
    {
      enabled,
      cacheTime: 0,
      onSuccess: (entityType) => {
        setEntityType(entityType)
      },
    }
  )

  const changeHandler = useCallback(
    (value: SmartSelectValue) => {
      setEntityType(value)
      setValue('')
    },
    [setValue]
  )

  const error =
    getIn(errors, name) && !typeIri ? getIn(touched, name) && getIn(errors, name) : undefined

  if (isLoading) return <Skeleton variant="rect" height="57px" />

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <div>
          <SmartSelect
            valueField={'@id'}
            label={data.name}
            value={typeIri}
            onChange={changeHandler}
            source={'entity_types'}
            reqParams={{ id: entityTypes }}
            error={error}
          />
        </div>
        {!!typeIri && (
          <div>
            <SmartSelectField
              valueField={'@id'}
              source={'entities'}
              reqParams={{ entityType: typeIri }}
              name={name}
              label={t('select.entity')}
            />
          </div>
        )}
      </Box>
    </Box>
  )
}

export default IerReferenceField
