// Core
import React from 'react'
// Components
import { DataView } from 'core/data'
import { FolderColumn, GridItem, NameColumn, Sidebar, SizeColumn } from '../../../list/components'
import { DefaultActionColumns, getActions, getTableColumn } from 'core/data/components/data-view'
import { SelectedFileView } from './components'
import { useTranslation } from 'react-i18next'

export const MediaLibrary = () => {
  const { t } = useTranslation()

  return (
    <DataView
      showCheckBox
      resourceProps={{ name: 'media' }}
      controllerOptions={{ filterChangeUrl: false }}
      sidebar={<Sidebar showCreateButton={false} />}
      columns={[
        getActions([DefaultActionColumns.DELETE], t),
        getTableColumn({ title: 'Name', width: 'auto' }, 'custom', NameColumn),
        getTableColumn({ title: 'Size', width: '100' }, 'custom', SizeColumn),
        getTableColumn({ title: 'Folder', field: 'folder' }, 'custom', FolderColumn),
        getTableColumn({ title: 'Author', field: 'createdBy' }, 'reference', null, {
          field: 'email',
          source: 'users',
        }),
        getTableColumn({ title: 'Created at', field: 'createdAt', width: 124 }, 'date'),
      ]}
      hasGrid={true}
      gridItemComponent={GridItem}
      inModal={true}
    >
      <SelectedFileView />
    </DataView>
  )
}
