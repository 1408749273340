// Core
import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { FormControl, Input, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
// Styles
import useStyles from './search.styles'

type Props = {
  children?: never
  onChange?: (value: string) => void
}

const Search: FC<Props> = (props) => {
  const { onChange } = props
  const [value, setValue] = useState('')
  const classes = useStyles()

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value)

      setValue(e.target.value)
    },
    [onChange]
  )

  return (
    <FormControl className={classes.root}>
      <Input
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        onChange={changeHandler}
      />
    </FormControl>
  )
}

export default Search
