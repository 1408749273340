import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'
import { useNotify } from 'core/hooks'
import { EntityService } from '../utils'
import { EntityStatus } from '../types'

type Params = {
  onSuccess?: () => void
}

export const useChangeEntityStatus = (entityIri: string, params: Params = {}) => {
  const { setConfirmation } = useConfirmation()
  const { t } = useTranslation()
  const notify = useNotify()

  const changeStatusM = useMutation(
    (status: EntityStatus) => EntityService.changeEntityStatus(entityIri, status),
    {
      onSuccess: () => {
        notify(`Successfully changed status`, { type: 'success' })
        params?.onSuccess?.()
      },
      onError: (error) => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const changeEntityStatus = useCallback(
    (status: EntityStatus) => {
      const messages = {
        title: '',
        content: '',
      }

      if (status === 'archive') {
        messages.title = t('global.archive')
        messages.content = t('notify.archive')
      } else if (status === 'draft') {
        messages.title = 'Restore'
        messages.content = 'Status will be changed to “Draft” do you want to continue?'
      }

      setConfirmation({
        open: true,
        ...messages,
        onSuccess: () => changeStatusM.mutate(status),
      })
    },
    [setConfirmation, t, changeStatusM]
  )

  return { changeEntityStatus, changeStatusM }
}
