import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      backgroundColor: '#fff',
    },
    title: {
      ...theme.typography.h2,
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(1),
      backgroundColor: '#fff',
    },
  })
)

export default useStyles
