// Core
import React, { FC, useCallback } from 'react'
import { useField } from 'formik'
// Components
import { RichEditor, RichEditorProps } from 'core/rich-editor'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
// Hooks
import { useInputValuePath } from 'core/hooks'
// Styles
import useStyles from './editor-field.styles'

type TProps = {
  name: string
  label: string
  localized?: boolean
} & RichEditorProps

const EditorField: FC<TProps> = (props) => {
  const classes = useStyles()
  const { name, label, localized = false, ...res } = props
  const valuePath = useInputValuePath(localized, name)
  const [field, meta, helpers] = useField(valuePath)

  const hasError = meta.touched && Boolean(meta.error)

  const onChange = useCallback(
    (value: any) => {
      helpers.setValue(value)
    },
    [helpers]
  )

  if (!valuePath) return null

  return (
    <FormControl className={classes.root} error={hasError}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <RichEditor {...res} {...field} onChange={onChange} />
      {hasError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default EditorField
