import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleWrapper: {
      display: 'flex',
    },
    title: {
      margin: '10px 0 11px',
      fontSize: 18,
    },
    icon: {
      marginRight: 15,
      fontSize: 34,
    },
  })
)

export default styles
