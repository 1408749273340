import { FormAttribute, SetRepeat, Value } from './types'
import { v4 as uniqueKey } from 'uuid'
import setWith from 'lodash.setwith'
import { Localization } from 'core/types'
import { AttributeTypesList, OneOfAttributeTypes } from 'common/types'
import { isNullable, withWrapValue } from 'core/utils'

type Values = {
  values: Value[]
  setRepeats: SetRepeat[]
}

enum ValuesTypes {
  simple = 'simple',
  ref_multiple = 'ref_multiple',
  localized = 'localized',
}

type OneOfValuesType = keyof typeof ValuesTypes

const valuesTypesList: any = {
  [ValuesTypes.simple]: [
    AttributeTypesList.string,
    AttributeTypesList.slug,
    AttributeTypesList.integer,
    AttributeTypesList.decimal,
    AttributeTypesList.text,
    AttributeTypesList.text_editor,
    AttributeTypesList.attachment,
    AttributeTypesList.image,
    AttributeTypesList.reference_one_to_one,
    AttributeTypesList.reference_many_to_one,
    AttributeTypesList.reference_many_to_one_hierarchical,
    AttributeTypesList.reference_many_to_one_multiple_entity_types,
  ],
  [ValuesTypes.ref_multiple]: [
    //
    AttributeTypesList.reference_many_to_one_multiple_types,
  ],
  [ValuesTypes.localized]: [
    AttributeTypesList.string_l10n,
    AttributeTypesList.text_l10n,
    AttributeTypesList.text_editor_l10n,
  ],
}

export class DefaultValuesGenerator {
  private formValues: any = {}

  constructor(
    private formData: FormAttribute[],
    private localizations: Localization[],
    private attrValues?: Values
  ) {
    formData.forEach((attrData) => {
      const {
        attribute: {
          attributeType: { type },
        },
      } = attrData

      const finalType = this.getGenerationType(type)

      try {
        this[finalType](attrData, attrValues)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Method "${type}" for resolve default value not exist`)
      }
    })
  }

  private getGenerationType(attributeType: OneOfAttributeTypes) {
    const typeKeys = Object.keys(valuesTypesList) as OneOfValuesType[]
    for (const typeKey of typeKeys) {
      if (valuesTypesList[typeKey].indexOf(attributeType) >= 0) {
        return typeKey
      }
    }
    return attributeType as OneOfValuesType
  }

  getValues() {
    return {
      key: uniqueKey(),
      ...this.formValues,
    }
  }

  /**
   * Rewrited
   * handlers
   */
  private [ValuesTypes.simple](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueObj = values?.values.find((item) => item.attribute === attrIri)

    if (valueObj && valueObj?.value) {
      this.formValues[attrIri] = {
        ...withWrapValue(valueObj.value, valueObj?.id),
        type: attribute.attributeType.type,
        isJson: attribute.options.show_links_modal,
      }
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        this.formValues[attrIri] = {
          // @ts-ignore
          ...withWrapValue(attribute.defaultValues.value),
          type: attribute.attributeType.type,
          isJson: attribute.options.show_links_modal,
        }
      } else {
        this.formValues[attrIri] = {
          ...withWrapValue(''),
          type: attribute.attributeType.type,
          isJson: attribute.options.show_links_modal,
        }
      }
    }
  }

  private [ValuesTypes.ref_multiple](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueObj = values?.values.find((item) => item.attribute === attrIri)

    if (valueObj) {
      if (isNullable(valueObj.value)) {
        this.formValues[attrIri] = withWrapValue('', valueObj?.id)
      } else {
        this.formValues[attrIri] = withWrapValue(valueObj.value, valueObj?.id)
      }
    } else {
      this.formValues[attrIri] = withWrapValue('')
    }
  }

  private [ValuesTypes.localized](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueArray = values?.values.filter((item) => item.attribute === attrIri) || []

    if (valueArray.length) {
      this.localizations.forEach((locale) => {
        const valueObj = valueArray.find((item) => {
          /** TODO (bad fix now) -> change includes to ===
           * backend return locale IRI some different
           * (/api/locale/ ...) & (api/locale/ ...) */
          return locale['@id']?.includes(String(item.locale))
        })

        this.formValues = setWith(
          this.formValues,
          `translations.${locale.code}.${attrIri}`,
          withWrapValue(valueObj?.value || '', valueObj?.id)
        )
      })
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)

      if (hasDefault) {
        this.localizations.forEach((locale) => {
          this.formValues = setWith(
            this.formValues,
            `translations[${locale.code}].${attrIri}`,
            // @ts-ignore
            withWrapValue(attribute.defaultValues?.value)
          )
        })
      } else {
        this.localizations.forEach((locale) => {
          this.formValues = setWith(
            this.formValues,
            `translations[${locale.code}].${attrIri}`,
            withWrapValue('')
          )
        })
      }
    }
  }

  /**
   * Uniq
   * handlers
   */

  private [AttributeTypesList.boolean](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueObj = values?.values.find((item) => item.attribute === attrIri)

    if (valueObj) {
      this.formValues[attrIri] = withWrapValue(valueObj.value, valueObj?.id)
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        // @ts-ignore
        this.formValues[attrIri] = withWrapValue(attribute.defaultValues.value !== 'false')
      } else {
        this.formValues[attrIri] = withWrapValue(false)
      }
    }
  }

  private [AttributeTypesList.date_time](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueObj = values?.values.find((item) => item.attribute === attrIri)

    if (valueObj) {
      this.formValues[attrIri] = withWrapValue(valueObj.value, valueObj?.id)
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        // @ts-ignore
        this.formValues[attrIri] = withWrapValue(attribute.defaultValues.value)
      } else {
        this.formValues[attrIri] = withWrapValue(null)
      }
    }
  }

  private [AttributeTypesList.select](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valueObj = values?.values.find((item) => item.attribute === attrIri)

    if (valueObj) {
      this.formValues[attrIri] = withWrapValue(valueObj.value || '', valueObj?.id)
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        // @ts-ignore
        const defaultValue = attribute.defaultValues?.value
        const findDefaultEnum = attribute.attributeEnums?.find(
          (enumItem) => enumItem.value === defaultValue
        )
        this.formValues[attrIri] = withWrapValue(findDefaultEnum?.id || '')
      } else {
        this.formValues[attrIri] = withWrapValue('')
      }
    }
  }

  private [AttributeTypesList.reference_many_to_many](attrData: FormAttribute, values?: Values) {
    /**
     * TODO
     * This is rewrited attr (not used id) */

    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valuesArr = values?.values
      .filter((item) => item.attribute === attrIri)
      .sort((a, b) => a?.sortOrder - b?.sortOrder)

    if (valuesArr && valuesArr.length && valuesArr.every((i) => !isNullable(i.value))) {
      this.formValues[attrIri] = withWrapValue(valuesArr.map((val) => val.value))
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        // @ts-ignore
        this.formValues[attrIri] = withWrapValue(attribute.defaultValues.value)
      } else {
        this.formValues[attrIri] = withWrapValue([])
      }
    }
  }

  private [AttributeTypesList.reference_many_to_many_multiple_types](
    attrData: FormAttribute,
    values?: Values
  ) {
    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valuesArr = values?.values.filter((item) => item.attribute === attrIri)

    if (valuesArr && valuesArr.length && valuesArr.every((i) => !isNullable(i.value))) {
      this.formValues[attrIri] = withWrapValue(valuesArr.map((val) => val.value))
    } else {
      this.formValues[attrIri] = withWrapValue([])
    }
  }

  private [AttributeTypesList.multi_select](attrData: FormAttribute, values?: Values) {
    /**
     * TODO
     * This is rewrited attr (not used id) */

    const { attribute } = attrData
    const attrIri = attribute['@id']
    const valuesArr = values?.values.filter((item) => item.attribute === attrIri)

    if (valuesArr && valuesArr?.length) {
      this.formValues[attrIri] = withWrapValue(valuesArr.map((val) => val.value))
    } else {
      const hasDefault = !Array.isArray(attribute.defaultValues)
      if (hasDefault) {
        // @ts-ignore
        const defaultValue = attribute.defaultValues?.value
        const findDefaultEnum = attribute.attributeEnums?.filter((enumItem) =>
          defaultValue.includes(enumItem.value)
        )
        this.formValues[attrIri] = withWrapValue(
          findDefaultEnum?.map((enumItem) => enumItem.id) || []
        )
      } else {
        this.formValues[attrIri] = withWrapValue([])
      }
    }
  }

  /**
   * Repeater & Group
   * handlers
   */
  private [AttributeTypesList.repeater](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const findValues = values?.setRepeats?.filter((item) => item.set === attribute['@id']) || []

    this.formValues[attribute['@id']] = withWrapValue(
      findValues.map((attr) => {
        const generator = new DefaultValuesGenerator(
          attribute.setAttributes || [],
          this.localizations,
          {
            values: attr.values,
            // @ts-ignore
            setRepeats: attr?.setRepeats || attr?.entitySetRepeats || [],
          }
        )
        return {
          id: attr?.id,
          ...generator.getValues(),
        }
      })
    )
  }

  private [AttributeTypesList.group](attrData: FormAttribute, values?: Values) {
    const { attribute } = attrData
    const groupValues = values?.setRepeats.find((item) => item.set === attribute['@id'])

    const generator = new DefaultValuesGenerator(
      attribute.setAttributes || [],
      this.localizations,
      {
        values: groupValues?.values || [],
        // @ts-ignore
        setRepeats: groupValues?.setRepeats || groupValues?.entitySetRepeats || [],
      }
    )

    this.formValues[attribute['@id']] = withWrapValue(generator.getValues(), groupValues?.id)
  }
}
