import React, { FC } from 'react'
import { Box, FormLabel, Paper } from '@material-ui/core'
import { useField } from 'formik'
import { schemes } from './schemes'
import { useSitesContext } from 'modules/sites'
// Styles
import useStyles from './radio-color.styles'

type TProps = {
  options: { value: string | number; label: string | number }[]
  name: string
  label?: string
  disabled?: boolean
  attrSlug: string
}

const RadioColor: FC<TProps> = (props) => {
  const classes = useStyles()
  const { options, name, label = '', attrSlug } = props
  const [{ value }, , { setValue }] = useField(name)
  const {
    siteSettings: {
      colorScheme: { value: schemeName },
    },
  } = useSitesContext()
  const schema = schemes[schemeName]
  const colors = schema ? schema[attrSlug] : []

  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    setValue(Number((event.currentTarget as HTMLInputElement).getAttribute('data-value')))
  }

  return (
    <Box className={classes.root}>
      <FormLabel component="legend">{label}</FormLabel>
      <Box className={classes.list}>
        {options.length &&
          options.map((item, index) => {
            return (
              <div className={classes.paperWrap} key={index}>
                <span style={{ fontWeight: value === item.value ? 'bold' : 'normal' }}>
                  {item.label}
                </span>
                <Paper
                  className={classes.paper}
                  variant="outlined"
                  onClick={handleChange}
                  data-value={item.value}
                  style={{
                    background: colors[index],
                  }}
                >
                  {value === item.value && <span className={classes.checked} />}
                </Paper>
              </div>
            )
          })}
      </Box>
    </Box>
  )
}

export default RadioColor
