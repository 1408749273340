import { AppBar, Box, Drawer } from '@material-ui/core'
import React, { cloneElement, FC, ReactElement } from 'react'
import sidebarStyles from './sidebar.styles'
import { SubmitButton } from 'core/data/components/crud-form-container/components'

type TProps = {
  children?: ReactElement
  type: 'create' | 'edit'
}

const SidebarGlobal: FC<TProps> = (props) => {
  const { children, type } = props
  const classes = sidebarStyles()

  return (
    <Drawer variant="permanent" anchor="right" className={classes.root}>
      <AppBar color="transparent" position="sticky" className={classes.appBar}>
        <Box className={classes.buttonsBox}>
          <SubmitButton type={type} />
        </Box>
      </AppBar>
      {children ? cloneElement(children, { ...props }) : null}
    </Drawer>
  )
}

export default SidebarGlobal
