// Core
import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Hooks
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
import { Permissions, useAppContext } from 'core/app'
// Types
import { ResourceProps } from 'core/data'
// Components
import { PageTitle } from 'ui'
import { CreateTranslation, Pagination, TranslationTable } from './components'
import { TranslationProvider } from '../../context'
// Styles
import useStyles from './edit.styles'

type TProps = {} & ResourceProps

export const EditLocalization: FC<TProps> = (props) => {
  const classes = useStyles()
  const location = useLocation<{ name: string }>()
  const { t } = useTranslation()
  const {
    actions: { userCan },
  } = useAppContext()

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t('list.localizations'), to: '/translations' },
    { title: location.state?.name },
  ])

  return (
    <TranslationProvider>
      <PageTitle className={classes.title} title="translations" />
      <div className={classes.root}>
        {userCan('translations', Permissions.CREATE, 'system') && <CreateTranslation />}

        <TranslationTable />
        <Pagination />
      </div>
    </TranslationProvider>
  )
}
