import React, { FC, MouseEvent, useCallback } from 'react'
// Material Components
import { Button } from '@material-ui/core'
// Material Icons
import AccountCircle from '@material-ui/icons/AccountCircle'
// Styles
import userStyles from './user.styles'
import { useAppContext } from 'core/app'

type TProps = {
  handleUserMenuOpen: (event: MouseEvent<HTMLElement>) => void
  handleMenuClose: () => void
  menuId: string
  isMenuOpen: boolean
  anchorEl: HTMLElement | null
}

const User: FC<TProps> = (props) => {
  const { user } = useAppContext()
  const classes = userStyles()
  const { handleUserMenuOpen, menuId } = props

  const userMenuOpenHandler = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (handleUserMenuOpen) handleUserMenuOpen(event)
    },
    [handleUserMenuOpen]
  )

  return (
    <Button
      aria-controls={menuId}
      // edge="end"
      aria-label="account of current user"
      aria-haspopup="true"
      onClick={userMenuOpenHandler}
      color="inherit"
    >
      <AccountCircle style={{ width: 40, height: 40 }} />
      <span className={classes.userText}>
        {`${user?.firstName} ${user?.lastName}` || user?.email}
      </span>
    </Button>
  )
}

export default User
