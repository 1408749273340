import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors, defaultThemeSizes } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors
const { appBarHeight } = defaultThemeSizes

const mainLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      paddingTop: appBarHeight,
      backgroundColor: contentBg,
    },
    toolbarGhost: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },

    grow: {
      flexGrow: 1,
    },
  })
)

export default mainLayoutStyles
