// Core
import React, { createElement, ElementType, FC, useCallback, useMemo } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
// Components
import CrudFormController from '../crud-form-controller'
// Hooks
import { useNotify, useUnsavedChangesWarning } from 'core/hooks'
import { LanguageProvider, useUpdate } from 'core/data'
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
// Types
import { ResourceProps } from 'core/data'
// Utils
import { transformResponseValidationData } from 'core/utils'
import { httpService } from 'core/data'
// Styles
import { useStyles } from './edit.styles'
// Translates
import { useTranslation } from 'react-i18next'

type TProps = {
  crudForm: ElementType
  sidebar?: any
  validationSchema?: any
  onSuccess?: () => void
  initialData?: any
} & ResourceProps

const Edit: FC<TProps> = (props) => {
  const { crudForm, name, sidebar, validationSchema, onSuccess, initialData = {} } = props
  const {
    prompt,
    methods: { setRef, savedChanges },
  } = useUnsavedChangesWarning()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const notify = useNotify()

  const createSuccessHandler = useCallback(() => {
    notify(t('notify.updated', { name }), {
      type: 'success',
    })

    queryClient.invalidateQueries(name)
    if (onSuccess) onSuccess()
  }, [name, notify, queryClient, t, onSuccess])

  const { data, isLoading, isFetching } = useQuery(
    [name, id],
    async () => {
      const response = await httpService.get<any>(`/${name}/${id}`)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      onError() {
        history.replace(`/not-found`)
      },
    }
  )

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t(`list.${name}`), to: `/${name}` },

    {
      title: `${t('global.edit')} - ${
        data ? data.name || ` ${data.firstName} ${data.lastName}` : ''
      }`,
    },
  ])

  const { error, mutate: update, isLoading: mutationIsLoading } = useUpdate(name)

  const onSubmitHandler = useCallback(
    (values) => {
      update(
        { id, values },
        {
          onSuccess: createSuccessHandler,
        }
      )
      savedChanges()
    },
    [createSuccessHandler, id, savedChanges, update]
  )

  const formErrors = useMemo(
    // @ts-ignore
    () => transformResponseValidationData(error ? error.violations : null),
    [error]
  )

  if (isLoading) {
    return (
      <div className={classes.spiner}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <LanguageProvider>
      <CrudFormController
        type="edit"
        onSubmit={onSubmitHandler}
        initialProps={{ ...data, ...initialData }}
        errors={formErrors}
        sidebar={sidebar}
        setRef={setRef}
        validationSchema={validationSchema}
        loading={isFetching || mutationIsLoading}
      >
        {crudForm ? createElement(crudForm, { ...props }) : <div />}
      </CrudFormController>
      {prompt}
    </LanguageProvider>
  )
}
export default Edit
