// Core
import React, { FC, ReactElement } from 'react'
import { Box } from '@material-ui/core'
// Styles
import useStyles from './form-holder.styles'

type TProps = {
  title: string
}

const FormHolder: FC<TProps> = (props) => {
  const { children, title } = props
  const classes = useStyles()

  return (
    <Box className={classes.root} justifyContent="center">
      <header className={classes.header}>
        <p>{title}</p>
      </header>
      <div className={classes.inputsContainer}>{children as ReactElement}</div>
    </Box>
  )
}

export default FormHolder
