import React, { FC } from 'react'
import { Edit } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  alias: Yup.string().required(),
  // userRoles: Yup.array().required(),
  // sites: Yup.array().required(),
})

const UserEdit: FC<ResourceProps> = (props) => {
  return <Edit validationSchema={validationSchema} {...props} crudForm={CrudForm} />
}

export default UserEdit
