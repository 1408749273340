import React, { FC, useCallback, useMemo } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { WordBreakWrapper } from 'ui'
import { useTranslation } from 'react-i18next'
import { typesList } from 'modules/new-entity/utils'

const TypeSelect: FC = () => {
  const { values, resetForm, setFieldValue } = useFormikContext<any>()
  const { t } = useTranslation()

  const changeHandler = useCallback(
    (event: any) => {
      resetForm({
        values: {
          ...values,
          ga_event: '',
          brand: '',
          no_follow: false,
          new_tab: false,
          url: null,
          entity: null,
          affiliate: null,
        },
      })
      setFieldValue('type', event.target.value)
    },
    [resetForm, setFieldValue, values]
  )

  const types = useMemo(() => typesList(t), [t])

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="attribute_type-select">{t('links.select-type')}</InputLabel>
      <Select
        label={t('links.select-type')}
        id="link_type-select"
        value={values.type || ''}
        defaultValue={values.type || ''}
        onChange={changeHandler}
      >
        {types.map((item: any) => {
          return (
            <MenuItem key={item.slug} value={item.slug}>
              <WordBreakWrapper>{item.title}</WordBreakWrapper>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default TypeSelect
