import cloneDeep from 'lodash.clonedeep'

export const filterParams = (listParams: any, removedFilter: string) => {
  const {
    params: { filters },
  } = listParams
  if (filters.search && filters[removedFilter]) {
    const clonedFilters = cloneDeep(filters)
    delete clonedFilters[removedFilter]

    return {
      ...listParams,
      params: {
        ...listParams.params,
        filters: {
          ...clonedFilters,
        },
      },
    }
  }
  return listParams
}

export const transformParams = (query: any) => {
  return {
    page: query.params?.pagination?.page,
    itemsPerPage: query.params?.pagination?.perPage,
    ...query.params?.filters,
    ...query.params?.sort,
  }
}
