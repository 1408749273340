// Core
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { useIdleTimer } from 'react-idle-timer'
// Utils
import { WAIT_FOR_ACCESS_TIME } from './utils'
// Types
import { AskControlData } from './types'

type Props = {
  data: AskControlData
  onClose: () => void
  onApproveControl: (withSave?: boolean) => void
  saveLoading: boolean
}

const ControlRequestScreen: FC<Props> = (props) => {
  const { data, onClose, onApproveControl, saveLoading } = props
  const [counter, setCounter] = useState(0)

  const closeHandler = useCallback(() => {
    onClose()
  }, [onClose])

  const onIdle = () => {
    onApproveControl()
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: WAIT_FOR_ACCESS_TIME,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime])

  return (
    <Dialog open onClose={closeHandler}>
      <DialogContent>
        <b>{data.userName}</b> is asking to take editing control ({counter})
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="secondary">
          Deny
        </Button>
        <Button
          onClick={() => onApproveControl(true)}
          disabled={saveLoading}
          variant="contained"
          color="primary"
        >
          Save and Approve
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ControlRequestScreen
