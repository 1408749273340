// Core
import React, { FC } from 'react'
import { Box, Paper } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
// Context
import { DataViewProvider } from './data-view-context'
// Components
import TableHeader from './table-header'
import Table from './table'
import TableFooter from './table-footer'
// Hooks
import useController from './use-controller'

type Props = {
  typeId: number
}

const HeaderSkeleton = (
  <Box display="flex" p={1}>
    <Skeleton variant="rect" height={40} width={227} />
    <Skeleton variant="rect" height={40} width={85} style={{ margin: '0 10px' }} />
    <Skeleton variant="rect" height={40} width={85} />
    <Skeleton variant="rect" height={40} width={190} style={{ marginLeft: 'auto' }} />
  </Box>
)

const DataSkeleton = Array.from(Array(14).keys()).map((i) => (
  <Box key={i} px={i === 0 ? 0 : 1} py={i === 0 ? 0 : 2}>
    <Skeleton variant="rect" height={i === 0 ? 57 : 31} />
  </Box>
))

const DataView: FC<Props> = (props) => {
  const { typeId } = props
  const controllerProps = useController({ typeId })
  const { isDataLoading, isColumnsLoading } = controllerProps

  if (controllerProps.hasError) return <div>Error</div>

  return (
    <DataViewProvider value={controllerProps}>
      <Box m={1} component={Paper}>
        {isColumnsLoading ? HeaderSkeleton : <TableHeader />}
        {isColumnsLoading || isDataLoading ? (
          DataSkeleton
        ) : (
          <>
            <Table />
            <TableFooter />
          </>
        )}
      </Box>
    </DataViewProvider>
  )
}

export default DataView
