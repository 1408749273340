// Core
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
// Styles
import useStyle from './multi-select-field.style'
import { useField } from 'formik'

type TOptions = { value: string | number; label: string }[]

type TProps = {
  name: string
  label?: string
  children?: never
  options: TOptions
  disabled?: boolean
}

const MultiSelectField: FC<TProps> = (props) => {
  const { options, label, name, disabled } = props
  const classes = useStyle()
  const [field, meta, helpers] = useField(name)

  const initialData = useMemo(() => {
    const keepData: any[] = []
    options.forEach((item: any) => {
      if (!field.value) return
      if (field.value.includes(item.value) || field.value.includes(item._value)) {
        keepData.push(item)
      }
    })
    return keepData || []
  }, [field, options])

  const changeHandler = useCallback(
    (event: ChangeEvent<{}>, _value: TOptions) => {
      const getValuefromValue = _value.map((item: any) => item.value)
      helpers.setValue(getValuefromValue)
    },
    [helpers]
  )

  return (
    <Box className={classes.root}>
      <Autocomplete
        fullWidth
        multiple
        id={`multiple-${name}`}
        options={options}
        getOptionLabel={(option) => option.label}
        value={initialData}
        getOptionSelected={(option, value) => option.label === value.label}
        onChange={changeHandler}
        disabled={disabled}
        renderInput={(params) => {
          return (
            <TextField
              helperText={meta.touched && meta.error}
              {...params}
              fullWidth
              variant="outlined"
              error={Boolean(meta.error && meta.touched)}
              label={label}
              placeholder={label}
            />
          )
        }}
      />
    </Box>
  )
}

export default MultiSelectField
