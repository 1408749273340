// Core
import React, { createElement, ElementType, FC, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
// Components
import CrudFormController from '../crud-form-controller'
// Hooks
import { useNotify, useUnsavedChangesWarning } from 'core/hooks'
import { LanguageProvider, useCreate } from 'core/data'
import { useTranslation } from 'react-i18next'
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
// Utils
import { getIdFromIri, transformResponseValidationData } from 'core/utils'
// Types
import { ResourceProps } from 'core/data'

type TProps = {
  crudForm: ElementType
  initialData?: any
  sidebar?: any
  validationSchema?: any
  onSuccess?: () => void
} & ResourceProps

const Create: FC<TProps> = (props) => {
  const {
    prompt,
    methods: { setRef, savedChanges },
  } = useUnsavedChangesWarning()
  const { t } = useTranslation()
  const { name, crudForm, initialData, sidebar, validationSchema, onSuccess } = props

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t(`list.${name}`), to: `/${name}` },
    { title: t('global.create') },
  ])

  const history = useHistory()
  const notify = useNotify()

  const createSuccessHandler = useCallback(
    (data: any) => {
      notify(t('notify.created', { name }), {
        type: 'success',
      })

      const id = getIdFromIri(data.data['@id'])
      history.push(`/${name}/${id}`)
      if (onSuccess) onSuccess()
    },
    [history, name, notify, t, onSuccess]
  )

  const createErrorHandler = useCallback(
    (error: any) => {
      notify(error.response.data['hydra:description'], {
        type: 'error',
      })
    },
    [notify]
  )

  const { error, mutate: create } = useCreate(name)

  const formErrors = useMemo(
    // @ts-ignore
    () => transformResponseValidationData(error ? error.violations : null),
    [error]
  )

  const submitHandler = useCallback(
    (values) => {
      create(values, {
        onSuccess: createSuccessHandler,
        onError: createErrorHandler,
      })
      savedChanges()
    },
    [create, createSuccessHandler, createErrorHandler, savedChanges]
  )

  return (
    <LanguageProvider>
      <CrudFormController
        type="create"
        onSubmit={submitHandler}
        errors={formErrors}
        initialProps={initialData}
        sidebar={sidebar}
        setRef={setRef}
        validationSchema={validationSchema}
      >
        {crudForm ? createElement(crudForm, { ...props }) : <div>sdsd</div>}
      </CrudFormController>
      {prompt}
    </LanguageProvider>
  )
}

export default Create
