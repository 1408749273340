// Core
import React, { FC } from 'react'

type TProps = {}

const MainLayout: FC<TProps> = (props) => {
  const { children } = props

  return <div>{children}</div>
}

export default MainLayout
