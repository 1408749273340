// Core
import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Grid } from '@material-ui/core'

type Props = {
  children?: never
}

export const FormSkeleton: FC<Props> = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" animation="wave" height="60px" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
