// Core
import React, { FC, useCallback, useRef, useState } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
// Components
import { SmartSelect } from 'ui'
import QuickCreate from 'modules/new-entity/components/quick-create'
// Types
import { SmartSelectProps, SmartSelectValue } from 'ui'

type Props = {
  children?: never
  name: string
  createButton?: boolean
  entityTypeIri?: string | undefined
} & SmartSelectProps

const SmartSelectField: FC<Props> = (props) => {
  const { name, createButton, entityTypeIri, onChange, ...rest } = props
  const { t } = useTranslation()
  const [field, meta, { setValue, setTouched }] = useField(name)
  const [createOpen, setCreateOpen] = useState(false)

  // REF to get useSmartSelect methods
  const methodsRef = useRef<any>()

  const changeHandler = useCallback(
    (item: SmartSelectValue) => {
      setValue(item)
      if (onChange) onChange(item)
    },
    [setValue, onChange]
  )

  const setModalValue = useCallback(
    (entity: any) => {
      setValue(entity['@id'])
      methodsRef.current.setSelected(entity)
    },
    [setValue]
  )

  const blurHandler = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  return (
    <>
      <SmartSelect
        {...rest}
        value={field.value}
        onChange={changeHandler}
        onBlur={blurHandler}
        error={meta.touched ? meta.error : undefined}
        innerRef={methodsRef}
      />
      {createButton && (
        <>
          <Button
            onClick={() => setCreateOpen(true)}
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
          >
            {t('button.create-event')}
          </Button>
          {createOpen && (
            <QuickCreate
              isOpen={createOpen}
              setIsOpen={setCreateOpen}
              entityTypeIri={entityTypeIri}
              setCreatedValue={setModalValue}
            />
          )}
        </>
      )}
    </>
  )
}

export default SmartSelectField
