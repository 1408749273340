export type ResponseValidationError = {
  propertyPath: string
  message: string
}

export const transformResponseValidationData = (
  errors: ResponseValidationError[] | null
): { [key: string]: string } => {
  const errorsObj: { [key: string]: string } = {}

  if (!errors) return errorsObj

  errors.forEach((errorItem) => {
    errorsObj[errorItem.propertyPath] = errorItem.message
  })

  return errorsObj
}

export const getIdFromIri = (iri: string) => {
  try {
    const split = iri.split('/')
    return split[split.length - 1]
  } catch (e) {
    return iri
  }
}

export const isIri = (str: string) => typeof str === 'string' && str.startsWith('/api')
export const parseIri = (str: string) => str.split('/').slice(2)
