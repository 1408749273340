// Core
import { useCallback, useEffect, useRef, useState } from 'react'
import produce from 'immer'
import cloneDeep from 'lodash.clonedeep'
// Hooks
import { useSitesContext } from '../../sites'
import {
  DefaultValuesGenerator,
  EntityWidget,
  ValuesGenerator,
} from 'modules/new-entity/transformers'
// Utils
import { dragEndEvent } from 'common/utils/custom-events'
// Types
import { IEntity, PageBuilderContextProps, WidgetsDataRef } from '../types'
import { IEntityWidget } from 'modules/new-entity/types'
import { EntityService } from '../utils'

type PBP = PageBuilderContextProps
type Actions = PBP['actions']

export const useWidgetsController = (entity: IEntity) => {
  const { locales } = useSitesContext()
  const localizations = locales.site
  const [widgets, setWidgets] = useState(entity?.entityWidgets || [])
  const [activeWidgets, setActiveWidgets] = useState<PBP['activeWidgets']>({})

  const widgetsDataRef = useRef<WidgetsDataRef>({})

  useEffect(() => {
    if (entity?.entityWidgets) {
      setWidgets(entity.entityWidgets)
    }
  }, [entity?.entityWidgets])

  const setWidgetRefData = useCallback<Actions['setWidgetRefData']>((id, formRef, attributes) => {
    if (formRef) {
      widgetsDataRef.current[id] = {
        attributes,
        formRef,
      }
    }
  }, [])

  const getWidgetRefData = useCallback<Actions['getWidgetRefData']>(
    (widgetId) => {
      try {
        if (!widgetsDataRef.current[widgetId]) return null

        return widgetsDataRef.current[widgetId]
      } catch (e) {
        return null
      }
    },
    [widgetsDataRef]
  )

  const toggleContainer = useCallback<Actions['toggleContainer']>(
    (container, widgets) => {
      const isExpanded = activeWidgets[container]

      setActiveWidgets(
        produce(activeWidgets, (draft) => {
          if (isExpanded) {
            delete draft[container]
          } else {
            draft[container] = widgets.map((widget) => widget.id)
          }
        })
      )
    },
    [activeWidgets]
  )

  const isWidgetExpanded = useCallback<Actions['isWidgetExpanded']>(
    (container, widget) => {
      return activeWidgets[container] && activeWidgets[container].includes(widget)
    },
    [activeWidgets]
  )

  const toggleWidget = useCallback<Actions['toggleWidget']>(
    (container, widget) => {
      const isExpanded = isWidgetExpanded(container, widget)

      setActiveWidgets(
        produce(activeWidgets, (draft) => {
          if (isExpanded) {
            const widgetIndex = activeWidgets[container].indexOf(widget)
            draft[container].splice(widgetIndex, 1)
          } else {
            const prevWidgets = activeWidgets[container] || []
            draft[container] = [...prevWidgets, widget]
          }
        })
      )
    },
    [activeWidgets, isWidgetExpanded]
  )

  const addWidget = useCallback<Actions['addWidget']>(
    async (container, widgetType, type) => {
      const typeId = widgetType.id

      const { singleEntityTypeRes, attributesRes } = await EntityService.getAttributes(
        'widget_types',
        typeId
      )

      const attributes = EntityService.generateAttributes(
        attributesRes,
        singleEntityTypeRes,
        'widgetTypeAttributes'
      )

      const initial = new DefaultValuesGenerator(
        // @ts-ignore
        attributes,
        localizations
      ).getValues()

      // @ts-ignore
      const generatedValues = new ValuesGenerator(attributes, initial, 'entitySetRepeats').getData()

      const sortOrder = type === 'push' ? widgets.length : 0

      const newWidget = new EntityWidget(
        container,
        entity?.layout || '',
        widgetType,
        sortOrder,
        generatedValues
      )

      setWidgets(
        produce(widgets, (draft) => {
          // setCurrentWidgets(draft)

          draft[type](newWidget)

          if (type === 'unshift') {
            draft.forEach((widget, index) => {
              widget.sortOrder = index
            })
          }
        })
      )

      toggleWidget(container, newWidget.id)
    },
    [entity?.layout, localizations, toggleWidget, widgets]
  )

  const removeWidget = useCallback<Actions['removeWidget']>(
    (widgetId) => {
      delete widgetsDataRef.current[widgetId]

      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          draft.splice(widgetIndex, 1)
          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })
        })
      )
      //** Clear this widget error */
      // setErroredWidget(erroredWidget.filter((id: number) => id !== widgetId))
    },
    [widgets]
  )

  const changeWidgetWidth = useCallback<Actions['changeWidgetWidth']>(
    (optionKey, widgetId, width) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          draft[widgetIndex].options[optionKey] = width
        })
      )
    },
    [widgets]
  )

  const moveWidget = useCallback<Actions['moveWidget']>(
    (source, destination, droppableSource, droppableDestination) => {
      setWidgets((prevWidgets) => {
        const cloneWidgets = cloneDeep(prevWidgets)
        const convertedData: IEntityWidget[] = []
        const containers: { [containerId: string]: IEntityWidget[] } = {}

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`] = []
        })

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`].push(widget)
        })

        const sourceClone = cloneDeep(source)
        const destClone = cloneDeep(destination)
        const [removed] = sourceClone.splice(droppableSource.index, 1)

        removed.options.container = droppableDestination.droppableId
        destClone.splice(droppableDestination.index, 0, removed)

        containers[droppableSource.droppableId] = sourceClone
        containers[droppableDestination.droppableId] = destClone

        Object.keys(containers).forEach((container) => {
          convertedData.push(...containers[container])
        })

        convertedData.forEach((widget, index) => {
          widget.sortOrder = index
        })

        const containerId = droppableSource.droppableId
        const widgetId = removed.id
        const isExpanded = isWidgetExpanded(containerId, widgetId)

        if (isExpanded) {
          setActiveWidgets(
            produce(activeWidgets, (draft) => {
              const removedIndex = draft[containerId].indexOf(widgetId)
              draft[containerId].splice(removedIndex, 1)
            })
          )
        }

        return convertedData
      })
    },
    [activeWidgets, isWidgetExpanded]
  )

  const reorderWidgets = useCallback<Actions['reorderWidgets']>(
    (containerId, startIndex, endIndex) => {
      setWidgets((prevWidgets) => {
        // const cloneWidgets = cloneDeep(prevWidgets)
        // const firstInContainer = cloneWidgets.find(
        //   (widget) => widget.options.container === containerId
        // )
        // if (!firstInContainer) return cloneWidgets
        // const realIndex = cloneWidgets.indexOf(firstInContainer)
        // const realStart = startIndex + realIndex
        // const realEnd = endIndex + realIndex
        //
        // const movedWidget = cloneWidgets[realStart]
        // cloneWidgets.splice(realStart, 1)
        // cloneWidgets.splice(realEnd, 0, movedWidget)
        //
        // cloneWidgets.forEach((widget, index) => {
        //   cloneWidgets[index].sortOrder = index
        // })
        //
        // return cloneWidgets

        /**
         * Fix after refactor */
        const cloneWidgets = cloneDeep(prevWidgets)
        const containers: { [containerId: string]: IEntityWidget[] } = {}
        const reorderedData: IEntityWidget[] = []

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`] = []
        })

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`].push(widget)
        })

        const widgetsInContainer = cloneWidgets.filter(
          (widget) => widget.options.container === containerId
        )

        const [movedWidget] = widgetsInContainer.splice(startIndex, 1)
        widgetsInContainer.splice(endIndex, 0, movedWidget)

        containers[containerId] = widgetsInContainer

        Object.keys(containers).forEach((container) => {
          reorderedData.push(...containers[container])
        })

        reorderedData.forEach((widget, index) => {
          widget.sortOrder = index
        })
        return reorderedData
      })
    },
    []
  )

  const moveWidgetUp = useCallback<Actions['moveWidgetUp']>(
    (widgetId) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          if (widgetIndex - 1 < 0) return
          const temp = draft[widgetIndex]
          draft[widgetIndex] = draft[widgetIndex - 1]
          draft[widgetIndex - 1] = temp

          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })

          document.dispatchEvent(dragEndEvent)
        })
      )
    },
    [widgets]
  )

  const moveWidgetDown = useCallback<Actions['moveWidgetDown']>(
    (widgetId) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          if (widgetIndex + 1 >= draft.length) return
          const temp = draft[widgetIndex]
          draft[widgetIndex] = draft[widgetIndex + 1]
          draft[widgetIndex + 1] = temp

          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })

          document.dispatchEvent(dragEndEvent)
        })
      )
    },
    [widgets]
  )

  const setGlobalWidget = useCallback<Actions['setGlobalWidget']>(
    (widgetId, globalWidget) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)

          if (widgetIndex >= 0) {
            draft[widgetIndex].widget = globalWidget
            draft[widgetIndex].values = []
            draft[widgetIndex].entitySetRepeats = []
          }
        })
      )
    },
    [widgets]
  )

  const actions: Actions = {
    addWidget,
    changeWidgetWidth,
    reorderWidgets,
    moveWidget,
    moveWidgetUp,
    moveWidgetDown,
    removeWidget,
    toggleContainer,
    isWidgetExpanded,
    toggleWidget,
    setWidgetRefData,
    setGlobalWidget,
    getWidgetRefData,
  }

  return {
    widgets,
    activeWidgets,
    widgetsDataRef,
    actions,
  }
}
