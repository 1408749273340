// Core
import React, { FC, memo } from 'react'
import { useAppContext, Permissions } from 'core/app'
import { useNavigationContext } from 'core/navigation'
import { useIsFetching } from 'react-query'
// Material Components
import {
  Box,
  MenuList,
  Divider,
  Collapse,
  MenuItem as MaterialMenuItem,
  IconButton,
  Icon,
  LinearProgress,
} from '@material-ui/core'
import clsx from 'clsx'
// Components
import { MenuItem } from '../menu-item'
import { useTranslation } from 'react-i18next'
// Types
import { NavigationItem } from 'core/navigation/types/navigation-item'
// Styles
import useStyles from './main-menu.styles'

const MainMenu: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isFavoriteFetching = useIsFetching('nav-favourite')
  const siteSettingsFetching = useIsFetching('sites-global')
  const favoriteFetching = isFavoriteFetching || siteSettingsFetching

  const {
    actions: { userCan },
  } = useAppContext()

  const { navigationList, navFavoriteList, isOpenCollapse, actions, isOpen } =
    useNavigationContext()

  return (
    <Box component="div">
      <MenuList className={classes.root}>
        {Boolean(navFavoriteList.length) && (
          <MaterialMenuItem
            className={clsx(classes.menuItem, classes.manuItemFav)}
            onClick={actions.openHandlerCollapse}
          >
            <IconButton>
              <Icon className={clsx('icon-favorite-default', classes.menuItemIcon)} />
            </IconButton>
            <span
              className={clsx(classes.menuItemText, {
                [classes.menuItemTextOpen]: isOpen,
              })}
            >
              {t('navigation.favorite')}
            </span>
            <Icon
              className={clsx(classes.menuItemIcon, classes.menuIconArrow, {
                'icon-outline-keyboard_arrow_up-24px-default': isOpenCollapse,
                'icon-outline-keyboard_arrow_down-24px-default': !isOpenCollapse,
              })}
            />
          </MaterialMenuItem>
        )}
        <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit className={classes.favorites}>
          {favoriteFetching ? (
            <LinearProgress />
          ) : (
            navFavoriteList &&
            navFavoriteList.map((favoriteItem: NavigationItem, index) => {
              return <MenuItem key={`${favoriteItem.slug}-${index}`} itemData={favoriteItem} />
            })
          )}
        </Collapse>
        {Boolean(navFavoriteList.length) && <Divider />}
        {navigationList.map((navItem: NavigationItem, index) => {
          if (
            navItem.slug === 'entities' ||
            navItem.slug === 'analytics' ||
            navItem.slug === 'sitemap-settings' ||
            navItem.slug === 'redirects' ||
            navItem.slug === 'dashboard' ||
            navItem.slug === 'translations' ||
            navItem.slug === 'templates'
          ) {
            return (
              <MenuItem
                exact={navItem.slug !== 'entities'}
                key={`${navItem.slug}-${index}`}
                itemData={navItem}
              />
            )
          }

          return (
            (userCan(navItem.slug, Permissions.VIEW) ||
              userCan(navItem.slug, Permissions.VIEW, 'system') ||
              userCan(navItem.slug, Permissions.VIEW, 'widgetType')) && (
              <MenuItem key={`${navItem.slug}-${index}`} itemData={navItem} />
            )
          )
        })}
      </MenuList>
    </Box>
  )
}

export default memo(MainMenu)
