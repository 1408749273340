// Core
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
// Hooks
import { useListSelected } from '../use-list-selected'
import { useViewType } from '../use-view-type'
import { useListParams } from '../use-list-params'
// Services
import { httpService } from 'core/data'
// Types
import {
  ApiResponseCollection,
  ResourceProps,
  ListControllerProps,
  ControllerOptions,
} from '../../types'
// Utils
import { filterParams, transformParams } from 'core/data'
import { toast } from 'react-toastify'

export const useListController = (
  resource: ResourceProps,
  options?: ControllerOptions
): ListControllerProps => {
  const defaultOptions = useMemo(
    () => ({
      defaultViewType: 'table',
      filters: {},
      removeFilter: '',
      filterChangeUrl: true,
      ...options,
    }),
    [options]
  )
  const [reqEnabled, setReqEnabled] = useState(false)
  const selected = useListSelected()
  const viewType = useViewType(defaultOptions.defaultViewType)
  const listParams = useListParams(
    defaultOptions.filters,
    setReqEnabled,
    defaultOptions.filterChangeUrl
  )

  const query = useQuery<unknown, unknown, ApiResponseCollection>(
    [resource.name, listParams.params],
    () => {
      const filteredParams = filterParams(listParams, defaultOptions.removeFilter)
      const reqParams = transformParams(filteredParams)

      return httpService
        .get<ApiResponseCollection>(`/${resource.name}`, { params: reqParams })
        .then((res) => res.data)
    },
    {
      onSuccess: (data) => {
        listParams.methods.setMeta({
          total: data['hydra:totalItems'],
        })
      },
      enabled: reqEnabled,
      retry: false,
      onError(e: any) {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )

  return {
    resource,
    viewType,
    listParams,
    ...selected,
    data: query.data?.['hydra:member'] as any,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
  }
}
