// Core
import React, { FC, memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
// Hooks
import {
  useEntityController,
  useEntityMutation,
  useUnsavedEntity,
  useWidgetsController,
  useInsertTemplate,
  useSimultaneouslyEdit,
} from '../../hooks'
import { useSetEntityBreadCrumbs } from 'core/breadcrumbs'
import useBackupDataOnVersionConflict from 'core/check-version/use-backup-data-on-version-conflict'
// Components
import AttributesForm from '../attributes-form'
import { Collapse } from 'ui'
import { FormHolder } from '../form-holder'
import { FormSkeleton } from '../form-skeleton'
import PageBuilder from '../page-builder'
import { PageBuilderContext } from '../../context'
import FormActions from '../form-actions'
import InsertTemplate from '../insert-template'
// Types
import { EavResourceType, MutationType } from 'modules/new-entity/types'

type TProps = {
  mutationType: MutationType
  resourceType: EavResourceType
}

const EntityMutationRoute: FC<TProps> = memo(({ mutationType, resourceType }) => {
  const { id, typeId } = useParams<any>()
  const { t } = useTranslation()

  /**
   * Controllers for widgets and entity
   * */
  const {
    entity,
    isLoading,
    attributes,
    mainAttributes,
    initialValues,
    asideAttributes,
    validationSchema,
    entityType,
    formRef,
    mutation,
    setEntity,
    isError,
  } = useEntityController(id, typeId, resourceType)

  const isUrlable = entityType?.urlable
  const isEdit = mutationType === MutationType.EDIT

  const widgetControllerProps = useWidgetsController(entity!)

  /**
   * Unsaved changes for entity
   * */
  const {
    prompt,
    methods: { savedChanges, checkUnsavedChanges },
  } = useUnsavedEntity(formRef, widgetControllerProps, entity?.entityWidgets)

  const { saveEntity, saveAsTemplate, generateEntityValues, invalidateForm } = useEntityMutation({
    attributes,
    formRef,
    mutation,
    entityType,
    entity: entity!,
    widgets: widgetControllerProps.widgets,
    widgetsDataRefs: widgetControllerProps.widgetsDataRef,
    resourceType,
    isEdit: Boolean(id),
    savedChanges,
    id,
    hasVersions: entityType?.urlable,
  })

  /**
   * Simultaneously Edit
   */
  const { lockScreen, askControlScreen } = useSimultaneouslyEdit(
    id,
    entityType,
    isEdit,
    entity?.template,
    saveEntity,
    mutation.isLoading,
    invalidateForm
  )

  /**
   * Backup/Restore current entity data in version conflict case
   */
  const setBackupValues = useCallback(
    (newValues) => {
      setEntity((currentValues) => ({
        ...currentValues,
        ...newValues,
      }))
    },
    [setEntity]
  )
  useBackupDataOnVersionConflict({
    data: generateEntityValues,
    enabled: !isLoading && !isError,
    onRestore: setBackupValues,
  })

  /**
   * Insertion template functionality
   * */
  const { insertTemplate, templatesModal, toggleTemplatesModal } = useInsertTemplate(setEntity)

  /**
   * Set Breadcrumbs
   * */
  useSetEntityBreadCrumbs(mutationType, entity?.name, entityType, resourceType)

  /**
   * Highlight attributes from query params
   */
  useEffect(() => {
    if (isLoading) return

    const query = new URLSearchParams(window.location.search)
    if (!query.has('attrs')) return
    setTimeout(() => {
      const params = query.get('attrs')?.split(',') || []
      params.forEach((slug, index) => {
        const el = document.querySelectorAll(`[data-aqa-attribute-slug="${slug}"]`)
        if (!el[0]) return
        el[0].classList.add('highlight')
        if (index === 0) {
          const elYPos = el[0].getBoundingClientRect().top
          window.scrollTo({ top: elYPos, behavior: 'smooth' })
        }
      })
    }, 0)
  }, [isLoading])

  if (isError) {
    return <div>Something went wrong</div>
  }

  return (
    <FormHolder>
      {(asideRef) => (
        <>
          <Collapse isOpen={true} label={t('list.settings')}>
            {isLoading ? (
              <FormSkeleton />
            ) : (
              <AttributesForm
                asideRef={asideRef}
                type={mutationType}
                mainAttributes={mainAttributes}
                asideAttributes={asideAttributes}
                initialValues={initialValues}
                validationSchema={validationSchema}
                resourceType={resourceType}
                typeOptions={entityType?.options}
                formRef={formRef}
                asideHolder={
                  <FormActions
                    originalId={id}
                    currentId={entity?.id}
                    isUrlable={isUrlable}
                    isEdit={isEdit}
                    status={entity.status}
                    isTemplate={resourceType === EavResourceType.TEMPLATE}
                    isLoading={mutation.isLoading}
                    saveEntityAction={saveEntity}
                    entityType={entityType}
                    checkUnsavedChanges={checkUnsavedChanges}
                    setSavedChanges={savedChanges}
                    insertTemplateAction={toggleTemplatesModal}
                    saveAsTemplateAction={saveAsTemplate}
                  />
                }
                entity={entity}
              />
            )}
          </Collapse>
          <Collapse isOpen={true} label={t('Page builder')}>
            {!isLoading && (
              <PageBuilderContext.Provider value={widgetControllerProps}>
                <PageBuilder layout={entity?.layout!} />
              </PageBuilderContext.Provider>
            )}
          </Collapse>
          {lockScreen}
          {askControlScreen}
          {prompt}
          <InsertTemplate
            insertTemplate={insertTemplate}
            typeId={+entityType?.id}
            isOpen={templatesModal}
            onModalClose={() => toggleTemplatesModal(false)}
          />
        </>
      )}
    </FormHolder>
  )
})

export default EntityMutationRoute
