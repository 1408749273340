import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { Layout } from 'core/types/layouts/entity-layouts/layout'
import { getIdFromIri } from 'core/utils'

type ReturnProps = {
  layout: Layout
  isLoading: boolean
}

type UseGetLayout = (layoutIri: string) => ReturnProps

export const useGetLayout: UseGetLayout = (layoutIri) => {
  const id = getIdFromIri(layoutIri)

  const { data: layout, isLoading } = useQuery(
    ['layouts', id],
    async () => {
      const res = await httpService.get<any>(`/layouts/${id}`)

      return res.data
    },
    {
      enabled: Boolean(layoutIri),
    }
  )

  return {
    layout,
    isLoading,
  }
}
