// Core
import React, { FC, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
// Hooks
import { useListContext } from 'core/data'
// UI
import SubHeader from 'ui/sub-header'
import { Permissions, useAppContext } from 'core/app'
// Types
import { EavResourceType } from 'modules/new-entity/types'

const Header: FC = () => {
  const {
    resource: { name, hasCreate, resourceType },
  } = useListContext()
  const history = useHistory()
  const { typeId } = useParams()
  const {
    actions: { getResource, userCan },
  } = useAppContext()

  const slug = getResource(typeId)?.slug

  const canCreate = useMemo(() => {
    if (slug && name === 'entities') return userCan(slug, Permissions.CREATE)
    // TODO remove when change name
    if (name === 'navigations') return userCan('navigation', Permissions.CREATE)
    return (
      userCan(name, Permissions.CREATE, 'system') || userCan(name, Permissions.CREATE, 'widgetType')
    )
  }, [name, slug, userCan])

  const createHandler = useCallback(() => {
    const path = () => {
      if (
        resourceType === EavResourceType.ENTITY ||
        resourceType === EavResourceType.WIDGET ||
        resourceType === EavResourceType.TEMPLATE
      ) {
        return `/${resourceType}/${typeId}/create`
      }
      return `/${name}/create`
    }

    history.push(path())
  }, [history, name, resourceType, typeId])

  return (
    <SubHeader
      resourceType={resourceType}
      showBreadcrumbs={false}
      resource={name}
      buttonName={name}
      hasButton={hasCreate}
      buttonClick={createHandler}
      canCreate={canCreate}
    />
  )
}

export default Header
