import { Navigation } from '../types/navigation-item'

const noSlash = (t: string) => t.replace(/^\/|\/$/g, '')

export const navList: (t: any) => Navigation = (t) => {
  const list = [
    {
      link_to: '/',
      icon: 'icon-dashboard',
      slug: 'dashboard',
      show_in_dashboard: false,
    },
    {
      link_to: '/entity_types',
      icon: 'icon-package',
      slug: 'entity_types',
      show_in_dashboard: true,
    },
    {
      link_to: '/entities',
      icon: 'icon-layers',
      slug: 'entities',
      show_in_dashboard: false,
    },
    {
      link_to: '/widget_types',
      icon: 'icon-widget',
      slug: 'widget_types',
      show_in_dashboard: true,
    },
    {
      link_to: '/widgets',
      icon: 'icon-layers',
      slug: 'widgets',
      show_in_dashboard: false,
    },
    {
      link_to: '/templates',
      icon: 'icon-file',
      slug: 'templates',
      show_in_dashboard: false,
    },
    {
      link_to: '/sites',
      icon: 'icon-monitor',
      slug: 'sites',
      show_in_dashboard: true,
    },
    {
      link_to: '/navigations',
      icon: 'icon-navigation',
      slug: 'navigation',
      show_in_dashboard: false,
    },
    {
      link_to: '/users',
      icon: 'icon-users',
      slug: 'users',
      show_in_dashboard: true,
    },
    {
      link_to: '/media',
      icon: 'icon-image',
      slug: 'media',
      show_in_dashboard: true,
    },
    {
      link_to: '/media_sizes',
      icon: 'icon-crop',
      slug: 'media_sizes',
      show_in_dashboard: true,
    },
    {
      link_to: '/layouts',
      icon: 'icon-layout',
      slug: 'layouts',
      show_in_dashboard: true,
    },
    {
      link_to: '/core-web-vitals',
      icon: 'icon-activity',
      slug: 'analytics',
      show_in_dashboard: false,
    },
    {
      link_to: '/sitemap-settings',
      icon: 'icon-preview',
      slug: 'sitemap-settings',
      show_in_dashboard: false,
    },
    {
      link_to: '/redirects',
      icon: 'icon-shuffle',
      slug: 'redirects',
      show_in_dashboard: false,
    },
    {
      link_to: '/translations',
      icon: 'icon-globe',
      slug: 'translations',
      show_in_dashboard: false,
    },
  ]

  return list.map((menuItem) => {
    return {
      ...menuItem,
      get title() {
        return t(`menu.${noSlash(this.link_to)}`)
      },
    }
  })
}
