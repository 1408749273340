// Core
import React, { FC, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getIn, useField, useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { SmartSelectValue } from 'ui'
import SmartSelectField from '../smart-select-field'
import { getIdFromIri } from 'core/utils'
import { httpService } from 'core/data'
import { AttributeData } from 'modules/new-entity/types'
// Styles
import useStyle from './ref-to-entity-type.style'

type Props = {
  name: string
  children?: never
  data: AttributeData
  defaultValues?: any
  disabled?: boolean
}

const RefToEntityType: FC<Props> = (props) => {
  const {
    name,
    data: { entityTypes },
    data,
    defaultValues: { value },
    disabled,
  } = props
  const classes = useStyle()

  const { errors } = useFormikContext<any>()
  const [field] = useField(name)

  const enadled = useRef(Boolean(field.value))
  const [entityType, setEntityType] = useState<SmartSelectValue>(value)
  const entityId = getIdFromIri(field.value)

  const { isLoading } = useQuery(
    ['entities', entityId],
    async () => {
      const response = await httpService.get<any>(`/entities/${entityId}`)
      return response.data.entityType
    },
    {
      enabled: enadled.current,
      cacheTime: 0,
      onSuccess: (res) => {
        setEntityType(res)
        enadled.current = false
      },
    }
  )

  if (isLoading) return <Skeleton variant="rect" height="57px" />

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <SmartSelectField
          name={name}
          valueField={'@id'}
          label={data.name}
          value={entityType}
          source={'entity_types'}
          reqParams={{ id: entityTypes }}
          error={getIn(errors, name) ? getIn(errors, name) : undefined}
          disabled={disabled}
        />
      </Box>
    </Box>
  )
}

export default RefToEntityType
