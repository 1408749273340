import React, { FC } from 'react'
import clsx from 'clsx'
// Material Components
import { Drawer as MaterialDrawer, IconButton, Icon } from '@material-ui/core'
// Styles
import drawerStyles from './drawer.styles'
// Components
import { MainMenu } from './components'
import { useNavigationContext } from 'core/navigation'

type Props = {}

const Drawer: FC<Props> = (props) => {
  const classes = drawerStyles()
  const {
    isOpen,
    actions: { openHandler },
  } = useNavigationContext()

  return (
    <MaterialDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
    >
      <div
        className={clsx(classes.actionIcons, {
          [classes.actionIconsOpen]: isOpen,
        })}
      >
        <IconButton aria-label="open drawer" onClick={openHandler} className={classes.icons}>
          <Icon
            className={clsx({
              'icon-menu-open': !isOpen,
              'icon-menu-close': isOpen,
            })}
          />
        </IconButton>
      </div>
      <MainMenu />
      <div className={classes.grow} />
    </MaterialDrawer>
  )
}

export default Drawer
