// Core
import React, { FC } from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useDelayValueChange } from 'core/hooks'

/** style */
import useStyle from './submit-button.styles'

type TProps = {
  title?: string
} & ButtonProps

const SubmitButton: FC<TProps> = (props) => {
  const { title = '', ...rest } = props
  const classes = useStyle()
  const { isSubmitting } = useFormikContext()
  const [isLoading] = useDelayValueChange(isSubmitting)

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? (
        <>
          <span className={classes.title}>{title}</span>
          <CircularProgress className={classes.spiner} size={22} />
        </>
      ) : (
        <span>{title}</span>
      )}
    </Button>
  )
}

export default SubmitButton
