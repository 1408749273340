import React, { FC } from 'react'
import clsx from 'clsx'
// Material Components
import { AppBar as MaterialAppBar, Toolbar } from '@material-ui/core'
// Components
import { Logo, Menu } from './components'
import { SiteSwitcher } from 'modules/sites'
// Styles
import appBarStyles from './app-bar.styles'

type TProps = {
  isOpen: boolean
}

const AppBar: FC<TProps> = (props) => {
  const classes = appBarStyles()
  const { isOpen } = props

  return (
    <>
      <MaterialAppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar>
          <Logo />
          <SiteSwitcher />
          <Menu />
        </Toolbar>
      </MaterialAppBar>
    </>
  )
}

export default AppBar
