import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    link: {
      textDecoration: 'none',
    },
    active: {
      '&>li': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    text: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    button: {
      padding: theme.spacing(2, 1, 2, 3),
    },
    menuItem: {
      padding: theme.spacing(0.5),
    },
    menuItemText: {
      opacity: 0,
      color: colors.blueGrey['600'],
      fontSize: '0.88rem',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: '10px',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuItemTextOpen: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuItemIcon: {
      color: colors.blueGrey['600'],
    },
  })
)

export default useStyles
