import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const breadcrumbsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& li': {
        whiteSpace: 'nowrap',
        maxWidth: '27vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1,
      },
      '& p': {
        display: 'inline',
        lineHeight: 1,
      },
    },
  })
)

export default breadcrumbsStyles
