// Core
import React, { FC, useCallback } from 'react'
import { Form, Formik } from 'formik'
import { Button, Divider, Paper, Typography } from '@material-ui/core'
import { useQuery } from 'react-query'
// Components
import { EditFile, FileDetails } from 'modules/media/components/file-form-modal/components'
import { httpService, LanguageProvider } from 'core/data'
import MediaError from 'modules/media/components/file-form-modal/components/media-error/media-error'
import FormSkeletonVertival from 'modules/media/components/file-form-modal/components/modal-skeleton-vertical/form-skeleton-vertical'
// Hooks
import { useNotify } from 'core/hooks'
import { useMediaManagerModalClose } from 'modules/media/hooks'
import { useDelete, useGetOne, useListContext, useUpdate } from 'core/data'
// Utils
import { transformLocalizations } from 'core/data'
// Styles
import useStyles from './selected-file-view.styles'
// types
import { IFile } from 'modules/media/types'

export const SelectedFileView: FC = () => {
  const classes = useStyles()
  const { selected } = useListContext()
  const managerModal = useMediaManagerModalClose()
  // @ts-ignore
  const { data } = useGetOne('media', selected)

  const {
    data: mediaData,
    isFetching,
    isError,
  } = useQuery(
    `media-${selected}`,
    () => {
      return httpService.get<IFile>(`/media/${selected}`).then(({ data }) => data)
    },
    {
      cacheTime: 0,
      retry: false,
    }
  )

  const notify = useNotify()

  const onSuccessUpdate = useCallback(() => {
    notify(`${data?.name} item was updated`, {
      type: 'success',
    })
  }, [data?.name, notify])

  const { mutate: update } = useUpdate('media')

  const { mutate: remove } = useDelete('media')

  const selectFileHandler = useCallback(() => {
    managerModal(false, data)
  }, [data, managerModal])

  const removeHandler = useCallback(() => {
    // @ts-ignore
    remove(data?.id || '', {
      onSuccess: () => {
        notify(`${data?.name} item was deleted`, {
          type: 'success',
        })
      },
    })
  }, [notify, remove, data?.id, data?.name])

  const submitHandler = useCallback(
    (values: any) => {
      update(
        {
          id: data?.id || '',
          values,
        },
        {
          onSuccess: onSuccessUpdate,
        }
      )
    },
    [onSuccessUpdate, data?.id, update]
  )

  if (!data) return null

  return isError ? (
    <MediaError />
  ) : isFetching && !mediaData ? (
    <FormSkeletonVertival />
  ) : (
    <LanguageProvider notSystem>
      <Formik
        initialValues={transformLocalizations(mediaData) || {}}
        onSubmit={submitHandler}
        enableReinitialize
      >
        {() => (
          <Form>
            <Paper>
              <div className={classes.selectedTitle}>
                <Typography variant="h6">Selected file</Typography>
                <Button onClick={selectFileHandler} color="primary" variant="contained">
                  Select
                </Button>
              </div>
              <Divider />
              <div className={classes.fileDetails}>
                <img
                  style={{ maxWidth: '100%', height: 'auto', marginTop: 24 }}
                  src={mediaData?.thumbnailUrls?.preview}
                  alt=""
                />
                <FileDetails data={mediaData} />
              </div>
              <EditFile removeHandler={removeHandler} />
            </Paper>
          </Form>
        )}
      </Formik>
    </LanguageProvider>
  )
}
