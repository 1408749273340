import React, { FC, ReactNode } from 'react'
import { useNavigationContext } from 'core/navigation'
// Components
import { Drawer, AppBar } from '../../components'
import { CssBaseline } from '@material-ui/core'
// Styles
import { SubHeader } from 'ui'
// Styles
import mainLayoutStyles from './main-layout.styles'

type TProps = {
  children?: ReactNode[]
}

const MainLayout: FC<TProps> = (props) => {
  const { children } = props
  const classes = mainLayoutStyles()
  const { isOpen } = useNavigationContext()

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar isOpen={isOpen} />
      <Drawer />

      <main className={classes.grow}>
        <SubHeader />
        {children}
      </main>
    </div>
  )
}

export default MainLayout
