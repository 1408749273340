// Core
import React, { FC } from 'react'
import {
  TableContainer,
  Table as TableMui,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'
// Hooks
import { useDataViewContext } from './data-view-context'
// Components
import ActionsColumn from './actions-column'
import Column from './column'

type Props = {
  children?: never
}

const Table: FC<Props> = () => {
  const { columnsData, rowsData } = useDataViewContext()

  return (
    <TableContainer style={{ maxHeight: 'calc(100vh - 241px)' }}>
      <TableMui stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Actions</TableCell>
            <TableCell align="left">Title</TableCell>
            {columnsData.map((column) => (
              <TableCell align="left" key={column.attrIri}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row) => (
            <TableRow hover key={row['@id']}>
              <ActionsColumn rowData={row} />
              <TableCell>{row.name}</TableCell>
              {columnsData.map((column) => (
                <Column key={column.attrIri} rowData={row} columnData={column} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableMui>
    </TableContainer>
  )
}

export default Table
