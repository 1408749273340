import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { httpService, useListContext } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useTranslation } from 'react-i18next'

type UseDeleteOne = (resource: string) => UseMutationResult<unknown, unknown, number>

export const useDelete: UseDeleteOne = (resource) => {
  const props = useListContext()
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()
  const queryClient = useQueryClient()

  return useMutation(
    (id: any) => {
      return httpService.delete(`/${resource}/${id}`)
    },
    {
      onError: (error: any, variables) => {
        if (error.response.status === 409) {
          setConfirmation({
            open: true,
            title: t('global.delete'),
            content: t('notify.delete'),
            onSuccess: () => {
              httpService.delete(`/${resource}/${variables}?force=true`).then(() => {
                queryClient.invalidateQueries(resource)
              })
            },
          })
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(resource)
        const {
          data,
          listParams: {
            params: {
              pagination: { page },
            },
            methods,
          },
        } = props

        if (data?.length === 1) {
          methods.setPage(page - 1 || 1)
        }
      },
    }
  )
}
