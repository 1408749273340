// Core
import React, { FC, useCallback, useEffect } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
// Translation
import { useTranslation } from 'react-i18next'
// Material Components
import { Typography, Box } from '@material-ui/core'
import { useAppContext } from '../../app'
import { SubmitButton, TextField } from 'core/form'
import { ACTIVE_SITE_STORAGE_KEY } from 'modules/sites'
// Styles
import useStyles from './login.styles'
// Images
import loginLogo from 'assets/img/login-logo.svg'
import bgImage from 'assets/img/login.svg'

type TProps = {}

type TFormProps = {
  email: string
  password: string
  credentials?: string
}

const ValidationSchema = Yup.object<TFormProps>({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(3),
})

const Login: FC<TProps> = () => {
  const initialValues = {
    email: '',
    password: '',
  }

  const classes = useStyles()
  const { t } = useTranslation()
  const {
    actions: { login },
  } = useAppContext()

  useEffect(() => {
    /** Delete current site ID
     * for getting new after login */
    localStorage.removeItem(ACTIVE_SITE_STORAGE_KEY)
  }, [])

  const submitHandler = useCallback(
    async (values: TFormProps, formikHelpers: FormikHelpers<TFormProps>) => {
      // login(values, formikHelpers)
      try {
        await login(values)
      } catch (e) {
        formikHelpers.setErrors({
          // @ts-ignore
          credentials: e.response.data.message,
        })
      }
    },
    [login]
  )

  return (
    <div className={classes.loginContainer}>
      <Box className={classes.loginBackground}>
        <img src={bgImage} alt="login-background" />
        <Typography variant="h5" className={classes.loginBgText}>
          VULCAN CMS
          <br />
          Early Access
        </Typography>
      </Box>
      <Box className={classes.loginForm}>
        <div className={classes.logo}>
          <img src={loginLogo} alt="login-logo" />
        </div>
        <Typography component="h1" variant="h5" className={classes.loginTitle}>
          {t('login.signin-title')}
        </Typography>
        <Formik
          onSubmit={submitHandler}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          enableReinitialize
        >
          {({ errors }) => (
            <Form className={classes.form}>
              <TextField
                name="email"
                placeholder={t('login.email-field')}
                label={t('login.email-field')}
                className={classes.formField}
              />
              <TextField
                name="password"
                type="password"
                placeholder={t('login.pass-field')}
                label={t('login.pass-field')}
                className={classes.formField}
              />
              {errors.credentials ? (
                <div className={classes.error}>{errors.credentials}</div>
              ) : null}
              <Box>
                <SubmitButton className={classes.submit} title={t('global.sign-in')} />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  )
}

export default Login
