import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '&>div': {
        width: '100%',
      },
    },
    helperText: {
      color: 'red',
    },
  })
)

export default styles
