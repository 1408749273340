import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 300,
      overflowY: 'auto',
    },
  })
)

export default styles
