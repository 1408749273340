// Core
import { useEffect, useMemo } from 'react'
import { useBreadcrumbsContext } from './use-breadcrumbs-context'
import { useTranslation } from 'react-i18next'
// Types
import { BreadcrumbData } from '../types'

export type TTypeBread = 'list' | 'create' | 'edit'

type UseSetEntityBreadcrumbs = (
  type: TTypeBread,
  name?: string,
  entityType?: any,
  selfType?: string
) => void

export const useSetEntityBreadCrumbs: UseSetEntityBreadcrumbs = (
  type,
  name = '',
  entityType = undefined,
  selfType
) => {
  const { t } = useTranslation()
  const { setLoading, setBreadcrumbsData } = useBreadcrumbsContext()

  useEffect(() => {
    setLoading(true)
    return () => {
      setLoading(false)
    }
  }, [setLoading])

  const breadcrumbs = useMemo(() => {
    const path = selfType
    const breadcrumbsData: BreadcrumbData[] = [
      { title: t('global.dashboard'), to: '/' },
      { title: t(`list.${path}`), to: `/${path}` },
    ]
    switch (type) {
      case 'create':
        if (!entityType?.single) {
          breadcrumbsData.push({ title: entityType?.name, to: `/${path}/${entityType?.id}` })
        }
        breadcrumbsData.push({ title: t('global.create') })
        break
      case 'edit':
        if (!entityType?.single) {
          breadcrumbsData.push({ title: entityType?.name, to: `/${path}/${entityType?.id}` })
        }
        breadcrumbsData.push({ title: `${t('global.edit')} - ${name}` })
        break
      case 'list':
        breadcrumbsData.push({ title: entityType?.name })
        break
    }

    return breadcrumbsData
  }, [entityType?.id, entityType?.name, entityType?.single, name, selfType, t, type])

  useEffect(() => {
    if (!entityType) return
    setBreadcrumbsData(breadcrumbs)
    setLoading(false)
  }, [breadcrumbs, entityType, setBreadcrumbsData, setLoading])
}
