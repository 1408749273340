// Core
import React, { FC, useCallback, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { getIn, useField, useFormikContext } from 'formik'
import { useQuery } from 'react-query'
import { SmartSelect, SmartSelectValue } from 'ui'
import SmartSelectField from '../smart-select-field'
import { getIdFromIri } from 'core/utils'
import { httpService } from 'core/data'
import { AttributeData } from 'modules/new-entity/types'
import { useTranslation } from 'react-i18next'
// Styles
import useStyle from './multiple-reference-field.style'

type Props = {
  name: string
  children?: never
  data: AttributeData
  defaultValues?: any
  disabled?: boolean
}

const MultipleReferenceField: FC<Props> = (props) => {
  const {
    name,
    data: { entityTypes },
    data,
    defaultValues: { value },
    disabled,
  } = props
  const classes = useStyle()
  const { t } = useTranslation()

  const { errors } = useFormikContext<any>()
  const [field, , { setValue }] = useField(name)
  const enadled = useRef(Boolean(field.value))
  const [entityType, setEntityType] = useState<SmartSelectValue>(value)
  const entityId = getIdFromIri(field.value)

  const { isLoading } = useQuery(
    ['entities', entityId],
    async () => {
      const response = await httpService.get<any>(`/entities/${entityId}`)
      return response.data.entityType
    },
    {
      enabled: enadled.current,
      cacheTime: 0,
      onSuccess: (res) => {
        setEntityType(res)
        enadled.current = false
      },
    }
  )

  const changeHandler = useCallback(
    (value: SmartSelectValue) => {
      setEntityType(value)
      setValue('')
    },
    [setValue]
  )

  if (isLoading) return <Skeleton variant="rect" height="57px" />

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <div>
          <SmartSelect
            valueField={'@id'}
            label={data.name}
            value={entityType}
            onChange={changeHandler}
            source={'entity_types'}
            reqParams={{ id: entityTypes }}
            error={getIn(errors, name) && !entityType ? getIn(errors, name) : undefined}
            disabled={disabled}
          />
        </div>
        {entityType && (
          <div>
            <SmartSelectField
              valueField={'@id'}
              source={'entities'}
              reqParams={{ entityType }}
              name={name}
              label={t('select.entity')}
              disabled={disabled}
            />
          </div>
        )}
      </Box>
    </Box>
  )
}

export default MultipleReferenceField
