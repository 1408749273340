// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd'
// Components
import { Container, DraggableItem, WidgetTypesModal } from './components'
import { useTranslation } from 'react-i18next'
// Hooks
import { useGetLayout } from '../../hooks'
import { usePageBuilderContext } from '../../context'
// Utils
import { dragEndEvent } from 'common/utils/custom-events'
// Types
import { IEntityWidget } from '../../types'
// Styles
import useStyles from './page-builder.styles'

type TypesModalState = {
  container: string
  type: 'push' | 'unshift'
}

type Props = {
  layout: string
}

const PageBuilder: FC<Props> = (props) => {
  const { layout: layoutIri } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    widgets: entityWidgets,
    actions: { addWidget, reorderWidgets, moveWidget },
  } = usePageBuilderContext()

  const [typesModalState, setTypesModalState] = useState<TypesModalState | null>(null)
  const { layout, isLoading } = useGetLayout(layoutIri)

  const widgets = useMemo<{ [containerId: string]: IEntityWidget[] }>(() => {
    if (isLoading) return {}

    const containersSchema: { [containerId: string]: IEntityWidget[] } = {}
    layout?.options.sections.forEach((section) => {
      section.containers.forEach((container) => {
        containersSchema[`${container.id}`] = []
      })
    })

    entityWidgets.forEach((item: IEntityWidget) => {
      if (containersSchema[`${item.options.container}`]) {
        containersSchema[`${item.options.container}`].push(item)
      }
    })

    entityWidgets.forEach((item: IEntityWidget) => {
      containersSchema[`${item.options.container}`].sort((a: IEntityWidget, b: IEntityWidget) => {
        return a.sortOrder > b.sortOrder ? 1 : -1
      })
    })

    return containersSchema
  }, [entityWidgets, isLoading, layout?.options.sections])

  const addWidgetHandler = useCallback(
    (widgetType: any) => {
      if (!typesModalState) return
      addWidget(typesModalState.container, widgetType, typesModalState.type)
      setTypesModalState(null)
    },
    [addWidget, typesModalState]
  )

  const dragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    (result) => {
      const { destination, source } = result
      if (!destination) return

      const dropId: any = source.droppableId
      const destId: any = destination?.droppableId

      if (dropId === destId) {
        reorderWidgets(dropId, source.index, destination?.index)
      } else {
        moveWidget(widgets[dropId], widgets[destId], source, destination)
      }

      document.dispatchEvent(dragEndEvent)
    },
    [widgets, reorderWidgets, moveWidget]
  )

  if (isLoading) return <div>{t('global.loading')}</div>

  return (
    <Box className={classes.root}>
      <DragDropContext onDragEnd={dragEnd}>
        <Grid container spacing={3}>
          {layout &&
            layout.options.sections.map((section) => {
              return (
                <Grid key={section.id} item xs={12}>
                  <Grid container spacing={2}>
                    {section.containers.map((container, containerIndex) => {
                      return (
                        <Container
                          setTypesModalState={setTypesModalState}
                          key={`${container.id}-${containerIndex}`}
                          container={container}
                          widgets={widgets[container.id]}
                          showSecondButton={Boolean(widgets[container.id]?.length)}
                        >
                          {widgets[container.id] &&
                            widgets[container.id].map((widget, widgetIndex: number) => {
                              return (
                                <DraggableItem
                                  disableMoveDown={widgets[container.id].length - 1 === widgetIndex}
                                  disableMoveUp={widgetIndex === 0}
                                  data={{ ...widget, 'container-slug': container.slug }}
                                  draggableId={`${container.id}-${widget.id}`}
                                  key={`${container.id}-${widget.id}`}
                                  index={widgetIndex}
                                />
                              )
                            })}
                        </Container>
                      )
                    })}
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </DragDropContext>
      <WidgetTypesModal
        opened={Boolean(typesModalState)}
        onClose={() => setTypesModalState(null)}
        onAddWidget={addWidgetHandler}
      />
    </Box>
  )
}

export default PageBuilder
