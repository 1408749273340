import React, { FC, useCallback, useMemo, useState } from 'react'
import { NavigationContext } from './navigation-context'
import { Navigation } from '../types/navigation-item'
import { useQuery, useQueryClient } from 'react-query'
import { httpService } from '../../data'
import { HydraResponse } from '../../types'
import { navList } from '../utils/navigation-list'
import { useTranslation } from 'react-i18next'
import { useAppContext } from 'core/app'

const transformData = (data: any[]): Navigation | [] => {
  const navArr: Navigation = []

  const sorted = data.sort((a: any, b: any) => {
    return (Number(a.options.order) || 1001) - (Number(b.options.order) || 1000)
  })

  sorted.forEach((item: any) => {
    navArr.push({
      title: item.name,
      link_to: `/entities/${item.id}`,
      icon: item.options.icon || 'icon-layers',
      slug: item.slug,
      show_in_dashboard: true,
      single: item.single,
      singleEntity: item.singleEntity,
    })
  })

  return navArr
}

export const NavProvider: FC = (props) => {
  const { children } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false)
  const [navFavoriteList, setNavFavoriteList] = useState<any>([])
  const navigationList: Navigation = useMemo(() => navList(t), [t])
  const queryClient = useQueryClient()
  const { user } = useAppContext()

  useQuery(
    'nav-favourite',
    () => {
      return httpService
        .get<HydraResponse>('entity_types', { params: { favorite: true } })
        .then((res) => res.data['hydra:member'])
    },
    {
      onSuccess: (data) => {
        setNavFavoriteList(transformData(data))
      },
      enabled: Boolean(user),
    }
  )

  const updateNav = useCallback(() => {
    queryClient.invalidateQueries('nav-favourite')
  }, [queryClient])

  const openHandler = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const openHandlerCollapse = useCallback(() => {
    setIsOpenCollapse(!isOpenCollapse)
  }, [isOpenCollapse])

  const actions = useMemo(
    () => ({ openHandler, updateNav, openHandlerCollapse }),
    [openHandler, updateNav, openHandlerCollapse]
  )

  return (
    <NavigationContext.Provider
      value={{ isOpen, isOpenCollapse, navigationList, navFavoriteList, actions }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
