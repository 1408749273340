import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginLeft: 10,
    },
    root: {
      position: 'relative',
      '&:hover .dropdown': {
        display: 'block',
      },
    },
    switcher: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 15px',
      borderRadius: 6,
      cursor: 'pointer',
      transition: 'all .3s ease',
      '&:hover': {
        backgroundColor: '#21318a',
      },
    },
    title: {
      marginLeft: 10,
    },
    dropdown: {
      position: 'absolute',
      display: 'none',
    },
    listRoot: {
      maxHeight: 376,
      overflowY: 'auto',
      overflowX: 'visible',
    },
  })
)

export default useStyles
