// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
// Components
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core'
import WidgetContent from './components/widget-content'
// Hooks
import { useTranslation } from 'react-i18next'
import { usePageBuilderContext } from '../../../../context'
// Utils
import clsx from 'clsx'
// Types
import { IEntityWidget } from 'modules/new-entity/types'
import { useConfirmation } from 'core/confirmation'
// Styles
import useStyles from './widget-item.styles'

type TProps = {
  data: IEntityWidget
  index: number
  disableMoveUp: boolean
  disableMoveDown: boolean
  previewMode?: boolean
  dnd?: DraggableProvidedDragHandleProps
}

const WidgetItem: FC<TProps> = (props) => {
  const { data, index, disableMoveUp, disableMoveDown, previewMode, dnd = {} } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()
  const [expanded, setExpanded] = useState(false)

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  const {
    widgetsDataRef,
    actions: {
      removeWidget,
      moveWidgetUp,
      moveWidgetDown,
      changeWidgetWidth,
      isWidgetExpanded,
      toggleWidget,
      setWidgetRefData,
      setGlobalWidget,
    },
  } = usePageBuilderContext()

  const isOpenedWidget = isWidgetExpanded(data.options.container, data.id)
  const prevValues =
    widgetsDataRef.current && widgetsDataRef.current[data.id]
      ? widgetsDataRef.current[data.id].formRef?.values
      : null

  const aqaTestDataAttr = useMemo(() => {
    return {
      'data-desktop-width': data.options.desktop_width,
      'data-mobile-width': data.options.tablet_width,
      'data-container': data['container-slug'] || '',
      'data-widget-id': `${data.widgetType.slug}-${data.id}`,
    }
  }, [data])

  return (
    <Box
      className={clsx(classes.root, {
        isPreview: previewMode,
        headerOpened: isOpenedWidget,
      })}
      data-error-id={data.id}
      {...aqaTestDataAttr}
    >
      <div className={clsx(classes.header, { headerOpened: isOpenedWidget })}>
        <span
          onClick={() => toggleWidget(data.options.container, data.id)}
          className={classes.toggleWidget}
        />
        <div className={clsx(classes.actionArea, 'action--area')}>
          {!previewMode ? (
            <ButtonGroup
              className={classes.buttonGroup}
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button disabled={disableMoveDown} onClick={() => moveWidgetDown(data.id)}>
                <Tooltip aria-disabled={disableMoveDown} placement={'top'} title={'move-down'}>
                  <Icon className={'icon-arrow-down'} />
                </Tooltip>
              </Button>
              <Button disabled={disableMoveUp} onClick={() => moveWidgetUp(data.id)}>
                <Tooltip aria-disabled={disableMoveUp} placement={'top'} title={'move-up'}>
                  <Icon className={'icon-arrow-up'} />
                </Tooltip>
              </Button>
              <Button onClick={() => toggleWidget(data.options.container, data.id)}>
                <Tooltip placement={'top'} title={!isOpenedWidget ? 'expand' : 'collapse'}>
                  <Icon className={`icon-chevron-${!isOpenedWidget ? 'down' : 'up'}`} />
                </Tooltip>
              </Button>
              <Button onClick={() => removeWithConfirmation(() => removeWidget(data.id))}>
                <Tooltip placement={'top'} title={'remove'}>
                  <Icon className={'icon-trash'} />
                </Tooltip>
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              className={classes.buttonGroup}
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button onClick={() => setExpanded(!expanded)}>
                <Tooltip placement={'top'} title={'expand'}>
                  <Icon className={`icon-chevron-${!expanded ? 'down' : 'up'}`} />
                </Tooltip>
              </Button>
            </ButtonGroup>
          )}
        </div>
        <div className={classes.icons}>
          <div className={classes.dragger} {...dnd}>
            <Icon className={'icon-dragindrop'} />
          </div>
        </div>
        <div className={classes.contentHolder}>
          <span className={classes.counter}>{index + 1}</span>
          <p className={classes.name}>
            {data?.isCreated && <strong>(Not saved) - </strong>}
            <span>{data.widgetType ? data.widgetType?.name : ''} /</span>
            <strong> {data?.name}</strong>
          </p>
        </div>
        <div className={classes.widthSelect}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="widget-width">{t('widget.d-width')}</InputLabel>
            <Select
              labelId={'widget-width'}
              onChange={(event: React.ChangeEvent<any>) => {
                changeWidgetWidth('desktop_width', data.id, event.target.value)
              }}
              label={t('widget.d-width')}
              value={data.options.desktop_width}
              readOnly={previewMode}
            >
              <MenuItem value={100}>100%</MenuItem>
              <MenuItem value={50}>50%</MenuItem>
              <MenuItem value={33}>33%</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel id="widget-width">{t('widget.t-width')}</InputLabel>
            <Select
              labelId={'widget-width'}
              onChange={(event: React.ChangeEvent<any>) => {
                changeWidgetWidth('tablet_width', data.id, event.target.value)
              }}
              label={t('widget.t-width')}
              value={data.options.tablet_width}
              readOnly={previewMode}
            >
              <MenuItem value={100}>100%</MenuItem>
              <MenuItem value={50}>50%</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {isOpenedWidget && (
        <Collapse className={classes.expandPanelEdit} in={isOpenedWidget} unmountOnExit>
          <WidgetContent
            setWidgetRefData={setWidgetRefData}
            data={data}
            prevValues={prevValues}
            setGlobalWidget={setGlobalWidget}
          />
        </Collapse>
      )}
      {previewMode && (
        <Collapse className={classes.expandPanel} in={expanded} unmountOnExit>
          <WidgetContent
            setWidgetRefData={setWidgetRefData}
            data={data}
            prevValues={prevValues}
            setGlobalWidget={setGlobalWidget}
            previewMode
          />
        </Collapse>
      )}
    </Box>
  )
}

export default WidgetItem
