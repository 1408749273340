import React, { FC, ReactElement } from 'react'
// Types
import { DataControllerProps } from 'core/data'
// UI
import { FormHolder } from 'ui'

type TProps = {} & DataControllerProps

const CrudFormContainer: FC<TProps> = (props) => {
  const { children, name: resourceName } = props
  return <FormHolder title={resourceName}>{children as ReactElement}</FormHolder>
}

export default CrudFormContainer
