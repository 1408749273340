import { RefObject } from 'react'
import { FormikProps } from 'formik'
import { SortedAttribute } from './attribute'
import { Entity, OneOfAttributeTypes } from '../../../common/types'

export type FormProps = FormikProps<any>

export type FormRef = RefObject<FormProps>

export type WidgetDataRef = {
  formRef: FormikProps<any>
  attributes: SortedAttribute[]
}

export type WidgetsDataRef = {
  [widgetId: number]: WidgetDataRef
}

export type AttributeData = {
  '@id': string
  attributeType: { type: OneOfAttributeTypes }
  name: string
  slug: string
  defaultValues: [] | { value: string }
  validationRules: {
    required?: boolean
    length?: { min: number; max: number }
    regex?: string
    range?: { min: number; max: number }
    count?: { min: number; max: number }
  }
  options: AttributeOptions
  hint: string
  attributeEnums?: SelectEnum[]
  entityType: string
  setAttributes?: FormAttribute[]
  entityTypes: string[]
  readonly: boolean
  system: boolean
}

export type AttributeOptions = {
  hidden: boolean
  show_in_sidebar: boolean
  fieldWidth: number
  type: string
  create_entity_button: boolean
  show_links_modal: boolean
  preservation: boolean
  skip_from_copy: boolean
}

export type FormAttribute = {
  id: number
  options: any
  sortOrder: number
  attribute: AttributeData
  nameable: boolean
}

export type TOptions = any | ''

export type SelectEnum = {
  id: number
  name: string
  value: string
  _value?: string
  sortOrder: number
}

export type Value = {
  id?: number
  attribute: string
  value: string
  locale?: string
  type: string
}

export type SetRepeat = {
  id?: number
  attribute: string
  set: string
  values: Value[]
}

export type OriginalValue = {
  id: number
} & Value

export type FormData = {
  id: number
  '@id': string
  entityTypeAttributes: FormAttribute[]
  values: Value[]
  // TODO set type
  entityWidgets: any[]
  originalValues: OriginalValue[]
  options: any
  entity?: Entity
}

export type ApiError = {
  message: string
  propertyPath: string
}

export enum MutationType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum EavResourceType {
  ENTITY = 'entities',
  WIDGET = 'widgets',
  TEMPLATE = 'templates',
}
