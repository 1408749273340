// Core
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
// Components
import { DataGridHeader } from 'ui'
// Hooks
import { useDataViewContext } from './data-view-context'
import { useAppContext, Permissions } from 'core/app'

type Props = {
  children?: never
}

const TableHeader: FC<Props> = () => {
  const { isDataFetching } = useDataViewContext()
  const { entityType, typeId, search, setSearch } = useDataViewContext()
  const {
    actions: { userCan },
  } = useAppContext()

  const canCreate = userCan(entityType.slug, Permissions.CREATE)
  const createPath = `/entities/${typeId}/create`

  const [s, setS] = useState(search)

  const submitHandler = useCallback(
    (e: FormEvent) => {
      setSearch(s)
      e.preventDefault()
    },
    [s, setSearch]
  )

  const clearHandler = useCallback(() => {
    setSearch('')
    setS('')
  }, [setSearch])

  // Delay loading
  const [showLoading, setShowLoading] = useState(isDataFetching)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isDataFetching) {
      setShowLoading(true)
    } else {
      timeout = setTimeout(() => setShowLoading(false), 1500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [isDataFetching])

  return (
    <DataGridHeader
      searchValue={s}
      onSearchChange={setS}
      onSearchSubmit={submitHandler}
      onSearchClear={clearHandler}
      hasCreateButton={canCreate}
      buttonText="Create new entity"
      buttonProps={{
        component: NavLink,
        to: createPath,
      }}
      isLoading={showLoading}
    />
  )
}

export default TableHeader
