import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const groupStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2, 2, 2, 2),
      borderRadius: theme.spacing(0.5),
    },
    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // paddingBottom: theme.spacing(2),
      transition: theme.transitions.create(['padding'], {
        duration: theme.transitions.duration.standard,
      }),
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: theme.spacing(2),
        display: 'block',
        width: '0',
        left: 0,
        right: 0,
        margin: '0 auto',
        height: '1px',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
    headerOpen: {
      paddingBottom: theme.spacing(2),
      '&:after': {
        margin: '0 auto',
        width: '100%',
        left: 0,
        right: 0,
      },
    },
    collapse: {
      // paddingTop: theme.spacing(2),
    },
  })
)

export default groupStyles
