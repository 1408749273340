import { TOKEN_KEY, REFRESH_TOKEN_KEY } from './constants'

const storage = {
  get storage() {
    return window.localStorage
  },
  setToken(token: string) {
    this.storage.setItem(TOKEN_KEY, token)
  },
  setRefreshToken(token: string) {
    this.storage.setItem(REFRESH_TOKEN_KEY, token)
  },
  getToken() {
    return this.storage.getItem(TOKEN_KEY)
  },
  getRefreshToken() {
    return this.storage.getItem(REFRESH_TOKEN_KEY)
  },
  logout() {
    this.storage.removeItem(TOKEN_KEY)
    this.storage.removeItem(REFRESH_TOKEN_KEY)
  },
  login(token: string, refreshToken: string) {
    this.setToken(token)
    this.setRefreshToken(refreshToken)
  },
}

export default storage
