// Core
import React, { FC, useCallback, useMemo } from 'react'
import { Box, Button, CircularProgress, Divider } from '@material-ui/core'
// Components
import { SmartSelect } from 'ui'
import AttributesForm from '../../../../../attributes-form'
import CollectionPreview from '../collection-preivew'
// Types
import { useWidgetController } from '../../../../../../hooks'
import {
  EavResourceType,
  IEntityWidget,
  MutationType,
  SetGlobalWidgetFunc,
  SetWidgetRefDataFunc,
} from '../../../../../../types'

type Props = {
  data: IEntityWidget
  setWidgetRefData: SetWidgetRefDataFunc
  setGlobalWidget: SetGlobalWidgetFunc
  prevValues?: any
  previewMode?: boolean
}

const WidgetContent: FC<Props> = (props) => {
  const { data, setWidgetRefData, prevValues, setGlobalWidget, previewMode } = props
  const {
    isCollection,
    typeId,
    isLoading,
    isFetching,
    entityType,
    validationSchema,
    initialValues,
    attributes,
    global,
  } = useWidgetController(data)

  const globalWidgetChangeHandler = useCallback(
    (e: any) => {
      setGlobalWidget(data.id, e)
    },
    [setGlobalWidget, data]
  )

  const formValues = useMemo(() => {
    if (data.widget) {
      return initialValues
    }
    return prevValues || initialValues
  }, [data, initialValues, prevValues])

  const loading = data.widget ? isFetching : isLoading

  return (
    <>
      {!isCollection && (
        <>
          {!previewMode && (
            <>
              <SmartSelect
                value={data.widget}
                source={'widgets'}
                label={'Select from global widgets'}
                reqParams={{ widgetType: `/api/widget_types/${typeId}` }}
                onChange={globalWidgetChangeHandler}
                valueField="@id"
              />
              <Box my={3}>
                <Divider />
              </Box>
            </>
          )}
        </>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AttributesForm
            resourceType={EavResourceType.WIDGET}
            type={MutationType.EDIT}
            mainAttributes={attributes}
            initialValues={formValues}
            validationSchema={validationSchema}
            typeOptions={entityType?.options}
            // @ts-ignore
            formRef={(formRef) => {
              if (!data.widget && !previewMode) {
                setWidgetRefData(data.id, formRef, attributes)
              }
            }}
            disabled={Boolean(data.widget) || previewMode}
            widget={data}
          />
          {global.show && (
            <Button
              onClick={() => global.saveAsGlobal()}
              style={{ marginTop: 20 }}
              color="secondary"
              size="large"
              variant="contained"
              disabled={global.isLoading}
            >
              Save as global
            </Button>
          )}
        </>
      )}
      {isCollection && <CollectionPreview widgetData={data} />}
    </>
  )
}

export default WidgetContent
