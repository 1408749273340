// Libs
import React, { FC, useCallback } from 'react'
import { useFormikContext } from 'formik'
// Components
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type TProps = {
  type: 'create' | 'edit'
  // beforeSubmit?: () => void
}

const SubmitButton: FC<TProps> = (props) => {
  const { type } = props
  const { submitForm } = useFormikContext()
  const { t } = useTranslation()

  const clickHandler = useCallback(() => {
    // beforeSubmit && beforeSubmit()
    submitForm()
  }, [submitForm])

  return (
    <Button color="primary" variant="contained" size="large" onClick={clickHandler}>
      {type === 'create' ? t('global.create') : t('global.save')}
    </Button>
  )
}

export default SubmitButton
