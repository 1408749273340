// Core
import React, { ChangeEvent, FC, useCallback } from 'react'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useField } from 'formik'
// Styles
import useStyle from './select-field.style'
import { WordBreakWrapper } from 'ui'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  label?: string
  children?: never
  options: { value: string | number; label: string | number }[]
  multiple?: boolean
  disabled?: boolean
}

const SelectField: FC<Props> = (props) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const { options, label, name, multiple, disabled } = props

  const [field, meta, helpers] = useField(name)

  const changeHandler = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      helpers.setValue(e.target.value)
    },
    [helpers]
  )

  const getValue = () => {
    const defaultValue = field.value || ''
    if (multiple && !Array.isArray(field.value)) {
      return [defaultValue]
    }
    return defaultValue
  }

  return (
    <Box className={classes.root}>
      <FormControl fullWidth variant="outlined" error={Boolean(meta.error && meta.touched)}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          {...field}
          value={getValue()}
          onChange={changeHandler}
          multiple={multiple}
          label={label || t('select.value')}
          disabled={disabled}
        >
          {!multiple && (
            <MenuItem value={''}>
              <em className={classes.none}>{t('select.empty-value')}</em>
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              <WordBreakWrapper children={item.label} />
            </MenuItem>
          ))}
        </Select>
        {Boolean(meta.touched && meta.error) && (
          <FormHelperText>
            {<span className={classes.helperText}>{meta.touched && meta.error}</span>}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default SelectField
