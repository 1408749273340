// Core
import React, { FC, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Link,
  ListItemIcon,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core'
// Hooks
import {
  useChangeEntityStatus,
  useDeleteEntity,
  useDuplicateEntity,
  useTypePermissions,
} from 'modules/new-entity/hooks'
import { useConfirmation } from 'core/confirmation'
// Types
import { EntityStatus, SaveEntityActions } from 'modules/new-entity/types'
import { EntityType } from 'core/types'

type Props = {
  originalId: number
  currentId: number
  isEdit: boolean
  isTemplate: boolean
  isUrlable: boolean
  status: EntityStatus
  isLoading: boolean
  entityType: EntityType
  saveEntityAction: (actionType: SaveEntityActions) => void
  saveAsTemplateAction: () => void
  insertTemplateAction: (value: boolean) => void
  checkUnsavedChanges: () => boolean
  setSavedChanges: () => void
  children?: never
}

const getMainActionText = (isEdit: boolean, isUrlable: boolean, isTemplate: boolean) => {
  if (!isUrlable && isEdit) return 'Save'
  if (isUrlable && isEdit) {
    if (isTemplate) return 'Save'
    return 'Publish'
  }
  return 'Create'
}

const getMainPath = (entityType: EntityType, isTemplate: boolean) => {
  if (entityType['@type'] === 'EntityType') {
    if (isTemplate) return 'templates'
    return 'entities'
  }

  return 'widgets'
}

const statusNamesMap = {
  draft: {
    title: 'Draft',
    icon: 'icon-eye-off',
  },
  publish: {
    title: 'Published',
    icon: 'icon-eye',
  },
  archive: {
    title: 'Archived',
    icon: 'icon-archive',
  },
}

const FormActions: FC<Props> = (props) => {
  const {
    originalId,
    currentId,
    isEdit,
    isTemplate,
    isUrlable,
    status,
    isLoading,
    saveEntityAction,
    entityType,
    checkUnsavedChanges,
    setSavedChanges,
    insertTemplateAction,
    saveAsTemplateAction,
  } = props

  const history = useHistory()
  const { setConfirmation } = useConfirmation()
  const permissions = useTypePermissions(entityType)

  const hasUrlableActions = isEdit && isUrlable
  const showStatus = isEdit && isUrlable && !isTemplate && Boolean(status)
  const isSingle = entityType.single
  const needTemplates = isUrlable && !isSingle && entityType.builder && !isTemplate
  const mainAction: SaveEntityActions = isEdit && isUrlable && !isTemplate ? 'publish' : 'default'
  const mainActionText = getMainActionText(isEdit, isUrlable, isTemplate)
  const mainPath = getMainPath(entityType, isTemplate)
  const isWidgets = mainPath === 'widgets'

  const actionsWrapperRef = useRef(null)
  const [showActions, setShowActions] = useState(false)

  const { deleteEntity, deleteEntityM } = useDeleteEntity(originalId, {
    onSuccess: () => history.push(`/${mainPath}/${entityType.id}`),
    isWidgetType: isWidgets,
  })

  const { changeEntityStatus, changeStatusM } = useChangeEntityStatus(
    `api/entities/${originalId}`,
    {
      onSuccess: () => history.push(`/${mainPath}/${entityType.id}`),
    }
  )

  const { duplicateEntity, duplicateEntityM } = useDuplicateEntity(currentId, {
    onSuccess: (createdId) => {
      history.push(`/${mainPath}/${entityType.id}/edit/${createdId}`)
    },
    isWidgetType: isWidgets,
  })

  const actionsData = useMemo(() => {
    const actionWrapper = (action: any) => () => {
      action()
      setShowActions(false)
    }

    const createHandler = () => {
      history.push(`/${mainPath}/${entityType.id}/create`)
    }

    const duplicateHandler = () => {
      const hasUnsavedChanges = checkUnsavedChanges()

      if (hasUnsavedChanges) {
        setConfirmation({
          open: true,
          title: 'Confirm duplication',
          content: 'You have unsaved changes. Would you like to continue to duplicate?',
          onSuccess: () => {
            setSavedChanges()
            duplicateEntity()
          },
        })
      } else {
        duplicateEntity()
      }
    }

    const deleteHandler = () => {
      setSavedChanges()
      deleteEntity()
    }

    const archiveHandler = () => {
      changeEntityStatus('archive')
    }

    return [
      {
        name: 'unpublish',
        label: 'Unpublish',
        action: actionWrapper(() => saveEntityAction('unpublish')),
        condition: status === 'publish' && permissions.canEdit && isUrlable && !isTemplate,
        icon: 'icon-eye-off',
      },
      {
        name: 'create_new',
        label: 'Create new',
        action: actionWrapper(createHandler),
        condition: isEdit && !isSingle && permissions.canCreate,
        icon: 'icon-add-default',
      },
      {
        name: 'duplicate',
        label: 'Duplicate',
        action: actionWrapper(duplicateHandler),
        condition: isEdit && !isSingle && permissions.canCreate,
        icon: 'icon-copy',
      },
      {
        name: 'insert_template',
        label: 'Insert template',
        action: actionWrapper(() => insertTemplateAction(true)),
        condition: needTemplates,
        icon: 'icon-add-above',
      },
      {
        name: 'save_as_template',
        label: 'Save as template',
        action: actionWrapper(() => saveAsTemplateAction()),
        condition: needTemplates && isEdit,
        icon: 'icon-add-below',
      },
      {
        name: 'delete',
        label: 'Delete',
        action: actionWrapper(deleteHandler),
        condition: isEdit && !isSingle && permissions.canDelete,
        icon: 'icon-delete',
      },
      {
        name: 'archive',
        label: 'Archive',
        action: actionWrapper(archiveHandler),
        condition:
          status !== 'archive' &&
          isUrlable &&
          isEdit &&
          !isSingle &&
          permissions.canEdit &&
          !isTemplate &&
          !isWidgets,
        icon: 'icon-archive',
      },
    ]
  }, [
    status,
    permissions.canEdit,
    permissions.canCreate,
    permissions.canDelete,
    isUrlable,
    isTemplate,
    isEdit,
    isSingle,
    needTemplates,
    history,
    mainPath,
    entityType.id,
    checkUnsavedChanges,
    setConfirmation,
    setSavedChanges,
    duplicateEntity,
    deleteEntity,
    saveEntityAction,
    insertTemplateAction,
    saveAsTemplateAction,
    changeEntityStatus,
    isWidgets,
  ])

  const loading =
    isLoading || deleteEntityM.isLoading || duplicateEntityM.isLoading || changeStatusM.isLoading
  const hasActions = actionsData.some((action) => action.condition)

  return (
    <Box flex={1} paddingX={1}>
      <Grid container justifyContent="space-between" alignItems="center">
        {hasUrlableActions && (
          <Box flex={1}>
            {!isTemplate && permissions.canEdit && (
              <>
                <Link
                  disabled={loading}
                  onClick={() => saveEntityAction('draft')}
                  component="button"
                  data-aqa-action="save"
                >
                  Save
                </Link>
                <span style={{ margin: '0 5px' }}>|</span>
              </>
            )}
            <Link
              disabled={loading}
              onClick={() => saveEntityAction('preview')}
              component="button"
              data-aqa-action="preview"
            >
              Preview
            </Link>
          </Box>
        )}
        <Box
          // @ts-ignore
          ref={actionsWrapperRef}
          flex={hasUrlableActions ? 0 : 1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {permissions.canEdit && (
            <Button
              onClick={() => saveEntityAction(mainAction)}
              disabled={loading}
              size="large"
              variant="contained"
              color="primary"
              data-aqa-action={mainActionText.toLowerCase()}
            >
              {loading && <CircularProgress size={20} />} {mainActionText}
            </Button>
          )}
          {hasActions && (
            <>
              <IconButton
                size="small"
                disabled={loading}
                style={{ borderRadius: 3, marginLeft: 5 }}
                onClick={() => setShowActions(true)}
                data-aqa-action="open_actions"
              >
                <Icon className="icon-more_horiz-1" />
              </IconButton>
              <Popover
                anchorEl={actionsWrapperRef.current}
                open={showActions}
                onClose={() => setShowActions(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {actionsData.map((action) => {
                  if (!action.condition) return null
                  return (
                    <MenuItem
                      key={action.name}
                      data-aqa-action={action.name}
                      onClick={action.action}
                    >
                      <ListItemIcon>
                        <Icon className={action.icon} />
                      </ListItemIcon>
                      {action.label}
                    </MenuItem>
                  )
                })}
              </Popover>
            </>
          )}
        </Box>
      </Grid>
      {showStatus && status && (
        <Box mt={1} display="flex">
          <Typography>
            Status: <b>{statusNamesMap[status].title}</b>
          </Typography>
          <Icon color="primary" style={{ marginLeft: 5 }} className={statusNamesMap[status].icon} />
        </Box>
      )}
    </Box>
  )
}

export default FormActions
