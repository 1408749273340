/** Core*/
import React, { useCallback, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'
import { SmartSelectField } from 'core/form'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
/** Utils*/
import { httpService } from 'core/data'
/** Types*/
import { LinksModalInitValues } from '../../../../types'
import { SmartSelect } from 'ui'

enum ReviewsEnum {
  SPORTSBOOK = 'sportsbook-review',
  CASINO = 'cs-reviews',
  APP = 'reviews-app',
  PSYCHICS = 'psychics-review',
}

const AffiliateFields = () => {
  const { t } = useTranslation()
  const {
    values: { brand },
    setFieldValue,
  } = useFormikContext<LinksModalInitValues>()
  const [selectedReview, setSelectedReview] = useState<string>('')
  const enabled = useRef(Boolean(brand))
  const {
    data,
    isError,
    isLoading: afIsLoading,
  } = useQuery('affiliate-entity-type', () => {
    return httpService
      .get<any>('entity_types', { params: { slug: 'affiliate_link' } })
      .then((res) => res.data['hydra:member'][0])
  })

  const { isLoading } = useQuery(
    ['get-entity-type-by-brand', brand],
    async () => {
      const resp = await httpService.get<any>(`entities/${brand}`)
      return resp.data.entityType
    },
    {
      enabled: enabled.current,
      cacheTime: 0,
      onSuccess: (res) => {
        setSelectedReview(res)
        enabled.current = false
      },
    }
  )

  const selectReviewHandler = useCallback(
    (id) => {
      setSelectedReview(id)
      setFieldValue('brand', '')
    },
    [setFieldValue]
  )

  return (
    <>
      {!afIsLoading && !isError && data && (
        <Grid item xs={12}>
          <SmartSelectField
            valueField={'id'}
            source={'entities'}
            name={'affiliate'}
            label={t('select.affiliate-link')}
            reqParams={{ entityType: data['@id'] }}
          />
        </Grid>
      )}

      {!isLoading && (
        <Grid item xs={6}>
          <SmartSelect
            valueField={'@id'}
            label={t('links.select-review')}
            onChange={selectReviewHandler}
            source={'entity_types'}
            value={selectedReview}
            reqParams={{
              slug: [
                ReviewsEnum.APP,
                ReviewsEnum.CASINO,
                ReviewsEnum.SPORTSBOOK,
                ReviewsEnum.PSYCHICS,
              ],
            }}
          />
        </Grid>
      )}
      {Boolean(selectedReview.length) && (
        <Grid item xs={6}>
          <SmartSelectField
            valueField={'id'}
            label={t('links.brand')}
            source={'entities'}
            name={'brand'}
            value={brand || ''}
            reqParams={{ entityType: selectedReview, urlable: true }}
          />
        </Grid>
      )}
    </>
  )
}

export default AffiliateFields
