// Core
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
// Hooks
import { isRelationError, useDeleteRelations } from './use-delete-relations'
import { useNotify } from 'core/hooks'
import { useConfirmation } from 'core/confirmation'
// Services
import { httpService } from 'core/data'

type Params = {
  invalidateKey?: string
  onSuccess?: () => void
  isWidgetType?: boolean
}

export const useDeleteEntity = (entityId: number, params: Params = {}) => {
  const { invalidateKey, onSuccess, isWidgetType } = params
  const queryClient = useQueryClient()
  const { notifyDeleteRelations } = useDeleteRelations()
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const { t } = useTranslation()
  const mainPath = isWidgetType ? 'widgets' : 'entities'

  const deleteM = useMutation(
    async () => {
      const entityReq = httpService.get<any>(`/${mainPath}/${entityId}`).then((res) => res.data)
      const relatedUrlsReq = httpService.get(`/entity_urls/${entityId}`).then((res) => res.data)
      const dataRes = await Promise.all([entityReq, relatedUrlsReq])

      const urlsData = {
        url:
          dataRes[0].entityUrls && dataRes[0].entityUrls[0] ? dataRes[0].entityUrls[0].url : null,
        urls: dataRes[1],
      }

      await httpService.delete(`/${mainPath}/${entityId}`)
      return urlsData
    },
    {
      onSuccess: (data) => {
        if (invalidateKey) {
          queryClient.invalidateQueries(invalidateKey)
        }
        notify(`Entity ${t('actions.deleted')}`, { type: 'success' })
        if (onSuccess) onSuccess()
      },
      onError: (error) => {
        if (isRelationError(error)) {
          notifyDeleteRelations(error)
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
    }
  )

  const deleteEntity = useCallback(() => {
    setConfirmation({
      open: true,
      title: t('global.delete'),
      content: t('notify.delete'),
      onSuccess: deleteM.mutate,
    })
  }, [deleteM.mutate, setConfirmation, t])

  return { deleteEntity, deleteEntityM: deleteM }
}
