// Core
import React, { FC } from 'react'
import { Icon, MenuItem, MenuList, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
// Components
import { SiteItem } from './components'
// Hooks
import { useSitesContext } from '../../hooks'
import { useTranslation } from 'react-i18next'
// Styles
import useStyles from './site-switcher.styles'

type Props = {}

export const SiteSwitcher: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data, activeSite } = useSitesContext()

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.switcher}>
          <Icon className="icon-list" />
          <div className={classes.title}>{t('list.sites')}</div>
        </div>
        <div className={clsx(classes.dropdown, 'dropdown')}>
          <Paper>
            <MenuList className={classes.listRoot}>
              {data?.map((site) => (
                <SiteItem data={site} key={site.id} isActive={site['@id'] === activeSite['@id']} />
              ))}
            </MenuList>
          </Paper>
        </div>
      </div>
      {activeSite && (
        <div className={classes.root}>
          <div className={classes.switcher}>
            <Icon className="icon-home" />
            <div className={classes.title}>{activeSite.name}</div>
          </div>
          <div className={clsx(classes.dropdown, 'dropdown')}>
            <Paper>
              <MenuList>
                <MenuItem component="a" target="_blank" href={activeSite.url}>
                  {t('select.visit')}
                </MenuItem>
                <Link
                  to={`/sites/${activeSite.id}`}
                  style={{ textDecoration: 'none', display: 'block', color: 'inherit' }}
                >
                  <MenuItem>{t('select.edit-site')}</MenuItem>
                </Link>
              </MenuList>
            </Paper>
          </div>
        </div>
      )}
    </div>
  )
}

export default SiteSwitcher
