import React from 'react'
import { ActionsColumn } from '../components'
import { ColumnType } from '../types'

export const getActionsColumns = (
  resourceName: string,
  t: any,
  onDelete?: () => void
): ColumnType => {
  const actionsColumn: ColumnType = {
    sorting: false,
    filtering: false,
    width: 130,
    title: t('table.actions'),
    render: (rowData: any) => (
      <ActionsColumn onDelete={onDelete} resource={resourceName} rowData={rowData} />
    ),
  }

  return actionsColumn
}
