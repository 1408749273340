import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '890px',
      margin: '24px auto',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 0, 6),
    },
    header: {
      fontWeight: 500,
      padding: theme.spacing(2, 2),
      marginBottom: theme.spacing(3.5),
      fontSize: '1rem',
      borderBottom: '1px solid #EEEEEE',
    },
    inputsContainer: {
      padding: theme.spacing(0, 2),
    },
  })
)

export default styles
