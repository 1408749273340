import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'
import { defaultThemeSizes } from 'core/themes/default/default'

const { drawerWidth, drawerWidthClose } = defaultThemeSizes

const drawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },

    actionIcons: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '&:after': {
        content: '""',
        display: 'block',
        height: 1,
        width: 40,

        background: '#E0E0E0',

        opacity: 1,
      },
    },

    actionIconsOpen: {
      alignItems: 'flex-end',

      '&:after': {
        opacity: 0,
        transition: theme.transitions.create('opacity', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },

    icons: {
      color: colors.blueGrey['600'],
    },

    hide: {
      display: 'none',
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },

    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerWidthClose,
    },
  })
)

export default drawerStyles
