import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: '64px',
      zIndex: 1001,
      background: '#fff',
      borderBottom: '1px solid #e0e0e0',
    },
    breadcrumbsWrap: {
      width: '100%',
      height: 50,
      padding: '0 24px',
      borderBottom: '1px solid #e0e0e0',
      display: 'flex',
      alignItems: 'center',
    },
    titleWrap: {
      padding: '0 24px',
    },
  })
)

export default styles
