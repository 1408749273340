// Core
import React, { FC, useCallback } from 'react'
import { Dialog, DialogContent, Grid, Paper } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
// Components
import { EditFile, FileDetails } from './components'
import { httpService, LanguageProvider } from 'core/data'
import { DialogTitle } from 'ui'
import FormSkeleton from './components/modal-skeleton/form-skeleton'
import MediaError from './components/media-error/media-error'
// Hooks
import { useModalWarnings } from 'core/hooks'
import { useListParams, useUpdate } from 'core/data'
import { useDelete } from 'core/data'
// Utils
import { transformLocalizations } from 'core/data'
// Redux
import { fileFormModalSelector, toggleFileForm } from 'modules/media/store'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Types
import { IFile } from 'modules/media/types'

type TProps = {}

const FileFormModal: FC<TProps> = () => {
  const { methods } = useListParams({})
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const { open, type, data, onDelete } = useSelector(fileFormModalSelector)

  const {
    data: mediaData,
    isFetching,
    isError,
  } = useQuery(
    `media-${data?.id}`,
    () => {
      return httpService.get<IFile>(`/media/${data?.id}`).then(({ data }) => data)
    },
    {
      enabled: Boolean(data),
      cacheTime: 0,
      retry: false,
    }
  )

  const closeMediaModal = useCallback(() => {
    if (location.pathname.includes('media')) {
      methods.removeFilter('edit')
    }
    dispatch(toggleFileForm(false, 'create'))
  }, [dispatch, location.pathname, methods])

  const {
    prompt,
    methods: { showWarningModal, closeModal, setRef },
  } = useModalWarnings(closeMediaModal)

  const { mutate: update } = useUpdate('media')

  const { mutate: remove } = useDelete('media')

  const removeHandler = useCallback(() => {
    if (onDelete) {
      onDelete()
    } else {
      // @ts-ignore
      remove(data?.id || '', {
        onSuccess: () => closeModal(),
      })
    }
    closeModal()
  }, [closeModal, data?.id, onDelete, remove])

  const submitHandler = useCallback(
    (values: any) => {
      update(
        { id: data ? data.id : '', values },
        {
          onSuccess: closeModal,
        }
      )
    },
    [closeModal, data, update]
  )

  if (!data) return null

  return (
    <LanguageProvider notSystem>
      <Dialog open={open} maxWidth="xl" fullWidth onClose={showWarningModal}>
        <DialogTitle onClose={showWarningModal}>
          {t(`global.${type}`)} {t(`media.index`)}
        </DialogTitle>
        {isError ? (
          <MediaError />
        ) : isFetching && !mediaData ? (
          <FormSkeleton />
        ) : (
          <Formik
            initialValues={transformLocalizations(mediaData) || {}}
            innerRef={setRef}
            onSubmit={submitHandler}
            enableReinitialize
          >
            {() => (
              <Form>
                <DialogContent style={{ backgroundColor: '#F4F6F8' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Paper style={{ padding: '20px 16px' }}>
                        <FileDetails data={mediaData} />
                        <img
                          style={{ maxWidth: '100%', height: 'auto', marginTop: 24 }}
                          src={mediaData?.thumbnailUrls?.preview}
                          alt=""
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <EditFile removeHandler={removeHandler} />
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
      {prompt}
    </LanguageProvider>
  )
}

export default FileFormModal
