// Core
import React, { FC, useCallback, useState } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import { Button, DialogActions, DialogContent, FormControl } from '@material-ui/core'
import * as Yup from 'yup'
// Components
import { SmartSelectField } from 'core/form'
import { SmartSelect } from 'ui'
import { useTranslation } from 'react-i18next'
// Utils
import { transformResponseValidationData } from 'core/utils'

type Props = {
  onCancel: () => void
  onSubmit: (entityType: string, entity: string) => void
}

type FormProps = { entity: string }

const validationSchema = Yup.object().shape({
  entity: Yup.string().required(),
})

const EntitySettings: FC<Props> = (props) => {
  const { onCancel, onSubmit } = props
  const [entityType, setEntityType] = useState('')
  const { t } = useTranslation()

  const initialValues: FormProps = { entity: '' }

  const submitHandler = useCallback(
    async (values: FormProps, formHelpers: FormikHelpers<any>) => {
      try {
        await onSubmit(entityType, values.entity)
      } catch (e) {
        // @ts-ignore
        const errors = transformResponseValidationData(e.response?.data?.violations)
        if (errors) {
          if (errors.entityUrl) errors.entity = errors.entityUrl
          formHelpers.setErrors(errors)
        }
        formHelpers.setSubmitting(false)
      }
    },
    [onSubmit, entityType]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => (
        <Form>
          <DialogContent>
            <FormControl fullWidth>
              <SmartSelect
                source="entity_types"
                label={t('select.entity-type')}
                reqParams={{ urlable: true }}
                valueField="@id"
                onChange={(id) => setEntityType(id as string)}
              />
            </FormControl>
            {entityType && (
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <SmartSelectField
                  name="entity"
                  source="entities"
                  label={t('select.entity')}
                  reqParams={{ entityType }}
                />
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button disabled={!formikProps.isValid || formikProps.isSubmitting} type="submit">
              Add
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

export default EntitySettings
