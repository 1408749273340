import cloneDeep from 'lodash.clonedeep'
import omit from 'lodash.omit'
import {
  Attribute,
  FormRef,
  IEntityWidget,
  SortedAttribute,
  WidgetsDataRef,
  IEntity,
  EavResourceType,
  IEntityCreate,
  EntityStatus,
} from '../types'
import { referencesParser } from './references-parser'
import { FormikTouched, setNestedObjectValues } from 'formik'
import { httpService } from '../../../core/data'
import { EntityType, HydraResponse } from '../../../core/types'
import { ValuesGenerator } from 'modules/new-entity/transformers'
import { getIdFromIri } from 'core/utils'
import { Site } from 'modules/sites'

function clearEntityValuesIds(data: any, level: number = 0): void {
  level = level + 1
  if (Array.isArray(data)) {
    data.forEach((item) => {
      clearEntityValuesIds(item, level)
    })
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      const clearKeys = ['id', '@context', '@type', '@id']
      if (data._widgetType) {
        data.widgetType = data._widgetType['@id']
      }
      if (data.widget) {
        delete data.widgetType
      } else if (key === 'widgetType' && level > 1) {
        data[key] = data[key]['@id']
        delete data.widget
      }
      if (clearKeys.includes(key)) {
        delete data[key]
      }
      clearEntityValuesIds(data[key], level)
    })
  }
}
export class EntityService {
  static generateValues(
    values: any,
    attributes: SortedAttribute[],
    isWidget = false,
    skipIds = false
  ) {
    values = cloneDeep(values)
    referencesParser(values, attributes as any)
    const repeatsKey = isWidget ? 'entitySetRepeats' : 'setRepeats'
    values = new ValuesGenerator(attributes as any, values, repeatsKey, skipIds).getData()

    return values
  }

  static prepareWidgetsValues(
    widgets: IEntityWidget[],
    widgetsDataRef: WidgetsDataRef,
    template = false,
    skipWidgetsTransform = false
  ) {
    widgets = cloneDeep(widgets)

    widgets = widgets.map((widget) => {
      /**
       * Find widget form ref and attributes to generate values per each widget
       */
      const widgetRefData = widgetsDataRef[widget.id]

      if (widgetRefData && !widget.widget) {
        const widgetValues = EntityService.generateValues(
          widgetRefData.formRef.values,
          widgetRefData.attributes,
          true,
          template
        )
        widget.values = widgetValues.values
        widget.entitySetRepeats = widgetValues.entitySetRepeats
      }

      /**
       * Transform widget object relates to created or global
       */
      const { isCreated } = widget

      if (!skipWidgetsTransform) {
        widget._widgetType = widget.widgetType

        if (isCreated || template) {
          delete widget.id
          if (template) {
            EntityService.widgetRemoveIds(
              widget.values,
              widget.entitySetRepeats,
              'entitySetRepeats'
            )
          }
          if (widget.widget) {
            delete widget.widgetType
          } else {
            widget.widgetType = widget.widgetType['@id']
            delete widget.widget
          }
        } else {
          delete widget.widgetType
          if (!widget.widget) delete widget.widget
        }
      }

      return widget
    })

    return widgets
  }

  static async validateForm(formRef: FormRef['current'], widgetId?: any) {
    const errors = (await formRef?.validateForm()) as {}

    if (Object.values(errors).length > 0) {
      EntityService.scrollToError(errors)

      // Add red color to widget if it has errors
      if (widgetId) {
        EntityService.toggleWidgetErrors(widgetId, true)
      }

      formRef?.setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true))
      const error = new Error('Eaw form validation error')
      // @ts-ignore
      error.type = 'validation'
      throw error
    }
  }

  static transformEntityStatus(
    entityData: IEntity,
    status: EntityStatus,
    attributes: SortedAttribute[]
  ): IEntity {
    const statusAttr = attributes.find((attr) => attr.attribute.slug === 'status')

    if (!statusAttr || !status) return entityData

    const statusAttrIri = statusAttr.attribute['@id']

    const statusToValueMap = statusAttr.attribute.attributeEnums.reduce<Record<string, string>>(
      (acc, item) => {
        acc[item.value] = item.id
        return acc
      },
      {}
    )

    const copyEntityData = cloneDeep(entityData)

    const statusValueRecord = copyEntityData.values.find(
      (value) => value.attribute === statusAttrIri
    )

    if (!statusValueRecord) return entityData

    statusValueRecord.value = statusToValueMap[status]

    return copyEntityData
  }

  static async validateWidgets(widgets: IEntityWidget[], widgetsDataRef: WidgetsDataRef) {
    const errors: any = []

    widgets.forEach((widget) => {
      const widgetRefData = widgetsDataRef[widget.id]
      EntityService.toggleWidgetErrors(widget.id, false)

      // If exist refData and widget not global
      if (widgetRefData && !widget.widget) {
        errors.push(EntityService.validateForm(widgetRefData.formRef, widget.id))
      }
    })

    await Promise.all(errors)
  }

  static generateAttributes(
    attributesRes: any,
    entityType: EntityType,
    attributesField: 'entityTypeAttributes' | 'widgetTypeAttributes'
  ) {
    const fullAttributes = attributesRes['hydra:member']

    const attributes = entityType[attributesField].map((item) => {
      const finded = fullAttributes.find(
        (attr: Attribute) => item.attribute === attr['@id']
      ) as Attribute
      return { ...item, attribute: finded }
    })

    return attributes
  }

  static async getAttributes(resourceName: string, typeId: number) {
    const [
      //
      { data: singleEntityTypeRes },
      { data: attributesRes },
    ] = await Promise.all([
      httpService.get<EntityType>(`/${resourceName}/${typeId}`),
      httpService.get<HydraResponse<Attribute>>(`/${resourceName}/${typeId}/attributes`),
    ])

    return { singleEntityTypeRes, attributesRes }
  }

  static scrollToError(errors: any) {
    const firstErrorAttr = Object.keys(errors)[0]
    const findEl = document.querySelectorAll(`[data-control-id='${firstErrorAttr}']`)

    if (findEl && findEl.length !== 0) {
      const errEl: any = findEl[0]
      const { top } = errEl.getBoundingClientRect()

      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    }
  }

  static toggleWidgetErrors(widgetId: any, errors: boolean) {
    const findEl = document.querySelectorAll(`[data-error-id='${widgetId}']`)

    if (findEl && findEl.length === 0) return null

    const errEl: any = findEl[0]
    if (errors) {
      errEl.classList.add('error')
    } else {
      errEl.classList.remove('error')
    }
  }

  static widgetRemoveIds = (values: any[], repeats: any[], repeatField: string) => {
    values.forEach((item) => {
      delete item.id
    })

    repeats.forEach((repeatsItem) => {
      delete repeatsItem.id

      if ((repeatField && repeatsItem[repeatField].length > 0) || repeatsItem.values.length > 0) {
        EntityService.widgetRemoveIds(repeatsItem.values, repeatsItem[repeatField], repeatField)
      }
    })
  }

  static cachePageRevalidate = async (
    siteUrl: string,
    entityId: number,
    resourceType: EavResourceType
  ) => {
    const revalidateRoute = `${siteUrl}/api/revalidate/`
    const url = resourceType === EavResourceType.WIDGET ? 'widgets' : 'entities'
    const { data } = await httpService.get<IEntity>(`/${url}/${entityId}`)

    if (data.template) return
    let pageUrl: string | null = null

    pageUrl = data.entityUrls ? data.entityUrls[0]?.url : undefined
    const urlsRes = await httpService.get(`/entity_urls/${entityId}`)

    await httpService.post(revalidateRoute, {
      url: pageUrl,
      urls: urlsRes.data || [],
    })
  }

  static async getEntityData(
    path: 'entities' | 'widgets',
    id: number,
    isUrlable: boolean,
    pathName: string
  ) {
    if (path === 'widgets' || !isUrlable) {
      return httpService.get(`/${path}/${id}`)
    }

    const entityDataRes = await httpService.get<IEntity>(`/${path}/${id}`)
    const entityData = entityDataRes.data

    if (entityData.original) {
      throw new Error('Entity with original cannot be edited directly')
    }

    if (entityData.status === 'archive') {
      throw new Error('Entity with status "archive" cannot be edited directly')
    }

    const isEntitiesRoute = pathName.includes('/entities')
    if (isEntitiesRoute && entityData.template) {
      throw new Error(`Entity with template cannot be edited by '/entities' route`)
    }

    if (entityData.template) {
      return entityDataRes
    }

    const draftVersion = entityData.versions.find((version) => version.label === 'draft')

    if (draftVersion) {
      id = draftVersion.id
    } else {
      const copiedEntityData = this.copyEntityData(entityData)
      const draftEntityData: IEntityCreate = {
        ...copiedEntityData,
        original: entityData['@id'],
        originalLabel: 'draft',
      }

      const createdIri = await httpService
        .post<any>(`/entities`, draftEntityData)
        .then((res) => res.data['@id'])

      await EntityService.changeEntityStatus(createdIri, 'draft')

      id = +getIdFromIri(createdIri)
    }

    const draftEntityRes = await httpService.get<IEntity>(`/${path}/${id}`)
    draftEntityRes.data.entityUrls = entityData.entityUrls
    draftEntityRes.data.status = entityData.status
    return draftEntityRes
  }

  static async saveOriginalEntity(
    originalIri: string,
    newValues: IEntity
  ): Promise<{ id: number }> {
    const originalId = +getIdFromIri(originalIri)

    const copiedData = this.copyEntityData(newValues)

    const createdEntity = await httpService
      .put(`/entities/${originalId}`, copiedData)
      .then((res) => res.data)

    const id = +getIdFromIri(createdEntity['@id'])

    return { id }
  }

  static copyEntityData(entity: IEntity): IEntityCreate {
    const copyEntity = cloneDeep(entity)
    const newData = omit(
      copyEntity,
      'id',
      '@id',
      '@context',
      '@type',
      'title',
      'name',
      'entityUrls',
      'versions'
    )

    newData.original = null
    newData.originalLabel = null

    clearEntityValuesIds(newData)

    return newData
  }

  static previewEntity(activeSite: Site, entityId: number) {
    window.open(
      `${activeSite.url}/api/preview/?entityId=${entityId}&token=${activeSite.token}`,
      '_blank'
    )
  }

  static changeEntityStatus(entityIri: string, status: EntityStatus) {
    const id = getIdFromIri(entityIri)
    return httpService.put(`/entity_statuses/${id}`, {
      status,
    })
  }

  static async clearSkippedValues(
    isWidgetType: boolean,
    typeId: number,
    entityData: IEntityCreate
  ): Promise<IEntityCreate> {
    const resourceName = isWidgetType ? 'widget_types' : 'entity_types'
    const { attributesRes } = await EntityService.getAttributes(resourceName, typeId)

    const getSafeOption = (options: any) =>
      !Array.isArray(options) ? options?.skip_from_copy : null

    const skipAttrIris = attributesRes['hydra:member'].reduce<string[]>((ids, attr) => {
      const needSkip = getSafeOption(attr.options)
      if (needSkip) ids.push(attr['@id'])
      return ids
    }, [])

    entityData.values = entityData.values.filter((value) => !skipAttrIris.includes(value.attribute))

    return entityData
  }

  static async duplicateEntity(entityId: number, isWidgetType: boolean = false) {
    const mainPath = isWidgetType ? 'widgets' : 'entities'

    const currentEntity = await httpService
      .get<IEntity>(`/${mainPath}/${entityId}`)
      .then((res) => res.data)

    const typeId = +getIdFromIri(currentEntity.entityType)

    let copyValues = EntityService.copyEntityData(currentEntity)
    copyValues = await EntityService.clearSkippedValues(isWidgetType, typeId, copyValues)

    const duplicatedEntity = await httpService
      .post<{ data: { '@id': string } }>(`/${mainPath}`, copyValues)
      .then((res) => res.data)
    const createdId = +getIdFromIri(duplicatedEntity['@id'])

    return { id: createdId }
  }
}
