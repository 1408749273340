import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const repeaterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },

    count: {
      position: 'absolute',
      left: '-12px',
      padding: theme.spacing(0.8),
    },
    formControlItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      '&:hover': {
        '& > .action--area': {
          opacity: 1,
          // transform: 'scale(1)',
        },
      },
    },
  })
)

export default repeaterStyles
