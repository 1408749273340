// Core
import React, { FC, useCallback, useRef } from 'react'
// Components
import { Button, ButtonGroup, Link, TextField as TextFieldMaterial } from '@material-ui/core'
import clsx from 'clsx'
// Hooks
import { useSystemSlug } from './hooks'
import { useTranslation } from 'react-i18next'
import { useOutsideClick } from 'common/hooks'
// Utils
import { isNullable, isString } from 'core/utils'
// Styles
import useStyles from './system-slug.styles'

type TProps = {
  name: string
  entityUrl?: string
  data: any
}

const showError = (error: undefined | string | boolean): boolean => {
  if (isNullable(error)) return false
  if (isString(error)) return true
  return Boolean(error)
}

const SystemSlug: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { name, entityUrl, data } = props
  const slugHolderRef = useRef(null)

  const trimSlash = useCallback((val: string) => {
    return val?.replace(/^\/+/, '')?.replace(/\/+$/, '')
  }, [])

  const {
    field,
    siteLink,
    meta,
    fieldState,
    showInput,
    htmlElRef,
    actions: {
      inputChangeHandler,
      setValueHandler,
      onBlurHandler,
      inputFocusHandler,
      cancelHandler,
    },
    siteUrl,
  } = useSystemSlug(name, data, entityUrl)

  useOutsideClick(slugHolderRef, onBlurHandler)

  let href = `${siteLink}${trimSlash(field.value)}${trimSlash(field.value) !== '' ? '/' : ''}`

  if (entityUrl) {
    href = `${siteUrl}/${entityUrl}/`
  }

  return (
    <>
      <div ref={slugHolderRef} className={classes.root}>
        <strong className={classes.placeholder}>{data.name}:&nbsp;</strong>

        <Link
          target={'_blank'}
          className={clsx(classes.link, {
            fill: data.slug === 'cloaked_url',
          })}
          href={href}
        >
          {siteLink}
          <span>
            {!(meta.touched && showError(meta.error)) &&
              !showInput &&
              `${trimSlash(field.value)}${trimSlash(field.value) !== '' ? '/' : ''}`}
          </span>
        </Link>

        <div className={classes.window}>
          {((meta.touched && showError(meta.error)) || showInput) && (
            <>
              <TextFieldMaterial
                fullWidth
                ref={htmlElRef}
                value={fieldState}
                variant={'outlined'}
                className={clsx(classes.input)}
                onChange={inputChangeHandler}
                error={meta.touched && showError(meta.error)}
                helperText={
                  meta.touched && meta.error ? (
                    <span className={classes.helperText}>{meta.touched && meta.error}</span>
                  ) : null
                }
              />
              /
            </>
          )}
        </div>

        <ButtonGroup className={classes.buttonGroup} variant="contained" color="primary">
          {!(meta.touched && showError(meta.error)) && !showInput && (
            <Button onClick={inputFocusHandler}>{t('global.edit')}</Button>
          )}

          {((meta.touched && showError(meta.error)) || showInput) && (
            <Button onClick={setValueHandler}>{t('global.ok')}</Button>
          )}

          {((meta.touched && showError(meta.error)) || showInput) && (
            <Button color={'secondary'} onClick={cancelHandler}>
              {t('global.cancel')}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </>
  )
}

export default SystemSlug
