import { BreadcrumbData } from '../types'
import { useBreadcrumbsContext } from './use-breadcrumbs-context'
import { useEffect, useMemo } from 'react'

type UseSetBreadcrumbsData = (data: BreadcrumbData[], isInModal?: boolean) => void

export const useSetBreadcrumbsData: UseSetBreadcrumbsData = (data, isInModal = false) => {
  const { setBreadcrumbsData } = useBreadcrumbsContext()
  const dataKey = JSON.stringify(data)

  const memoData = useMemo(() => {
    return data
  }, [dataKey]) // eslint-disable-line

  useEffect(() => {
    if (isInModal) return
    setBreadcrumbsData(memoData)

    return () => {
      if (isInModal) return
      setBreadcrumbsData([])
    }
  }, [isInModal, memoData, setBreadcrumbsData])
}
