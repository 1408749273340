// Core
import React, { FC, useCallback } from 'react'
import { useField } from 'formik'
import { Attachment } from 'ui'
// Hooks
import { useMediaManagerModalOpen } from 'modules/media/hooks'
import { useTranslation } from 'react-i18next'
// Types
import { IFile } from 'modules/media/types'
// Utils
import { isImageFromMime } from 'modules/media/utils'

type TProps = {
  label?: string
  name: string
  type: string
  disabled?: boolean
  slug: string
}

const ImageAttachment: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const mediaMangerOpen = useMediaManagerModalOpen()
  const { name, label, type, disabled, slug } = props
  const [{ value }, meta, { setValue, setTouched, setError }] = useField(name)

  const handleChange = useCallback(
    (file: IFile) => {
      const isImage = isImageFromMime(file.mimeType)

      if (type === 'image' && !isImage) {
        setError(t('errors.image.type'))
      } else {
        setValue(file['@id'])
      }
    },
    [type, setError, t, setValue]
  )

  const openMediaPopup = useCallback(() => {
    mediaMangerOpen(true, (file: IFile) => handleChange(file))
    setTouched(true)
  }, [handleChange, mediaMangerOpen, setTouched])

  return (
    <Attachment
      type={type}
      openPopup={openMediaPopup}
      value={value}
      error={meta.error}
      touched={meta.touched}
      label={label}
      setValue={setValue}
      disabled={disabled}
      slug={slug}
    />
  )
}

export default ImageAttachment
