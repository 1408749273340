import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px dashed #BDBDBD',
      borderRadius: '2px',
      padding: theme.spacing(2),
      position: 'relative',
    },
    title: {
      padding: theme.spacing(0, 0, 1),
      fontSize: '1rem',
      lineHeight: '22px',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    button: {
      position: 'absolute',
      right: '15px',
      top: '10px',
      padding: '4px 4px',
    },
    holder: {
      margin: theme.spacing(1, 0),
    },
  })
)

export default containerStyles
