import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px 14px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      cursor: 'pointer',
      fontSize: '16px',
      '&:hover': {
        border: '1px solid rgba(0, 0, 0, 0.97)',
      },
    },
    multiple: {
      padding: 7,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    label: {
      position: 'absolute',
      top: '-9px',
      left: '8px',

      height: 11,
      width: 'auto',
      padding: '0 4px 0 5px',

      backgroundColor: 'rgba(255, 255, 255, 1)',

      fontSize: '0.75em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    placeholder: {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export default styles
