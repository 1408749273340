// Core
import React, { FC } from 'react'
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

const CoreWebVitalsPage: FC<Props> = () => {
  const { t } = useTranslation()

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t('menu.core-web-vitals') },
  ])

  return (
    <iframe
      title="core-web-vitals"
      width="100%"
      style={{ height: 'calc(100% - 51px)' }}
      height="100%"
      src="https://datastudio.google.com/embed/reporting/16243871-f5b1-485b-b586-3e6ffe2919fc/page/BGYaC"
      frameBorder="0"
      allowFullScreen
    />
  )
}

export default CoreWebVitalsPage
