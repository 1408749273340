// Core
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
// Context
import { SitemapSettingsProvider } from '../../context'
// Components
import { SettingsTable, CreateSettingsModal, Regenerate } from '../../components'
import { PageTitle } from 'ui'
// Hooks
import { useSetBreadcrumbsData } from 'core/breadcrumbs'
// Types
import { SettingsType } from '../../types'

type Props = {
  children?: never
}

const SitemapSettingsMap: FC<Props> = () => {
  const { t } = useTranslation()

  useSetBreadcrumbsData([
    { title: t('global.dashboard'), to: '/' },
    { title: t('Sitemap.xml settings') },
  ])

  return (
    <SitemapSettingsProvider>
      <Box
        px={2}
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ background: '#fff' }}
      >
        <PageTitle title="xml_settings" />
        <Regenerate />
      </Box>
      <SettingsTable type={SettingsType.entityType} />
      <SettingsTable type={SettingsType.entity} />
      <CreateSettingsModal />
    </SitemapSettingsProvider>
  )
}

export default SitemapSettingsMap
