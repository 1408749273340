// Core
import { useMemo, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { FormikProps } from 'formik'
// Hooks
import { useInitData } from './use-init-data'
import { useInitialValues } from './use-initial-values'
import { useGetActiveSite } from 'modules/sites'
// Services
import { httpService } from 'core/data'
import { EntityService } from '../utils'
// Types
import { EavResourceType, IEntity, SortedAttribute } from '../types'

type AttributesData = {
  mainAttributes: SortedAttribute[]
  asideAttributes: SortedAttribute[]
}

export const useEntityController = (
  id: any,
  typeId: any,
  resourceType: EavResourceType,
  queryKey?: string
) => {
  const activeSite = useGetActiveSite()
  const [entity, setEntity] = useState<IEntity>(null!)
  const isEdit = Boolean(id)

  const formRef = useRef<FormikProps<any>>(null)
  const type = resourceType === EavResourceType.WIDGET ? 'widgets' : 'entity'

  const { data, isLoading, isError } = useInitData(id, typeId, type, {
    onSuccess: ({ entity }) => {
      setEntity(entity)
    },
    queryKey,
  })

  const { initialValues, validationSchema } = useInitialValues(entity, data)

  const { mainAttributes, asideAttributes } = useMemo<AttributesData>(() => {
    if (!data?.attributes) return { mainAttributes: [], asideAttributes: [] }

    return data?.attributes.reduce(
      (accum: AttributesData, attribute: any) => {
        if (attribute.attribute.options.show_in_sidebar) {
          accum.asideAttributes.push(attribute)
        } else {
          accum.mainAttributes.push(attribute)
        }
        return accum
      },
      { mainAttributes: [], asideAttributes: [] }
    )
  }, [data?.attributes])

  const mutation = useMutation(async (dataParam: any) => {
    if (dataParam.asTemplate) {
      return httpService.post('/entities', dataParam)
    }

    const url = resourceType === EavResourceType.WIDGET ? 'widgets' : 'entities'
    const id = entity?.id
    const method = isEdit ? 'put' : 'post'
    const entityData = await httpService[method](`/${url}${isEdit ? `/${id}` : ''}`, dataParam)

    if (['publish', 'unpublish'].includes(dataParam.action)) {
      const dataWithNewStatus = EntityService.transformEntityStatus(
        dataParam,
        dataParam.action === 'publish' ? 'publish' : 'draft',
        data.attributes
      )
      await EntityService.saveOriginalEntity(entity.original!, dataWithNewStatus)
    }

    if (dataParam.action === 'preview') {
      EntityService.previewEntity(activeSite!, id)
    }

    return entityData
  })

  return {
    entity,
    isLoading,
    attributes: data?.attributes,
    mainAttributes,
    asideAttributes,
    initialValues,
    validationSchema,
    entityType: data?.entityType,
    formRef,
    mutation,
    setEntity,
    isError,
  }
}
