// Core
import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useDataViewContext } from './data-view-context'

type Props = {
  children?: never
}

const TableFooter: FC<Props> = () => {
  const { page, setPage, totalItems, perPage } = useDataViewContext()
  const count = useMemo(() => Math.ceil(totalItems / perPage), [perPage, totalItems])

  return (
    <Box py={1} borderTop={1} style={{ borderColor: '#e0e0e0' }}>
      <Pagination
        count={count}
        page={page}
        onChange={(_, p) => setPage(p)}
        color="primary"
        showFirstButton
        showLastButton
      />
    </Box>
  )
}

export default TableFooter
