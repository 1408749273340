import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      opacity: 0,
      transition: 'all 0.5s',
    },
    spiner: {
      position: 'absolute',
    },
  })
)
