import { AxiosError, isAxiosError } from 'axios'
import { FormikErrors, setNestedObjectValues } from 'formik'
import { FormProps } from '../types'

type ApiError = {
  propertyPath: string
  message: string
}

type ApiErrorRes = {
  violations: ApiError[]
}

type FormErrors = FormikErrors<any>

type ApiValidationError = AxiosError<ApiErrorRes>

export class ApiErrorsService {
  constructor(private formInstance: FormProps, private error: ApiValidationError) {}

  public static isApiError(error: unknown) {
    if (!isAxiosError(error)) return false
    const { response } = error
    if (!response?.data) return false
    const apiErrors = response.data as ApiErrorRes
    if (!apiErrors.violations) return false
    return true
  }

  private transformApiErrors(apiErrors: ApiError[]) {
    return apiErrors.reduce<FormErrors>((errors, apiError) => {
      errors[apiError.propertyPath] = { value: apiError.message }
      return errors
    }, {})
  }

  public setFormErrors() {
    if (!this.error.response?.data) return
    const { violations } = this.error.response.data
    const formErrors = this.transformApiErrors(violations)
    this.formInstance.setTouched(setNestedObjectValues(formErrors, true), false)
    this.formInstance.setErrors(formErrors)
  }
}
