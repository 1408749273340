import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const pageBuilderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    section: {},
  })
)

export default pageBuilderStyles
