// Core
import React, { FC } from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
// Types
import { LockedUser } from './types'

type Props = {
  lockedUser: LockedUser
  goBackHandler: () => void
  askControl: () => void
  waitControlLoading: boolean
}

const LockScreen: FC<Props> = (props) => {
  const { askControl, lockedUser, waitControlLoading, goBackHandler } = props

  return (
    <Dialog open>
      <DialogContent>
        Page is already being edited by <b>{lockedUser.userName}</b>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => goBackHandler()} color="primary">
          Go back
        </Button>
        <Button
          onClick={() => askControl()}
          disabled={waitControlLoading}
          color="primary"
          variant="contained"
          endIcon={waitControlLoading && <CircularProgress size={20} />}
        >
          Take over
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LockScreen
