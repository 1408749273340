// Core
import React, { FC, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
// Hooks
import { useGetCondition } from './hooks'
// Utils
import { ControlRenderer } from './utils'
// Types
import {
  FormAttribute,
  IEntityWidget,
  MutationType,
  TOptions,
  EavResourceType,
} from 'modules/new-entity/types'
import { IEntity } from 'modules/new-entity/types'
// Styles
import useStyles from './form-control.styles'
import clsx from 'clsx'

type Props = {
  data: FormAttribute
  children?: never
  name: string
  options: TOptions
  allData?: any
  pathToHierarchicalOptions?: string
  pathForConditions?: string
  nested?: boolean
  resourceType: EavResourceType
  type?: MutationType
  selfType: 'entity' | 'widget'
  disabled?: boolean
  entity?: IEntity
  widget?: IEntityWidget
  slugs?: any
}

const FormControl: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    data,
    options,
    pathToHierarchicalOptions = '',
    pathForConditions = '',
    name,
    allData,
    nested = false,
    selfType,
    resourceType,
    type,
    disabled,
    entity,
    widget,
    slugs,
  } = props

  const combineDisabled = disabled || data?.attribute?.readonly

  // TODO rework after old fixtures will deleted
  const pathWithNoValue = pathToHierarchicalOptions.replace(/(value)(\[[0-9]+\])|(value.)/g, '')
  const getOptionsByKey = name && options?.attributeOptions?.[pathWithNoValue]

  const fieldWidth = useMemo(() => {
    if (!getOptionsByKey) return 12
    return getOptionsByKey.fieldWidth
  }, [getOptionsByKey])

  const getCondition = useGetCondition(getOptionsByKey, allData, pathForConditions)
  if (!getCondition) return null
  if (
    (data.attribute.slug === 'updated_at' || data.attribute.slug === 'created_at') &&
    type === 'create'
  )
    return null

  if (
    resourceType === EavResourceType.TEMPLATE &&
    (data.attribute.slug === 'slug' ||
      data.attribute.slug === 'status' ||
      data.attribute.slug === 'updated_at' ||
      data.attribute.slug === 'created_at')
  )
    return null

  if (getOptionsByKey?.hidden || data.attribute.options.hidden) return null

  return (
    <Grid
      item
      xs={12}
      lg={fieldWidth}
      className={clsx(classes.fieldWrapper, data.attribute.slug)}
      data-control-id={data.attribute['@id']}
      data-aqa-attribute-slug={data.attribute.slug}
      // @ts-ignore
      data-aqa-attribute-type={data.attribute.attributeType.name}
    >
      {data.attribute.hint &&
        !['navigation_item_entity_group', 'navigation_item_custom_group'].includes(
          data.attribute.slug
        ) && (
          <Typography className={classes.hint} variant={'caption'} component={'p'}>
            {data.attribute.hint}
          </Typography>
        )}
      <div className={`${classes.fieldItem} fieldItem`}>
        <ControlRenderer
          resourceType={resourceType}
          nested={nested}
          attributeData={data.attribute}
          isNamable={data.nameable}
          name={name}
          options={options}
          selfType={selfType}
          type={type}
          disabled={combineDisabled}
          entity={entity}
          widget={widget}
          slugs={slugs}
        />
      </div>
    </Grid>
  )
}
export default FormControl
