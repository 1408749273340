// Core
import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, Theme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { BreadcrumbsProvider } from 'core/breadcrumbs'
import { DateLib } from 'core/date'

import { ConfirmationProvider } from 'core/confirmation'

type TProps = {
  store: any
  theme: Theme
}

const CoreContext: FC<TProps> = (props) => {
  const { store, theme, children } = props

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider libInstance={DateLib} utils={MomentUtils}>
          <ConfirmationProvider>
            <BreadcrumbsProvider>{children}</BreadcrumbsProvider>
          </ConfirmationProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default CoreContext
