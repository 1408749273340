// Core
import React, { FC, SyntheticEvent, useCallback, useMemo } from 'react'
import { ArrowDropDown, Close } from '@material-ui/icons'
import { Chip, IconButton } from '@material-ui/core'
import clsx from 'clsx'
// Styles
import useStyles from './value.styles'

type Props = {
  children?: never
  onClick?: (e: any) => void
  label: string
  value: any
  titleField: string
  valueField: string
  multiple?: boolean
  onDelete: (item: any) => void
  error?: boolean
  hasClear: boolean
  clearValue: () => void
}

const Value: FC<Props> = (props) => {
  const {
    value,
    onClick,
    label,
    titleField,
    valueField,
    onDelete,
    multiple,
    error,
    hasClear,
    clearValue,
  } = props
  const classes = useStyles()

  const clickHandler = useCallback(
    (e: any) => {
      if (onClick) onClick(e.target)
    },
    [onClick]
  )

  const deleteHandler = useCallback(
    (item) => () => {
      onDelete(item)
    },
    [onDelete]
  )

  const clearHandler = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      clearValue()
    },
    [clearValue]
  )

  const hasValue = useMemo(() => {
    return multiple ? Array.isArray(value) && value.length > 0 : Boolean(value)
  }, [multiple, value])

  return (
    <div
      onClick={clickHandler}
      className={clsx(classes.root, {
        [classes.multiple]: multiple && Array.isArray(value) && value.length,
        [classes.error]: error,
      })}
    >
      {value && <legend className={classes.label}>{label}</legend>}
      {value && Array.isArray(value) && value.length > 0 ? (
        <div>
          {value.map(
            (item) =>
              item && (
                <Chip
                  key={item?.[valueField]}
                  label={item?.[titleField]}
                  onDelete={deleteHandler(item)}
                  className={classes.chip}
                />
              )
          )}
        </div>
      ) : (
        <span className={clsx({ [classes.placeholder]: !value })}>
          {(value && value[titleField]) || label}
        </span>
      )}
      <div className={classes.buttons}>
        {hasClear && hasValue && (
          <IconButton size="small" onClick={clearHandler}>
            <Close fontSize="inherit" />
          </IconButton>
        )}
        <ArrowDropDown className={classes.icon} />
      </div>
    </div>
  )
}

export default Value
