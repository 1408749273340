// Core
import React, { FC, useMemo } from 'react'
import { DataControllerProps, httpService } from 'core/data'
import { Grid } from '@material-ui/core'
import { TextField, CheckboxField } from 'core/form'
import { ReferenceInput, SelectRef } from 'core/form'
import { CrudFormContainer } from 'core/data'
import SmartSelectField from 'core/form/components/smart-select-field'
// Hooks
import { useTranslation } from 'react-i18next'
// Utils
import moment from 'moment-timezone'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

const timeZones = moment.tz.names()

const offsetTmz: any[] = []

const CrudForm: FC<DataControllerProps> = (props) => {
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQuery(
    'entity-type-site',
    async () => {
      const res = await httpService.get<any>('/entity_types', { params: { slug: 'site' } })
      return res.data['hydra:member']
    },
    {
      onError: (e: any) => {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )
  useMemo(() => {
    return timeZones.forEach((item, index) => {
      offsetTmz.push({
        name: `UTC ${moment.tz(item).format('Z')}: ${timeZones[index]}`,
        '@id': `${timeZones[index]}`,
      })
    })
  }, [])

  return (
    <CrudFormContainer {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name={'name'} label={t('labels.site-name')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="url" label={t('labels.url')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="dateFormat" label={t('labels.d-format')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="timeFormat" label={t('labels.t-format')} />
        </Grid>
        <Grid item xs={12}>
          <ReferenceInput source="timezone" choices={offsetTmz} label={t('labels.timezone')}>
            <SelectRef optionText="name" />
          </ReferenceInput>
        </Grid>

        {!isLoading && !isError && (
          <Grid item xs={12}>
            <SmartSelectField
              valueField={'@id'}
              name={'entity'}
              source={'entities'}
              reqParams={{ entityType: data[0] ? data[0]['@id'] : [] }}
              label={t('select.entity')}
              nullable={true}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SmartSelectField
            valueField={'@id'}
            name={'network'}
            source={'networks'}
            label={t('labels.network')}
          />
        </Grid>
        <Grid item xs={12}>
          <SmartSelectField
            valueField={'@id'}
            name={'defaultLocale'}
            source={'localizations'}
            label={t('labels.defaultLocalization')}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField name="noindex" label={t('labels.no-index')} />
        </Grid>
        <Grid item xs={12}>
          <TextField multiline name={'robot'} label="Custom robots.txt" />
        </Grid>
      </Grid>
    </CrudFormContainer>
  )
}

export default CrudForm
