import React, { cloneElement, createElement, FC, ReactElement } from 'react'
import { Form, Formik } from 'formik'
import formContainerStyles from './crud-form-controller.styles'
import SidebarGlobal from 'core/components/sidebar'
import { Box } from '@material-ui/core'
import { transformLocalizations } from 'core/data'

type TProps = {
  children: ReactElement
  onSubmit: (data?: any) => void
  initialProps?: any
  type: 'create' | 'edit'
  errors?: any
  sidebar?: any
  setRef?: (e: any) => void
  validationSchema?: any
  loading?: boolean
}

const CrudFormController: FC<TProps> = (props) => {
  const {
    children,
    onSubmit,
    initialProps = {},
    errors,
    sidebar,
    type,
    setRef,
    validationSchema,
    loading,
  } = props
  const classes = formContainerStyles()

  return (
    <Formik
      innerRef={setRef}
      initialValues={transformLocalizations(initialProps)}
      onSubmit={onSubmit}
      initialErrors={errors}
      validationSchema={validationSchema}
      enableReinitialize={!loading}
    >
      {(formikProps) => (
        <Form className={classes.root}>
          <Box className={classes.contentHolder}>
            {cloneElement(children, { ...formikProps, ...props })}
          </Box>

          <SidebarGlobal type={type}>
            {sidebar ? createElement(sidebar, { ...props }) : <div />}
          </SidebarGlobal>
        </Form>
      )}
    </Formik>
  )
}

export default CrudFormController
