import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { EntityType, HydraResponse } from 'core/types'
import { Attribute, SortedAttribute } from '../types'
import { getIdFromIri } from 'core/utils'
import { EntityService } from '../utils'

const getData = (type: 'entity' | 'widgets' | 'widget') =>
  type === 'entity'
    ? {
        entityWidgets: [],
        options: {},
        values: [],
        entityUrls: [],
        setRepeats: [],
        template: false,
        version: 0,
      }
    : {
        values: [],
        setRepeats: [],
      }

type TDataSelect = {
  entityType: EntityType
  entity: any
  setRepeatsField: string
  attributesField: 'entityTypeAttributes'
  attributesRes: HydraResponse<Attribute>
}

type TData = {
  attributes: SortedAttribute[]
} & TDataSelect

type UseInitData = (
  id: number | null,
  typeId: number,
  type: 'entity' | 'widgets' | 'widget',
  additional: { onSuccess?: (data: any) => void; initialData?: any; queryKey?: string }
) => { data: TData; isLoading: boolean; isFetching: boolean; isError: boolean }

// @ts-ignore
export const useInitData: UseInitData = (
  id,
  typeId,
  type,
  { onSuccess, initialData, queryKey }
) => {
  const location = useLocation()
  const isWidget = Boolean(initialData?.widget)

  const getKey = () => {
    if (isWidget) return ['attributes-form', type, initialData.widget, id]
    return ['attributes-form', type, queryKey, id]
  }

  const { data, isLoading, isFetching, isError } = useQuery<any, any, any>(
    getKey(),
    async () => {
      const path = type === 'entity' ? 'entities' : 'widgets'

      const [apiResourceName, attributesField, setRepeatsField] = (() => {
        switch (type) {
          case 'entity':
            return ['entity_types', 'entityTypeAttributes', 'setRepeats'] as const
          default:
            return [
              'widget_types',
              'widgetTypeAttributes',
              type === 'widgets' ? 'setRepeats' : 'entitySetRepeats',
            ] as const
        }
      })()

      const { singleEntityTypeRes, attributesRes } = await EntityService.getAttributes(
        apiResourceName,
        typeId
      )

      const { data: entData } = await (() => {
        if (type === 'widget') {
          return isWidget
            ? httpService.get(`/widgets/${getIdFromIri(initialData.widget!)}`)
            : { data: initialData }
        }
        return !id
          ? { data: getData(type) }
          : EntityService.getEntityData(path, id, singleEntityTypeRes.urlable, location.pathname)
      })()

      let entity = { ...entData }

      if (type === 'entity') {
        entity = {
          ...entData,
          layout: singleEntityTypeRes?.entityTypeLayouts[0]?.layout,
        }
      }

      return {
        entity,
        entityType: singleEntityTypeRes,
        setRepeatsField,
        attributesField,
        attributesRes,
      }
    },
    {
      select: (data: TDataSelect) => {
        const attributes = EntityService.generateAttributes(
          data.attributesRes,
          data.entityType,
          data.attributesField
        )

        return {
          ...data,
          attributes,
        }
      },
      onSuccess,
      onError: (e: any) => {
        if (e?.response?.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          toast.error(e?.message || `Something went wrong`)
          // eslint-disable-next-line no-console
          console.error(e)
        }
      },
      cacheTime: 0,
    }
  )

  return { data, isLoading, isFetching, isError }
}
